import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { colors } from './../design';

class ClientHeader extends React.Component {

  constructor() {
    super();
    this.state = {
      selected: 'OPEN'
    };
  }

  componentDidMount(){
    switch (window.location.pathname) {
      case '/open-rfps': {
        this.props.setClientHeaderTab('OPEN');
        break;
      }
      case '/marketer-draft-rfps': {
        this.props.setClientHeaderTab('DRAFT');
        break;
      }
      case '/marketer-closed-rfps': {
        this.props.setClientHeaderTab('CLOSED');
        break;
      }
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          backgroundColor: colors.lightAccent
        }}>
        <Button 
          variant={props.clientHeader === 'OPEN' ? "contained" : "outlined"}
          className={classes.button}
          color="secondary"
          component={Link}
          to="/open-rfps"
          onClick={() => this.props.setClientHeaderTab('OPEN')}>
          Open RFPs ({props.dashboard.active_open_rfps})
        </Button>
        <Button 
          variant={props.clientHeader === 'DRAFT' ? "contained" : "outlined"} 
          className={classes.button}
          color="secondary"
          component={Link}
          to="/marketer-draft-rfps"
          onClick={() => {
            this.props.setClientHeaderTab('DRAFT');
          }}>
          Draft RFPs ({props.dashboard.draft_open_rfp})
        </Button>
        <Button 
          variant={props.clientHeader === 'CLOSED' ? "contained" : "outlined"} 
          className={classes.button}
          color="secondary"
          component={Link}
          to="/marketer-closed-rfps"
          onClick={() => this.props.setClientHeaderTab('CLOSED')}>
          Closed RFPs ({props.dashboard.closed_rfp})
        </Button>
      </div>
    );
  }
}

ClientHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  button: {
    width:200,
    height:40,
    margin:15
  }
});

export default withRoot(withStyles(styles)(ClientHeader));