export const declineRFPResponse = (data) => {
  return {
    type: 'RFP_DECLINE_RFP_RESPONSE',
    payload: data
  }
}

export const setClientAnswersByProperty = (data) => {
  return {
    type: 'RFP_SET_CLIENT_ANSWERS_BY_PROPERTY',
    payload: data
  }
}

export const setLocalResponseFavorite = (data) => {
  return {
    type: 'SET_LOCAL_RESPONSE_FAVORITE',
    payload: data
  }
}

export const setRFPResponses = (data) => {
  return {
    type: 'RFP_SET_RFP_RESPONSES',
    payload: data
  }
}

export const clearForEditRFP = (data) => {
  return {
    type: 'CLEAR_FOR_EDIT_RFP',
    payload: data
  }
}

export const setExistingClientQuestions = (data) => {
  return {
    type: 'RFP_SET_RFP_existing_agency_questions',
    payload: data
  }
}

export const setExistingAgencyQuestions = (data) => {
  return {
    type: 'RFP_SET_RFP_existing_client_answers',
    payload: data
  }
}

export const setAgencyClosedRFPs = (data) => {
  return {
    type: 'RFP_SET_AGENCY_CLOSED_RFPS',
    payload: data
  }
}

export const setAgencySentRFPs = (data) => {
  return {
    type: 'RFP_SET_AGENCY_SENT_RFPS',
    payload: data
  }
}

export const setAgencyDraftRFPs = (data) => {
  return {  
    type: 'RFP_SET_AGENCY_DRAFT_RFPS',
    payload: data
  }
}

export const setViewRFP = (data) => {
  return {
    type: 'RFP_SET_VIEW_RFP',
    payload: data
  }
}

export const sortAgencyOpenRFPS = (data) => {
  return {
    type: 'RFP_SORT_AGENCY_OPEN_RFPS',
    payload: data
  }
}

export const setAgencyOpenRFPs = (data) => {
  return {
    type: 'RFP_SET_AGENCY_OPEN_RFPS',
    payload: data
  }
}

export const setEditRFPProperty= (data) => {
  return {
    type: 'RFP_SET_EDIT_RFP_PROPERTY',
    payload: data
  }
}

export const setSetClientTemplate = (data) => {
  return {
    type: 'RFP_SET_RFP_CLIENT_TEMPLATE',
    payload: data
  }
}

export const setAgencyTemplate = (data) => {
  return {
    type: 'RFP_SET_RFP_AGENCY_TEMPLATE',
    payload: data
  }
}

export const setRFPResponse = (data) => {
  return {
    type: 'RFP_SET_RFP_RESPONSE',
    payload: data
  }
}

export const setClientDashboard = (data) => {
  return {
    type: 'SET_CLIENT_DASHBOARD',
    payload: data
  }
}

export const setAgencyDashboard = (data) => {
  return {
    type: 'SET_AGENCY_DASHBOARD',
    payload: data
  }
}

export const setClientOpenRFPs = (data) => {
  return {
    type: 'SET_CLIENT_OPEN_RFPS',
    payload: data
  }
}

export const sortClientOpenRFPS = (data) => {
  return {
    type: 'SORT_CLIENT_OPEN_RFPS',
    payload: data
  }
}

export const setClientDraftRFPs = (data) => {
  return {
    type: 'SET_CLIENT_DRAFT_RFPS',
    payload: data
  }
}

export const setClientClosedRFPs = (data) => {
  return {
    type: 'SET_CLIENT_CLOSED_RFPS',
    payload: data
  }
}

export const sortClientClosedAwardedRFPS = (data) => {
  return {
    type: 'SORT_CLIENT_CLOSED_AWARDED_RFPS',
    payload: data
  }
}

export const sortClientClosedUnawardedRFPS = (data) => {
  return {
    type: 'SORT_CLIENT_CLOSED_UNAWARDED_RFPS',
    payload: data
  }
}

export const sortClientClosedRFPS = (data) => {
  return {
    type: 'SORT_CLIENT_CLOSED_RFPS',
    payload: data
  }
}

export const setCompareResponses = (data) => {
  return {
    type: 'RFP_SET_COMPARE_RESPONSES',
    payload: data
  }
}
