import * as React from 'react';
import { connect } from 'react-redux';
import ClientClosedRFPs from './ClientClosedRFPsComponent';
import { 
  GetClientClosedRFPs, 
  GetClientDashboard,
  GetAwardedResponderName 
} from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';
import { 
  setClientClosedRFPs, 
  sortClientClosedRFPS,
  setClientDashboard,
  sortClientClosedAwardedRFPS,
  sortClientClosedUnawardedRFPS
} from './../reducers/rfpActions';
import ClientClosedRFP from './ClientClosedRFPComponent';
import { setClientHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfps: state.rfpReducer.client_closed_rfps,
    awarded: state.rfpReducer.client_closed_rfps_awarded,
    unawarded: state.rfpReducer.client_closed_rfps_unawarded
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAgencyView: (props) => {

      var agencyData = {
        agency_profile: props.awarded_agency,
        closes_on_date: props.closes_on_date,
        rfp_id: props.id,
        title: props.title,
        opened_date: props.opened_date,
        awarded_date: props.awarded_date,
        views: props.views,
        response_count: props.response_count,
        status: props.status
      };

      dispatch(loading(true));
      dispatch(GetAwardedResponderName({rfp_id: props.id}))
      .then(res => {
        dispatch(loading(false));
        agencyData.awarded_responder_name = res.value.data.responder_name;
        console.log('agencyData props', props);
        props.history.push('/view-agency', agencyData)
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetAwardedResponderName err", err);
        props.history.push('/view-agency', agencyData)
      });
    },
    setHeaderTab: (tab) => {
      dispatch(setClientHeader(tab))
    },
    getRFPS: (props) => {
      dispatch(loading(true));
      dispatch(GetClientClosedRFPs())
      .then(res => {
        dispatch(setClientClosedRFPs(res.value.data));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetClientClosedRFPs err", err);
      });

      dispatch(GetClientDashboard())
      .then(res => {
        dispatch(setClientDashboard(res.value.data));
      })
      .catch(err => {
        console.log("GetClientDashboard err", err);
      });
    },
    getViews: (props, state) => {
      var views = [];
      var rfps = [];
      
      if (state.toggleSelected === 'awarded') {
        rfps = props.awarded;
      }
      else if (state.toggleSelected === 'unawarded') {
        rfps = props.unawarded;
      }
      else {
        rfps = props.rfps;
      }

      rfps.forEach((rfp) => {
        var viewCount = 0;
        if (rfp.rfp_meta) {
          viewCount = rfp.rfp_meta.views;
        }
        views.push(
          <ClientClosedRFP
            {...props}
            id={rfp.id}
            title={rfp.title}
            views={viewCount}
            opened_date={rfp.opened_date}
            closes_on_date={rfp.closes_on_date}
            response_count={rfp.response_count}
            status={rfp.status}
            awarded_date={rfp.awarded_date}
            awarded_agency={rfp.agency_profile}
          />
        )
      })
      return views;
    },
    sortRFP: (sort, toggleSelected) => {
      if (toggleSelected === 'awarded') {
        dispatch(sortClientClosedAwardedRFPS(sort));
      }
      else if (toggleSelected === 'unawarded') {
        dispatch(sortClientClosedUnawardedRFPS(sort));
      }
      else {
        dispatch(sortClientClosedRFPS(sort));
      }
    }
  }
}

const ClientClosedRFPsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientClosedRFPs);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ClientClosedRFPsContainer;