import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import AgencyViewRFP from './AgencyViewRFPComponent';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors } from './../design';

class ViewResponses extends React.Component {

  constructor() {
    super();
    this.state = {
      rfp: null,
      compare: false,
      favorite: false,
      declineResponseOpen: false,
      service_needed: []
    };
  }

  componentDidMount(){
    const history = this.props.history.location.state;
    if (history && history.rfp_id){
      this.props.getRFPResponses(history.rfp_id, 'service_needed', this.setState.bind(this));
    }
    else {
      this.props.history.push('/');
    }
    sessionStorage.removeItem('compare_responses');
  }

  render() {

    const props = this.props;
    const state = this.state;
    const historyState = props.history.location.state;
    const { classes } = this.props;
    const rfpDetails = {
      title: historyState.title,
      opened_date: historyState.opened_date,
      closes_on_date: historyState.closes_on_date,
      views: historyState.views,
      response_count: historyState.response_count,
      status: historyState.status,
      rfp_id: historyState.rfp_id
    }

    var compareResponses = sessionStorage.getItem('compare_responses');
    compareResponses = JSON.parse(compareResponses);
    var compareResponsesKeys = [];
    if (compareResponses) {
      compareResponsesKeys = Object.keys(compareResponses);
    }
    var closedRFP = false;
    const rfpClosedDate = new Date(rfpDetails.closes_on_date);
    const today = new Date(); 
    if (rfpDetails.status !== "AWARDED" || rfpClosedDate <= today) {
      closedRFP = true;
    }

    return (
      <div style={{margin:20}}>
        <Dialog
          open={state.declineResponseOpen}
          onClose={() => null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Would you like to decline this response?</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
             <Button
              onClick={() => {
                this.setState({declineResponseOpen: false});
                if (state.rfp && state.rfp.rfp_response.id) {
                  props.declineResponse(props, state.rfp.rfp_response.id);
                }
              }}
              color="primary">
              Yes
            </Button>
            <Button
              onClick={() => this.setState({declineResponseOpen: false})}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{margin:5, marginBottom:20}} variant="display1">
                  {historyState.title}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{margin:5}} variant="subheadline">
                  Opened On: {moment(historyState.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography style={{margin:5}} variant="subheadline">
                  Closes On: {moment(historyState.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{margin:5}} variant="subheadline">
                  Viewed By: {historyState.views}
                </Typography>
                <Typography style={{margin:5}} variant="subheadline">
                  Responses: {historyState.response_count}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{margin:5}} variant="subheadline">
                  Status: {historyState.status}
                </Typography>
                <Typography style={{margin:5}} variant="subheadline">
                  <Checkbox
                    style={{padding: "0 5 0 0"}}
                    checked={this.state.compare}
                    onChange={() => {
                      
                      if (state.rfp && state.rfp.rfp_response) {
                        if (this.state.compare === true) {
                          if (compareResponses) {
                            delete compareResponses[state.rfp.rfp_response.id];
                          }
                          sessionStorage.setItem('compare_responses', JSON.stringify(compareResponses));
                        }
                        else {
                          if (compareResponses === null) {
                            compareResponses = {};
                          }
                          compareResponses[state.rfp.rfp_response.id] = state.rfp;
                          sessionStorage.setItem('compare_responses', JSON.stringify(compareResponses));
                        }
                      }
                      
                      this.setState({compare: !this.state.compare});
                    }}
                    color="primary"
                  />
                  Compare this Response
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{margin:5}} variant="subheadline">
                  <Checkbox
                    style={{padding: "0 5 0 0"}}
                    checked={this.state.favorite}
                    onChange={() => {
                      if (state.rfp && state.rfp.rfp_response.id) {
                        props.setFavoriteStatus(state.rfp.rfp_response.id, !state.favorite, this.setState.bind(this));
                      }
                    }}
                    color="primary"
                  />
                  Favorite this Response
                </Typography>
                {
                  closedRFP ?
                    <Button
                      style={{
                        padding: "2 0 2 10",
                        margin: 0,
                        minHeight: 0,
                        color:'red'
                      }}
                      onClick={() => {
                        this.setState({declineResponseOpen: true});
                      }}>
                      <img 
                        src={require('./../assets/denied-icon.png')} 
                        style={{width:20, height: 20, marginRight:5}}
                      />
                      Decline this Response
                    </Button>
                    :
                    null
                }
              </Grid>
            </Grid>
            <Divider 
              style={{
                marginTop:40, 
                marginBottom:40,   
                backgroundColor: grey[300]
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}
            style={{
              borderRightStyle: 'solid',
              borderRightWidth: 1,
              color: grey[300]
            }}>
            {
              props.rfpResponses.length > 1 ? 
              (
                <div>
                  <Typography 
                    variant="title" 
                    style={{
                      marginBottom:10,
                    }}>
                    Compare
                  </Typography>
                  {
                    props.rfpResponses.length > 1 ?
                    (
                      <Button 
                        color='primary'
                        style={{
                          fontSize: 10
                        }}
                        onClick={() => {
                          props.history.push('/compare-responses', {
                            rfpResponses: props.rfpResponses,
                            servicesNeeded: state.service_needed,
                            rfpTitle: historyState.title
                          });
                        }}>
                        Compare All
                      </Button>
                    )
                    :
                    (
                      <div/>
                    )
                  }
                  {
                    compareResponsesKeys.length > 1 ?
                    (
                      <Button 
                        color='primary'
                        style={{
                          fontSize: 10
                        }}
                        onClick={() => {
                          const rfpResponses = compareResponsesKeys.map(key => compareResponses[key]);
                          props.history.push('/compare-responses', {
                            rfpResponses: rfpResponses,
                            servicesNeeded: state.service_needed,
                            rfpTitle: historyState.title
                          });
                        }}>
                        Compare Selected
                      </Button>
                    )
                    :
                    (
                      <div/>
                    )
                  }
                  <Button 
                    color='primary'
                    style={{
                      fontSize: 10
                    }}
                    onClick={() => {
                      sessionStorage.removeItem('compare_responses');
                      this.setState({compare: false});
                    }}>
                    Clear Selected
                  </Button>
                </div>
              )
              :
              (
                <div/>
              )
            }
            <Typography 
              variant="title" 
              style={{
                marginBottom:20,
                marginTop:20,
              }}>
              Responses
            </Typography>
            {props.getResponseViews(props, state, this.setState.bind(this))}
          </Grid>
          <Grid item xs={10}>
            <AgencyViewRFP rfp_response={this.state.rfp} rfp_details={rfpDetails} {...this.props}/>
            {
              closedRFP ?
              <Button 
                style={{margin:50, width:200}}
                variant="contained"
                color="secondary"
                onClick={() => props.awardRFP(props, state)}>
                Award RFP
              </Button>
              :
              null
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

ViewResponses.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(ViewResponses));