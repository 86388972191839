var init = {
  is_authenticated: false,
  profile: null
};

const reducer = (state = init, action) => {

  switch (action.type) {
    case 'USER_SET_IS_AUTHENTICATED': {
      return Object.assign({}, state, {
        is_authenticated: action.payload
      });
      break;
    }
    case 'USER_SET_PROFILE': {
      return Object.assign({}, state, {
        profile: action.payload
      });
      break;
    }
    default: {
      return Object.assign({}, state);
    }
  }
}

export default reducer;
