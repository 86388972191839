import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class ClientClosedRFPs extends React.Component {

  constructor() {
    super();
    this.state = {
      sort: 'desc',
      toggleSelected: 'both'
    };
  }

  componentDidMount(){
    this.props.setHeaderTab('CLOSED')
    this.props.getRFPS(this.props, this.state);
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.rfps) {
      if (props.rfps.length > 0) {
        return (
          <div>
            <Typography variant="headline" style={{margin:30}}>
              Your Closed RFPs:
            </Typography>
            <div style={{marginLeft: 40}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Gender"
                  name="gender1"
                  className={classes.group}
                  value={this.state.toggleSelected}
                  onChange={(event) => this.setState({ toggleSelected: event.target.value })}
                  style={{flexDirection: "row"}}>
                  <FormControlLabel value="awarded" control={<Radio />} label="Awarded" />
                  <FormControlLabel value="unawarded" control={<Radio />} label="Unawarded" />
                  <FormControlLabel value="both" control={<Radio />} label="Both" />
                </RadioGroup>
              </FormControl>
            </div>
            <Typography variant="subheading" style={{marginLeft:55, marginTop:20}}>
              Close Date
            </Typography>
            {
              state.sort === 'desc' ? 
              (
                <Button 
                  style={{marginLeft:60}}
                  onClick={() => {
                    this.setState({sort: 'asc'});
                    props.sortRFP(state.sort, state.toggleSelected);
                  }}>
                  <MdKeyboardArrowDown size={30}/>
                </Button>
              )
              :
              (
                <Button 
                  style={{marginLeft:60}}
                  onClick={() => {
                    this.setState({sort: 'desc'});
                    props.sortRFP(state.sort, state.toggleSelected);
                  }}>
                  <MdKeyboardArrowUp size={30}/>
                </Button>
              )
            }
            {props.getViews(props, state)}
          </div>
        );
      }
      else {
        return (
          <div>
            
          </div>
        );
      }
    }
    else {
      return (<div/>);
    }
  }
}

ClientClosedRFPs.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(ClientClosedRFPs));