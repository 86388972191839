import * as React from 'react';
import { connect } from 'react-redux';
import AgencyDraftResponses from './AgencyDraftResponsesComponent';
import { GetAgencyDraftResponses, PublishRFPResponse, GetAgencyDashboard } from './../util/requests';
import {loading, simpleAlert, setAgencyHeader} from './../reducers/displayActions';
import { setAgencyDraftRFPs } from './../reducers/rfpActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import { setAgencyDashboard } from './../reducers/rfpActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfps: state.rfpReducer.agency_draft_rfps,
    totalDraftResponses: state.rfpReducer.agency_dashboard.total_draft_responses
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPS: (props) => {
      dispatch(loading(true));
      dispatch(GetAgencyDraftResponses())
      .then(res => {
        dispatch(setAgencyDraftRFPs(res.value.data));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetAgencyDraftResponses err", err);
      });

      dispatch(GetAgencyDashboard())
      .then(res => {
        dispatch(setAgencyDashboard(res.value.data));
      })
      .catch(err => {
        console.log("GetAgencyDashboard err", err);
      });
    },
    getViews: (props) => {
      var views = [];
      props.rfps.forEach((draft, index) => {

        var viewCount = 0;
        if (draft.rfp_meta) {
          viewCount = draft.rfp_meta.views;
        }

        views.push(
          <div style={{margin:50}}>
            <Grid container>
              <Grid item md={2}>
              </Grid>
              <Grid item md={10} style={{margin: "20 0"}}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    marginRight: 40,
                    width: 125
                  }}
                  onClick={() => {
                    props.history.push('/edit-agency-response', {
                      rfp_id: draft.rfp.id,
                      rfp_response_id: draft.rfp_response.id
                    });
                  }}>
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: 125}}
                  onClick={() => {
                    props.publishRFP(props, draft.rfp_response.id);
                  }}>
                  Publish
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2}>
                <Typography variant="body">
                  {moment(draft.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Button
                  style={{
                    padding: 0, 
                    margin: 0, 
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 20,
                    textTransform: 'none'
                  }} 
                  color="secondary"
                  onClick={() => {
                    props.history.push('/edit-agency-response', {
                      rfp_id: draft.rfp.id,
                      rfp_response_id: draft.rfp_response.id
                    });
                  }}>
                  {draft.rfp.title}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2}/>
              <Grid item md={4}>
                <Typography variant="body">
                  Opened On: {moment(draft.rfp.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="body">
                  Closes On: {moment(draft.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant="body">
                  Viewed By: {viewCount}
                </Typography>
                {/* <Typography variant="body">
                  Responses: {draft.rfp.response_count}
                </Typography> */}
              </Grid>
              <Grid item md={2}>
                <Button
                  onClick={() => props.history.push('/test-pdf', {
                    account_type: "AGENCY",
                    rfp_details: {
                      rfp_id: draft.rfp.id,
                      title: draft.rfp.title,
                      views: viewCount,
                      opened_date: draft.rfp.closes_on_date,
                      closes_on_date: draft.rfp.closes_on_date,
                      response_count: draft.rfp.response_count,
                    }
                  })}>
                  <img 
                    src={require('./../assets/print-icon.png')} 
                    style={{maxWidth:25}}
                  />
                </Button>
              </Grid>
            </Grid>
            <Divider 
              style={{
                marginTop:60, 
                marginBottom:20, 
                marginLeft:100, 
                marginRight:100, 
                backgroundColor: grey[300]
              }}
            />
          </div>
        );
      })
      return views;
    },
    publishRFP: (props, rfp_response_id) => {
      dispatch(PublishRFPResponse({rfp_response_id: rfp_response_id}))
      .then(res => {
        if (res) {
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your response has been published'
          }));
        }
        dispatch(loading(false));
        props.history.push('/', {reloadData: true});
        dispatch(setAgencyHeader('OPEN'));
        sessionStorage.removeItem('agency_responses');
      })
      .catch(err => {
        console.log('PublishRFP err', err);
        dispatch(loading(false));
        if (
          err.response && err.response.data && 
          err.response.data.results && err.response.data.results.display_message
        ) {
          dispatch(simpleAlert({
            title:'Error',
            body: "Your RFP was saved as a draft. " + err.response.data.results.display_message
          }));
        }
        else if (err.response && err.response.data && err.response.data.display_message) {
          dispatch(simpleAlert({
            title:'Error',
            body: "Your RFP was saved as a draft. " + err.response.data.display_message 
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'Your RFP was saved as a draft. There was an error publishing this RFP. Please try again later.'
          }));
        }
        props.history.push('/', {reloadData: true});
        dispatch(setAgencyHeader('OPEN'));
        sessionStorage.removeItem('agency_responses');
      })
    }
  }
}

const AgencyDraftResponsesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyDraftResponses);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AgencyDraftResponsesContainer;