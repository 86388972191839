import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class EditProfile extends React.Component {

  componentDidMount(){

  }

  state = {
    open: true,
  };

  render() {

    var profileTypeFromHistory = null;

    if (this.props.history.location.state && this.props.history.location.state.profileType) {
      profileTypeFromHistory = this.props.history.location.state.profileType;
    }

    if (this.props.profile || profileTypeFromHistory) {
      if ((this.props.profile && this.props.profile.type === 'CLIENT') || profileTypeFromHistory === 'CLIENT') {
        this.props.history.push('/edit-marketer-profile');
        return (<div/>);
      }
      else if ((this.props.profile && this.props.profile.type) === 'AGENCY' || profileTypeFromHistory === 'AGENCY') {
        this.props.history.push('/edit-agency-profile');
        return (<div/>);
      }
      else {
        return (
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">Please select an account type</DialogTitle>
              <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                  Client: enter description
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  Agency: enter description
                </DialogContentText> */}
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={() => {
                    this.setState({open: false});
                    this.props.history.push('/edit-marketer-profile');
                  }} 
                  color="primary">
                  Marketer
                </Button>
                <Button 
                  onClick={() => {
                    this.setState({open: false});
                    this.props.history.push('/edit-agency-profile');
                  }} 
                  color="primary" autoFocus>
                  Agency
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }

    return (<div/>);
  }
}

const styles = theme => ({

});

EditProfile.propTypes = {

};

export default withRoot(withStyles(styles)(EditProfile));