export const setIsAuthenticated = (data) => {
  return {
    type: 'USER_SET_IS_AUTHENTICATED',
    payload: data
  }
}

export const setProfile = (data) => {
  return {
    type: 'USER_SET_PROFILE',
    payload: data
  }
}