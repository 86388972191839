var init = {
  simple_alert_open: false,
  simple_alert_title: '',
  simple_alert_body: '',
  loading: false,
  client_header_tab: 'OPEN',
  agency_header_tab: 'OPEN'
};

const reducer = (state = init, action) => {

  switch (action.type) {
    case 'DISPLAY_REDUCER_SIMPLE_ALERT_BODY': {
      return Object.assign({}, state, {
        simple_alert_title: action.payload.title,
        simple_alert_body: action.payload.body,
        simple_alert_open: action.payload.open === false ? false : true
      });
      break;
    }
    case 'DISPLAY_REDUCER_LOADING': {
      return Object.assign({}, state, {
        loading: action.payload
      });
      break;
    }
    case 'SET_CLIENT_HEADER_TAB': {
      return Object.assign({}, state, {
        client_header_tab: action.payload
      });
      break;
    }
    case 'SET_AGENCY_HEADER_TAB': {
      return Object.assign({}, state, {
        agency_header_tab: action.payload
      });
      break;
    }
    default: {
      return Object.assign({}, state);
    }
  }
}

export default reducer;
