import * as React from 'react';
import { connect } from 'react-redux';
import EditAgencyResponse from './EditAgencyResponseComponent';
import {loading, simpleAlert, setAgencyHeader} from './../reducers/displayActions';
import { 
  GetTemplate, 
  CreateResponse, 
  GetRFP, 
  PublishRFPResponse,
  GetRFPResponse,
  UpdateRFPQuestionResponses,
  GetRFPAgencyQuestions 
} from './../util/requests';
import {
  setAgencyTemplate,
  setEditRFPProperty,
  setViewRFP,
  setRFPResponse,
  setClientAnswersByProperty
} from './../reducers/rfpActions';
import RFPQuestion from './RFPQuestionComponent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    agency_template: state.rfpReducer.agency_template,
    profile: state.userReducer.profile,
    tempAgencyQuestions: state.rfpReducer.template_agency_questions,
    rfp: state.rfpReducer.view_rfp,
    rfpResponse: state.rfpReducer.rfp_response,
    clientAnswers: state.rfpReducer.client_answers_by_property
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPResponse: (historyState) => {
      dispatch(loading(true));
      dispatch(GetRFPResponse({
        rfp_id: historyState.rfp_id,
        rfp_response_id: historyState.rfp_response_id
      }))
      .then(res => {
        dispatch(setRFPResponse(res.value.data));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('GetRFPResponse err', err);
      })
    },
    getClientResponses: (props) => {
      var views = [];
      if (props.rfp && props.rfp.client_responses) {
        const responses = props.rfp.client_responses;        
        responses.forEach(response => {
          const templateQuestion = response.template_question;
          const clientResponse = response.client_response;
          if (templateQuestion.visible_to_responder === true) {
            var description = templateQuestion.description;
            if (templateQuestion.agency_description) {
              description = templateQuestion.agency_description;
            }
            switch (clientResponse.answer_type.toLowerCase()) {
              case 'string': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      {clientResponse.answer}
                    </Typography>
                  </Grid>
                );
                break;
              }
              case 'currency': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      {clientResponse.answer}
                    </Typography>
                  </Grid>
                );
                break;
              }
              case 'array': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      {clientResponse.answer.toString().replace(new RegExp(',', 'g'), ', ')}
                    </Typography>
                  </Grid>
                );
                break;
              } 
              case 'boolean': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      {clientResponse.answer ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                );
                break;
              } 
              case 'date': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    <Typography variant="body2">
                      {moment(clientResponse.answer).format('M/D/YYYY')}
                    </Typography>
                  </Grid>
                );
                break;
              }
              case 'object': {
                views.push(
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Typography variant="subheading">
                      {description}
                    </Typography>
                    {
                      templateQuestion.text_fields.map(textField => {
                        var answer = clientResponse.answer[textField.description];
                        if (answer !== undefined && answer !== undefined && answer !== '') {
                          return (
                            <div style={{margin:0}}>
                              <Typography variant="body2">
                                {textField.description}: {answer !== undefined ? answer : ''}
                              </Typography>
                            </div>
                          );
                        }
                      })
                    }
                  </Grid>
                );
                break;
              }  
              default:
                break;
            }
          }
        })
      }

      return views;
    },
    getCompanyDetails: (props) => {
      if (props.rfp && props.rfp.client_profile) {
        const clientProfile = props.rfp.client_profile;
        return (
          <Grid container spacing={24} style={{padding:40}}> 
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Job Function
              </Typography>
              <Typography variant="body2">
                {clientProfile.job_function}
              </Typography>
            </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Seniority Level
              </Typography>
              <Typography variant="body2">
                {clientProfile.seniority_level}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Client company type
              </Typography>
              <Typography variant="body2">
                {clientProfile.company_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Client industry
              </Typography>
              <Typography variant="body2">
                {clientProfile.industry_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Years in business
              </Typography>
              <Typography variant="body2">
                {clientProfile.years_in_business}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Number of people in the marketing department
              </Typography>
              <Typography variant="body2">
                {clientProfile.number_of_marketers}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Has worked with an agency or contractor to outsource marketing services before
              </Typography>
              <Typography variant="body2">
                {clientProfile.has_outsourced_marketing ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Audience/customer type
              </Typography>
              <Typography variant="body2">
                {clientProfile.audience_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Market focus
              </Typography>
              <Typography variant="body2">
                {clientProfile.market_focus}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Titles of internal employees that will be involved in this project
              </Typography>
              <Typography variant="body2">
                {clientProfile.internal_employee_titles}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Typography variant="subheading">
                Number of people in the company
              </Typography>
              <Typography variant="body2">
                {clientProfile.number_of_employees}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      return (<div/>);
    },
    getRFP: (props) => {
      dispatch(loading(true))
      dispatch(GetRFP(props.rfp_id))
      .then(res => {
        dispatch(setViewRFP(res.value.data));
        var clientAnswersByProperty = {};
        if (res.value.data.client_responses) {
          res.value.data.client_responses.forEach(clientRes => {
            if (clientRes.template_question && clientRes.template_question.property) {
              clientAnswersByProperty[clientRes.template_question.property] = clientRes;
            }
          })
        }
        dispatch(setClientAnswersByProperty(clientAnswersByProperty));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('getRFP err', err);
      }) 
    },
    getTemplate: (rfpId) => {
      dispatch(loading(true));

      dispatch(GetRFPAgencyQuestions({rfp_id: rfpId}))
      .then(res => {
        var tempQuestions = res.value.data.template_questions;
        var templateId = res.value.data.template_id;
        dispatch(setAgencyTemplate({id: templateId}))
        var rfpQuestions = {};
        tempQuestions.forEach((tempQuestion) => {
          rfpQuestions[tempQuestion.id] = tempQuestion;
        });
        dispatch(setEditRFPProperty({
          value: rfpQuestions,
          prop: 'template_agency_questions'
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('GetTemplate err', err);
      })
    },
    getTempRFPQuestions: (props, disabled) => {
      var questions = [];
      const historyState = props.history.location.state;
      if (historyState && historyState.rfp_id && historyState.rfp_response_id) {
        if (props.rfpResponse && props.rfpResponse.length > 0) {
          props.rfpResponse.forEach(response => {
            var subText = null;
            if (props.rfp && props.rfp.client_profile && response.template_question.mapped_client_profile_prop) {
              const clientProfile = props.rfp.client_profile;
              if (clientProfile[response.template_question.mapped_client_profile_prop] !== undefined) {
                subText = clientProfile[response.template_question.mapped_client_profile_prop];
              }
            }
            if (props.clientAnswers && response.template_question.mapped_client_response_prop) {
              const answer = props.clientAnswers[response.template_question.mapped_client_response_prop];
              if (answer && answer.client_response) {
                if (answer.client_response.answer_type !== 'OBJECT') {
                  subText = answer.client_response.answer
                }
                else {
                  Object.keys(answer.client_response.answer).forEach((key, index) => {
                    if (index === 0) {
                      subText = key +': ' + answer.client_response.answer[key];
                    }
                    else {
                      subText += ', ' + key +': ' + answer.client_response.answer[key];
                    }
                  })
                }
                
              }
            }
            
            questions.push(
              <RFPQuestion
                {...props}
                role="ANSWER" 
                sub_text={subText}
                disabled={disabled}
                initialValue={response.rfp_question_response ? response.rfp_question_response.answer : null}
                title={response.template_question.title}
                description={response.template_question.description}
                id={response.template_question.id}
                answers={response.template_question.answers}
                questionType={response.template_question.question_type}
                responseType={response.template_question.response_type}
                required={response.template_question.required}
                answerChanged={(answer) => {
                  var answers = sessionStorage.getItem('agency_responses');
                  answers = JSON.parse(answers);
                  if (answers === null || answers === undefined) {
                    answers = {};
                  }
                  answers[response.template_question.id] = {
                    question_response_id: response.rfp_question_response.id,
                    answer: answer
                  };
                  sessionStorage.setItem('agency_responses', JSON.stringify(answers));
                }}
              />
            )
          })
        }
      } 
      else {
        if (props.tempAgencyQuestions) {
          var keys = Object.keys(props.tempAgencyQuestions);
          var rfpQuestionsArray = [];
          keys.forEach(key => {
            rfpQuestionsArray.push(props.tempAgencyQuestions[key]);
          })
          if (rfpQuestionsArray) {
            rfpQuestionsArray.sort((a, b) => {
              const aOrder = parseInt(a.order);
              const bOrder = parseInt(b.order);
              
              if (aOrder < bOrder) {
                return -1
              }
              else if (aOrder > bOrder){
                return 1;
              }
              else {
                return 0;
              }
            });
            rfpQuestionsArray.forEach(rfpQuestion => {
              var subText = null;
              if (props.rfp && props.rfp.client_profile && rfpQuestion.mapped_client_profile_prop) {
                const clientProfile = props.rfp.client_profile;
                if (clientProfile[rfpQuestion.mapped_client_profile_prop] !== undefined) {
                  subText = clientProfile[rfpQuestion.mapped_client_profile_prop];
                }
              }
              if (props.clientAnswers && rfpQuestion.mapped_client_response_prop) {
                const answer = props.clientAnswers[rfpQuestion.mapped_client_response_prop];
                if (answer && answer.client_response) {
                  if (answer.client_response.answer_type !== 'OBJECT') {
                    subText = answer.client_response.answer
                  }
                  else {
                    Object.keys(answer.client_response.answer).forEach((key, index) => {
                      if (index === 0) {
                        subText = key +': ' + answer.client_response.answer[key];
                      }
                      else {
                        subText += ', ' + key +': ' + answer.client_response.answer[key];
                      }
                    })
                  }
                  
                }
              }
              questions.push(
                <RFPQuestion
                  {...props}
                  role="ANSWER"
                  sub_text={subText}
                  disabled={disabled}
                  initialValue={null}
                  title={rfpQuestion.title}
                  description={rfpQuestion.description}
                  id={rfpQuestion.id}
                  answers={rfpQuestion.answers}
                  questionType={rfpQuestion.question_type}
                  responseType={rfpQuestion.response_type}
                  required={rfpQuestion.required}
                  answerChanged={(answer) => {
                    var answers = sessionStorage.getItem('agency_responses');
                    answers = JSON.parse(answers);
                    if (answers === null || answers === undefined) {
                      answers = {};
                    }
                    answers[rfpQuestion.id] = answer;
                    sessionStorage.setItem('agency_responses', JSON.stringify(answers));
                  }}
                />
              )
            })
          }
        }
      }
      return questions;
    },
    saveResponse: (props, state, publish) => {
      var formattedAnswers = [];
      
      // BUILDING A LIST OF ANSWERS TO THE CLIENT QUESTIONS BASED ON THE TEMPLATE
      // VALIDATE IF AN ANSWER IS REQUIRED AND IS THE RIGHT TYPE

      if (props.history.location.state && props.history.location.state.rfp_id) {
        const rfpId = props.history.location.state.rfp_id;
        var validRequest = true;

        if (props.tempAgencyQuestions) {
          var answers = sessionStorage.getItem('agency_responses');
          answers = JSON.parse(answers);
          const keys = Object.keys(props.tempAgencyQuestions);
          for (let i = 0; i < keys.length; i++) {

            const key = keys[i];
            const question = props.tempAgencyQuestions[key];

            var answer = null;
            if (answers && answers[question.id] !== undefined) {
              answer = answers[question.id];
            }

            formattedAnswers.push(
              {
                template_question_id: question.id,
                answer_type: question.response_type,
                answer: answer
              }
            );
          }
        }
  
        var newResponse = {
          rfp_id: rfpId,
          template_id: props.agency_template.id,
          rfp_question_responses: formattedAnswers
        }
  
        dispatch(loading(true));
        dispatch(CreateResponse(newResponse))
        .then(res => {
          if (res.value && res.value.data && res.value.data.new_agency_res && publish) {

            if (validRequest === true) {
              props.publishRFP(props, res.value.data.new_agency_res.id);
            }
            else {
              dispatch(loading(false));
              dispatch(simpleAlert({
                title: 'Error',
                body: 'This RFP was saved as a draft. Please enter an answer to all required questions to publish'
              }));
              props.history.push('/', {reloadData: true});
              dispatch(setAgencyHeader('OPEN'));
              sessionStorage.removeItem('agency_responses');
            }
          }
          else {
            dispatch(loading(false));
            dispatch(simpleAlert({
              title: 'Success',
              body: 'Your response has been saved as a draft'
            }));
            props.history.push('/', {reloadData: true});
            dispatch(setAgencyHeader('OPEN'));
            sessionStorage.removeItem('agency_responses');
          }
        })
        .catch(err => {
          dispatch(loading(false));
          if (
            err.response && err.response.data && 
            err.response.data.results && err.response.data.results.display_message
          ) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.results.display_message
            }));
          }
          else if (err.response && err.response.data && err.response.data.display_message) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error creating this RFP Draft. Please try again later.'
            }));
          }
        })
      }
    },
    updateDraft: (props, state, publish) => {
      var formattedAnswers = [];
      const historyState = props.history.location.state;
      if (historyState && historyState.rfp_id && historyState.rfp_response_id) {
        var formattedAnswers = [];
        var answersString = sessionStorage.getItem('agency_responses');
        var answers = JSON.parse(answersString);
        if (answers) {
          Object.keys(answers).forEach(key => {
            const answer = answers[key];
            formattedAnswers.push({
              id: answer.question_response_id,
              answer: answer.answer
            });
          });
        }
        dispatch(loading(true));
        dispatch(UpdateRFPQuestionResponses({rfp_question_responses: formattedAnswers}))
        .then(res => {
          if (publish) {
            props.publishRFP(props, historyState.rfp_response_id);
          }
          else {
            dispatch(simpleAlert({
              title: 'Success',
              body: 'Your response has been saved as a draft'
            }));
          }
          dispatch(loading(false));
        })
        .catch(err => {
          dispatch(loading(false));
          if (
            err.response && err.response.data && 
            err.response.data.results && err.response.data.results.display_message
          ) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.results.display_message
            }));
          }
          else if (err.response && err.response.data && err.response.data.display_message) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error updating this Draft RFP Response. Please try again later.'
            }));
          }
          console.log('Create RFP err', err);
        })
      }
    },
    publishRFP: (props, rfp_response_id) => {
      dispatch(PublishRFPResponse({rfp_response_id: rfp_response_id}))
      .then(res => {
        if (res) {
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your response has been published'
          }));
        }
        dispatch(loading(false));
        props.history.push('/', {reloadData: true});
        dispatch(setAgencyHeader('OPEN'));
        sessionStorage.removeItem('agency_responses');
      })
      .catch(err => {
        console.log('PublishRFP err', err);
        dispatch(loading(false));
        if (
          err.response && err.response.data && 
          err.response.data.results && err.response.data.results.display_message
        ) {
          dispatch(simpleAlert({
            title:'Error',
            body: "Your RFP was saved as a draft. " + err.response.data.results.display_message
          }));
        }
        else if (err.response && err.response.data && err.response.data.display_message) {
          dispatch(simpleAlert({
            title:'Error',
            body: "Your RFP was saved as a draft. " + err.response.data.display_message 
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'Your RFP was saved as a draft. There was an error publishing this RFP. Please try again later.'
          }));
        }
        props.history.push('/', {reloadData: true});
        dispatch(setAgencyHeader('OPEN'));
        sessionStorage.removeItem('agency_responses');
      })
    }
  }
}

const EditAgencyResponseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAgencyResponse);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default EditAgencyResponseContainer;