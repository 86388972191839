import React from 'react';
import { connect } from 'react-redux';
import Login from './LoginComponent';
import {
  SignIn, 
  SignUp, 
  GetUserProfile,
  GetClientDashboard,
  GetAgencyDashboard,
  ForgotPassword
} from './../util/requests';
import { LoadEnvironment, UpdateEvironment} from './../util/persist';
import {setIsAuthenticated, setProfile} from './../reducers/userActions';
import { loading, simpleAlert } from './../reducers/displayActions';
import { setClientDashboard, setAgencyDashboard } from './../reducers/rfpActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.userReducer.is_authenticated
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    forgotPassword: (email, setState) => {
      dispatch(loading(true));
      dispatch(ForgotPassword({email:email}))
      .then(res => {
        dispatch(loading(false));
        dispatch(simpleAlert({
          title:'Success',
          body: 'Please check your email to finish resetting your password'
        }));
        setState({mode: 'SIGN_IN'});
      })
      .catch(err => {
        dispatch(loading(false));
        if (err.response && err.response.data && err.response.data.err_code === 'da0200') {
          dispatch(simpleAlert({
            title:'Error',
            body: 'No user found with this email address'
          }));
        }
        else if (err.response && err.response.data && err.response.data.display_message) {
          dispatch(simpleAlert({
            title:'Error',
            body: err.response.data.display_message
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'There was an error generating the forgot password email.'
          }));
        }
        console.log('ForgotPassword Err', err);
      });
    },
    signIn: (props, email, password, newUser, profileType) => {
      if (email !== '' && password != '') {
        dispatch(loading(true));
        dispatch(SignIn({
          email: email,
          password: password
        }))
        .then(res => {
          var account = res.value.data;
          
          UpdateEvironment({
            bs_email: email,
            bs_password: password,
            bs_api_token: account.bs_api_token,
            bs_login_type: 'BACKSTRAP'
          });
          dispatch(setIsAuthenticated(true));
          dispatch(loading(false));

          dispatch(GetClientDashboard())
          .then(res => {
            dispatch(setClientDashboard(res.value.data));
          })
          .catch(err => {
            console.log("GetClientDashboard err", err);
          })

          dispatch(GetAgencyDashboard())
          .then(res => {
            dispatch(setAgencyDashboard(res.value.data));
          })
          .catch(err => {
            console.log("GetAgencyDashboard err", err);
          })

          return dispatch(GetUserProfile())
        })
        .then(res => {
          if (res) {
            dispatch(setProfile(res.value.data));
            const profile = res.value.data;

            console.log('newUser', newUser);

            if (newUser === true) {
              props.history.push('/edit-profile',{profileType: profileType});
            }
            else if (profile.type === 'ADMIN') {
              props.history.push('agency-profiles');
            }
            else if (profile.type === 'AGENCY' && (profile.payment_source === undefined || profile.payment_source === null)) {
              dispatch(simpleAlert({
                title: 'Missing Payment Information',
                body: 'Please enter your payment information at the bottom'
              }));
              props.history.push('/edit-agency-profile');
            }
            else {
              props.history.push('/');
            } 
          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.display_message) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error signing you in. Please try again later.'
            }));
          }
          dispatch(loading(false));
          console.log('Login Err', err);
        });
      }
    },
    signUp: (props, state) => {
      
      if (
        state.email !== '' && state.password !== '' && 
        state.confirmed_password !== '' && state.account_type !== null
      ) {

        if (state.password !== state.confirmed_password) {
          dispatch(simpleAlert({
            title: 'Error',
            body: 'Passwords do not match'
          }));
          return;
        }

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailRegex.test(state.email ) === false) {
          dispatch(simpleAlert({
            title: 'Error',
            body: "Please enter a valid email address"
          }));
          return;
        }
        
        dispatch(loading(true));
        dispatch(SignUp({
          email: state.email,
          password: state.password,
          account_type: state.account_type
        }))
        .then(res => {
          props.signIn(props, state.email, state.password, true, state.account_type);
        })
        .catch(err => {
          dispatch(loading(false));
          if (err.response && err.response.data && err.response.data) {
            const data = err.response.data;
            if (data.results && data.results.display_message) {
              dispatch(simpleAlert({
                title: 'Error',
                body: data.results.display_message
              }));
            }
            else if (data.display_message) {
              dispatch(simpleAlert({
                title: 'Error',
                body: data.display_message
              }));
            }
            else {
              dispatch(simpleAlert({
                title: 'Error',
                body: 'Error signing up'
              }));
            }
          }
          else {
            dispatch(simpleAlert({
              title: 'Error',
              body: 'Error signing up'
            }));
          }
          console.log('Sign Up Err', err);
        });
      }
      else {
        dispatch(simpleAlert({
          title: 'Error',
          body: 'Please enter all fields'
        }));
      }
    }
  }
}

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default LoginContainer;
