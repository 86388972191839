import * as React from 'react';
import { connect } from 'react-redux';
import EditProfile from './EditProfileComponent';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    
  }
}

const EditProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default EditProfileContainer;