import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { colors } from './../design';

class EditAgencyResponse extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){
    const historyState = this.props.history.location.state;
    this.props.getRFP(historyState);
    if (historyState && historyState.rfp_id && historyState.rfp_response_id) {
      this.props.getRFPResponse(historyState);
    }
    else {
      this.props.getTemplate(historyState.rfp_id);
    }
    sessionStorage.setItem('agency_responses', null);
    if (!(historyState && historyState.rfp_id)){
      this.props.history.push('/');
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;
    const historyState = this.props.history.location.state;

    var disabled = false;
    if (historyState && historyState.mode === 'VIEW') {
      disabled = true;
    }

    if (props.profile && props.profile.type === 'CLIENT') {
      props.history.push('/');
    }

    var rfpTitle = '';
    if (props.rfp && props.rfp.rfp && props.rfp.rfp.title) {
      rfpTitle = props.rfp.rfp.title;
    }

    return (
      <div style={{marginTop:50}}>
        <div>
          {
            historyState.mode === 'VIEW' && props.rfp && props.rfp.rfp.status === "AWARDED" ?
            (
              <div style={{marginTop:60, marginLeft:40}} >
                <Typography 
                  className={classes.typography} variant="display1" align='left' gutterBottom={false}>
                  Awarded From Marketer: {props.rfp.client_profile.company_name}
                </Typography>
                <Button
                  style={{
                    fontSize: 17,
                    textTransform: 'none'
                  }} 
                  onClick={() => {
                    this.props.history.push('/view-marketer', {
                      client_profile: props.rfp.client_profile,
                      rfp: props.rfp.rfp,
                      rfp_meta: props.rfp.meta
                    })
                  }}
                  color="secondary">
                  View Full Profile
                </Button>
              </div>
            )
            :
            (<div/>)
          }
          <div 
            style={{
              backgroundColor: colors.lightAccent
            }}>
            <Typography 
              style={{marginTop:60, marginLeft:40}} 
              className={classes.typography} variant="display1" align='left' gutterBottom={false}>
              Company Details
            </Typography>
          </div>
          {props.getCompanyDetails(props)}
        </div>
        <div>
          <div 
            style={{
              backgroundColor: colors.lightAccent
            }}>
            <Typography 
              style={{marginTop:60, marginLeft:40}} 
              className={classes.typography} variant="display1" align='left' gutterBottom={false}>
              RFP Details for {rfpTitle}
            </Typography>
          </div>
          <Grid container spacing={24} style={{padding:40}}>
            {props.getClientResponses(props)}
          </Grid>
          <Typography 
            style={{marginTop:60, marginLeft:40}} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Please answer the following questions
          </Typography>
          {this.props.getTempRFPQuestions(this.props, disabled)}
          {
            historyState && historyState.mode === 'VIEW' ?
            (
              <div/>
            )
            :
            (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginLeft:40, width:200}} 
                  onClick={() => {
                    if (historyState && historyState.rfp_id && historyState.rfp_response_id) {
                      props.updateDraft(props, this.state, false);
                    }
                    else {
                      props.saveResponse(props, this.state, false);
                    }
                  }}>
                  Save as Draft
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginLeft:40, width:200}} 
                  onClick={() => {
                    if (historyState && historyState.rfp_id && historyState.rfp_response_id) {
                      props.updateDraft(props, this.state, true);
                    }
                    else {
                      props.saveResponse(props, this.state, true);
                    } 
                  }}>
                  Publish
                </Button>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

EditAgencyResponse.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(EditAgencyResponse));