import React from 'react';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRouteComponent';
import { GetUserProfile } from './../util/requests';
import { setProfile } from './../reducers/userActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.userReducer.is_authenticated,
    profile: state.userReducer.profile,
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getProfile: (props) => {
      
    }
  }
}

const PrivateRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null, {
    pure: false
  }
)(PrivateRoute);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default PrivateRouteContainer;
