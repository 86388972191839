import * as React from 'react';
import { connect } from 'react-redux';
import ResetPassword from './ResetPasswordComponent';
import {
  SignIn, 
  ResetPassword as ResetPasswordReq
} from './../util/requests';
import { LoadEnvironment, UpdateEvironment} from './../util/persist';
import {setIsAuthenticated, setProfile} from './../reducers/userActions';
import { loading, simpleAlert } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {

  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetPassword: (state, props) => {
      if (state.token && state.password && state.confirmed_password) {
        if (state.password === state.confirmed_password) {
          dispatch(loading(true))
          dispatch(ResetPasswordReq({token: state.token, password: state.password}))
          .then(res => {
            dispatch(simpleAlert({
              title:'Success',
              body: 'Your password has been reset please login.'
            }));
            dispatch(loading(false));
            props.history.push('/login');
          })
          .catch(err => {
            if (err.response && err.response.data && err.response.data.display_message) {
              dispatch(simpleAlert({
                title:'Error',
                body: err.response.data.display_message
              }));
            }
            else {
              dispatch(simpleAlert({
                title:'Error',
                body: 'There was an error resetting your password.'
              }));
            }
            dispatch(loading(false));
            console.log('ResetPassword Err', err);
          })
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'Passwords do not match'
          }));
        }
      }
    }
  }
}

const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ResetPasswordContainer;