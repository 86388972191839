import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

class SelectBool extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){
    if (this.props.initialValue === true) {
      this.setState({selected: 'yes'});
    }
    else if (this.props.initialValue === false) {
      this.setState({selected: 'no'});
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (
      this.props.initialValue !== null && this.props.initialValue !== undefined && 
      this.state.selected === undefined && this.props.initialValue !== ''
    ) {
      if (this.props.initialValue === true) {
        this.setState({selected: 'yes'});
      }
      else {
        this.setState({selected: 'no'});
      }
    }

    return (
      <div style={{marginBottom:0, width:300}}>
        <Typography
          className={classes.typography}
          style={{marginTop:20}}
          variant="subheading"
          align='left'
          gutterBottom={false}>
          {this.props.title}
        </Typography>
        <RadioGroup
          style={{display:'inline', marginLeft:5}}
          className={classes.group}
          value={state.selected}
          onChange={(event) => {
            if (props.disabled !== true) {
              this.setState({selected: event.target.value});
              this.props.optionSelected(event.target.value === 'yes');
            }
          }}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
    );
  }
}

SelectBool.propTypes = {
  optionSelected: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const styles = theme => ({

  classes: PropTypes.object.isRequired,
});

export default withRoot(withStyles(styles)(SelectBool));