export const setAgencyProfiles = (data) => {
  return {
    type: 'ADMIN_SET_AGENCY_PROFILES',
    payload: data
  }
}

export const setLocalAgencyVettedStatus = (data) => {
  return {
    type: 'ADMIN_SET_LOCAL_AGENCY_VETTED_STATUS',
    payload: data
  }
}

export const setLocalAgencyActiveStatus = (data) => {
  return {
    type: 'ADMIN_SET_LOCAL_AGENCY_ACTIVE_STATUS',
    payload: data
  }
}