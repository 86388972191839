import * as React from 'react';
import { connect } from 'react-redux';
import AgencyProfiles from './AgencyProfilesComponent';
import { loading, simpleAlert } from './../reducers/displayActions';
import { 
  GetAgencyProfiles, 
  SetAgencyVettedStatus,
  SetAgencyActiveStatus 
} from './../util/requests';
import { 
  setAgencyProfiles,
  setLocalAgencyVettedStatus,
  setLocalAgencyActiveStatus 
} from './../reducers/adminActions';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import moment from 'moment';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    profiles: state.adminReducer.agency_profiles
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return { 
    setSort: (props, dateAdded, vettedStatus, agencyName) => {
      if (props.profiles) {
        if (dateAdded) {
          dispatch(setAgencyProfiles(
            props.profiles.sort((a, b) => {

              var aDate = null;
              if (a.created_at) {
                aDate = new Date(a.created_at)
              }
    
              var bDate = null;
              if (b.created_at) {
                bDate = new Date(b.created_at)
              }
    
              if (dateAdded === 'desc') {
                if (aDate < bDate) {
                  return -1
                }
                else if (aDate > bDate){
                  return 1;
                }
                else {
                  return 0;
                }
              }
              else {
                if (aDate < bDate) {
                  return 1
                }
                else if (aDate > bDate){
                  return -1;
                }
                else {
                  return 0;
                }
              }
            })
          ));
        }

        if (vettedStatus) {
          var sorted = props.profiles.sort((a, b) => {
            if (vettedStatus === 'desc') {
              if (a.vetted === true && b.vetted === false) {
                return -1
              }
              else if (b.vetted === true && a.vetted === false) {
                return 1;
              }
              else {
                return 0;
              }
            }
            else {
              if (b.vetted === true && a.vetted === false) {
                return -1
              }
              else if (a.vetted === true && b.vetted === false) {
                return 1;
              }
              else {
                return 0;
              }
            }
          });
          
          dispatch(setAgencyProfiles(
            sorted.sort((a, b) => {
              if (vettedStatus === 'desc') {
                if (a.vetted === undefined && b.vetted !== undefined) {
                  return -1
                }
                else if (b.vetted === undefined && a.vetted !== undefined) {
                  return 1;
                }
                else {
                  return 0;
                }
              }
              else {
                if (b.vetted === undefined  && a.vetted !== undefined) {
                  return -1
                }
                else if (a.vetted === undefined  && b.vetted !== undefined) {
                  return 1;
                }
                else {
                  return 0;
                }
              }
            })
          ));
        }

        if (agencyName) {
          dispatch(setAgencyProfiles(
            props.profiles.sort((a, b) => {
              if (agencyName === 'desc') {
                if (a.agency_name < b.agency_name) {
                  return -1
                }
                else if (a.agency_name > b.agency_name){
                  return 1;
                }
                else {
                  return 0;
                }
              }
              else {
                if (a.agency_name < b.agency_name) {
                  return 1
                }
                else if (a.agency_name > b.agency_name){
                  return -1;
                }
                else {
                  return 0;
                }
              }
            })
          ))
        }
      }
    },
    setActiveProfileStatus: (agencyId, status) => {
      dispatch(loading(true));
      dispatch(SetAgencyActiveStatus({
        agency_profile_id: agencyId,
        is_active: status
      }))
      .then(res => {
        dispatch(loading(false));
        dispatch(setLocalAgencyActiveStatus({
          agency_profile_id: agencyId,
          is_active: status
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('SetAgencyVettedStatus err', err);
      })
    },
    setVettedStatus: (agencyId, vetted) => {
      dispatch(loading(true));
      dispatch(SetAgencyVettedStatus({
        agency_profile_id: agencyId,
        vetted: vetted
      }))
      .then(res => {
        dispatch(loading(false));
        dispatch(setLocalAgencyVettedStatus({
          agency_profile_id: agencyId,
          vetted: vetted
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('SetAgencyVettedStatus err', err);
      })
    }, 
    getAgencyProfiles: (props, setState) => {
      if (props.profiles === null) {
        dispatch(loading(true));
        dispatch(GetAgencyProfiles())
        .then(res => {
          var profiles = res.value.data;
          dispatch(setAgencyProfiles(profiles));
          dispatch(loading(false));
          var csvData = [
            ["Date Added", "Status" ,"Active", "Service Provider Name", "Service Provider Address", "Service Provider Email", "Service Provider Profile"]
          ];
          profiles.forEach(profile => {
            var status = 'Set Status';
            var active = 'No';
            if (profile.vetted === true) {
              status = 'Vetted'
            }
            else if (profile.vetted === false) {
              status = 'Not Vetted'
            }
            if (profile.is_active === true) {
              active = 'Yes'
            }
            csvData.push([
              moment(profile.created_at).format('M/D/YYYY'),
              status,
              active,
              profile.agency_name,
              profile.agency_city + ", " + profile.agency_state,
              profile.agency_email,
              profile.agency_phone_number
            ]);
          });
          setState({csvData: csvData})
        })
        .catch(err => {
          console.log('GetAgencyProfiles err', GetAgencyProfiles);
          dispatch(loading(false));
        })
      }
    },
    getTableCells: (props, setState) => {
      var cells = [];
      if (props.profiles) {
        props.profiles.forEach((profile) => {
          var status = 'Set Status';
          var active = 'No';
          if (profile.vetted === true) {
            status = 'Vetted'
          }
          else if (profile.vetted === false) {
            status = 'Not Vetted'
          }
          if (profile.is_active === true) {
            active = 'Yes'
          }
          cells.push(
            <TableRow key={profile.id}>
              <TableCell component="th" scope="row">
                {moment(profile.created_at).format('M/D/YYYY')}
              </TableCell>
              <TableCell>
                <Button 
                  color="secondary"
                  onClick={() => {
                    if (profile.is_active === true) {
                      setState({
                        setStatusOpen: true,
                        selectedProfile: profile
                      });
                    }
                    else {
                      dispatch(simpleAlert({
                        title: 'Error',
                        body: 'Please activate this user first'
                      }));
                    }
                  }}>
                  {status}
                </Button>
              </TableCell>
              <TableCell>
                <Button 
                  color="secondary"
                  onClick={() => {
                    setState({
                      setActiveOpen: true,
                      selectedProfile: profile
                    });
                  }}>
                  {active}
                </Button>
              </TableCell>
              <TableCell>{profile.agency_name}</TableCell>
              <TableCell>{profile.agency_city}, {profile.agency_state}</TableCell>
              <TableCell>{profile.agency_email}</TableCell>
              <TableCell>{profile.agency_phone_number}</TableCell>
            </TableRow>
          )
        });
      }
      return cells;
    }
  }
}

const AgencyProfilesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyProfiles);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AgencyProfilesContainer;