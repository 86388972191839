var init = {
  template_agency_questions: null,
  template_client_questions: null,
  client_template: null,
  existing_agency_questions: [],
  existing_client_answers: [],
  agency_template: null,
  client_dashboard: {
    active_open_rfps: 0,
    agency_responses: 0,
    awarded_responses: 0,
    closed_rfp: 0,
    draft_open_rfp: 0,
    all_rfps: 0
  },
  agency_dashboard: {
    total_draft_responses: 0,
    total_sent_responses: 0,
    total_closed_rfps: 0,
    total_responses: 0,
    total_awarded_responses: 0,
    total_open_rfps: 0,
    estimated_value_of_awarded_rfps: 0
  },
  client_open_rfps: null,
  client_draft_rfps: null,
  client_closed_rfps: null,
  client_closed_rfps_awarded: null,
  client_closed_rfps_unawarded: null,
  agency_open_rfps: null,
  agency_draft_rfps: null,
  agency_sent_rfps: null,
  agency_closed_rfps: null,
  agency_closed_rfps_won: null,
  agency_closed_rfps_lost: null,
  view_rfp: null,
  rfp_response: null,
  rfp_responses: [],
  compare_responses: [],
  client_answers_by_property: {}
};

const reducer = (state = init, action) => {

  switch (action.type) {
    case 'RFP_SET_CLIENT_ANSWERS_BY_PROPERTY': {
      return Object.assign({}, state, {
        client_answers_by_property: action.payload
      });
      break;
    }
    case 'CLEAR_FOR_EDIT_RFP': {
      return Object.assign({}, state, {
        template_agency_questions: null,
        template_client_questions: null,
        client_template: null,
        existing_agency_questions: [],
        existing_client_answers: [],
        agency_template: null
      });
      break;
    }
    case 'RFP_SET_COMPARE_RESPONSES': {
      return Object.assign({}, state, {
        compare_responses: action.payload
      });
      break;
    }
    case 'RFP_SET_RFP_RESPONSES': {
      return Object.assign({}, state, {
        rfp_responses: action.payload
      });
      break;
    }
    case 'RFP_SET_RFP_existing_agency_questions': {
      return Object.assign({}, state, {
        existing_agency_questions: action.payload
      });
      break;
    }
    case 'RFP_SET_RFP_existing_client_answers': {
      return Object.assign({}, state, {
        existing_client_answers: action.payload
      });
      break;
    }
    case 'RFP_SET_RFP_RESPONSE': {
      return Object.assign({}, state, {
        rfp_response: action.payload
      });
      break;
    }
    case 'RFP_SET_AGENCY_CLOSED_RFPS': {
      return Object.assign({}, state, {
        agency_closed_rfps: action.payload.rfps,
        agency_closed_rfps_won: action.payload.rfps_won,
        agency_closed_rfps_lost: action.payload.rfps_lost,
      });
      break;
    }
    case 'RFP_SET_AGENCY_SENT_RFPS': {
      return Object.assign({}, state, {
        agency_sent_rfps: action.payload
      });
      break;
    }
    case 'RFP_SET_AGENCY_DRAFT_RFPS': {
      return Object.assign({}, state, {
        agency_draft_rfps: action.payload
      })
      break;
    }
    case 'RFP_SET_VIEW_RFP': {
      return Object.assign({}, state, {
        view_rfp: action.payload
      })
      break;
    }
    case 'RFP_SET_AGENCY_OPEN_RFPS': {
      return Object.assign({}, state, {
        agency_open_rfps: action.payload
      })
      break;
    }
    case 'SET_CLIENT_DRAFT_RFPS': {
      return Object.assign({}, state, {
        client_draft_rfps: action.payload
      });
      break;
    }
    case 'RFP_SET_EDIT_RFP_PROPERTY': {
      var copy = Object.assign({}, state, {}); 
      copy[action.payload.prop] = action.payload.value;
      return copy;
      break;
    }
    case 'RFP_SET_RFP_CLIENT_TEMPLATE': {
      return Object.assign({}, state, {
        client_template: action.payload
      });
      break;
    }
    case 'RFP_SET_RFP_AGENCY_TEMPLATE': {
      return Object.assign({}, state, {
        agency_template: action.payload
      });
      break;
    }
    case 'SET_CLIENT_DASHBOARD': {
      return Object.assign({}, state, {
        client_dashboard: action.payload
      });
      break;
    }
    case 'SET_AGENCY_DASHBOARD': {
      return Object.assign({}, state, {
        agency_dashboard: action.payload
      });
      break;
    }
    case 'SET_CLIENT_OPEN_RFPS': {
      return Object.assign({}, state, {
        client_open_rfps: action.payload
      });
      break;
    }
    case 'SORT_CLIENT_OPEN_RFPS': {
      var copy = Object.assign({}, state, {});
      var rfps = copy.client_open_rfps; 
      console.log('rfps', rfps);
      if (rfps) {
        copy.client_open_rfps = sortRFPS(rfps, action.payload); 
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'RFP_SORT_AGENCY_OPEN_RFPS': {
      var copy = Object.assign({}, state, {});
      var rfps = copy.agency_open_rfps; 
      if (rfps) {
        copy.agency_open_rfps = sortRFPS(rfps, action.payload); 
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'SET_CLIENT_CLOSED_RFPS': {
      return Object.assign({}, state, {
        client_closed_rfps: action.payload.all,
        client_closed_rfps_awarded: action.payload.awarded,
        client_closed_rfps_unawarded: action.payload.unawarded
      });
      break;
    }
    case 'SORT_CLIENT_CLOSED_RFPS': {
      var copy = Object.assign({}, state, {});
      var rfps = copy.client_closed_rfps; 
      if (rfps) {
        copy.client_closed_rfps = sortRFPS(rfps, action.payload); 
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'SORT_CLIENT_CLOSED_UNAWARDED_RFPS': {
      var copy = Object.assign({}, state, {});
      var rfps = copy.client_closed_rfps_unawarded; 
      if (rfps) {
        copy.client_closed_rfps_unawarded = sortRFPS(rfps, action.payload); 
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'SORT_CLIENT_CLOSED_AWARDED_RFPS': {
      var copy = Object.assign({}, state, {});
      var rfps = copy.client_closed_rfps_awarded; 
      if (rfps) {
        copy.client_closed_rfps_awarded = sortRFPS(rfps, action.payload); 
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'RFP_DECLINE_RFP_RESPONSE': {
      var copy = Object.assign({}, state, {});
      var responses = copy.compare_responses; 
      if (responses) {
        var indexOfResponse = null;
        for (let i = 0; i < responses.length; i++) {
          const response = responses[i];
          if (response.rfp_response.id === action.payload) {
            indexOfResponse = i;
            break;
          }
        }
        if (indexOfResponse >= 0) {
          responses.splice(indexOfResponse, 1);
        }
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    case 'SET_LOCAL_RESPONSE_FAVORITE': {
      var copy = Object.assign({}, state, {});
      var responses = copy.compare_responses; 
      if (responses) {
        for (let i = 0; i < responses.length; i++) {
          const response = responses[i];
          if (response.rfp_response.id === action.payload.responseId) {
            response.rfp_response.client_favorite = action.payload.favorite;
            break;
          }
        }
        return copy;
      }
      else {
        return state;
      }
      break;
    }
    default: {
      return Object.assign({}, state);
    }
  }
}

export default reducer;

const sortRFPS = (rfps, sort) => {

  return rfps.sort((a, b) => {
    var aDate = null;
    if (a && a.data && a.data.closes_on_date) {
      aDate = new Date(a.data.closes_on_date)
    }
    else if (a.closes_on_date) {
      aDate = new Date(a.closes_on_date)
    }
    else if (a && a.rfp && a.rfp.closes_on_date) {
      aDate = new Date(a.rfp.closes_on_date)
    }

    var bDate = null;
    if (b && b.data && b.data.closes_on_date) {
      bDate = new Date(b.data.closes_on_date)
    }
    else if (b.closes_on_date) {
      bDate = new Date(b.closes_on_date)
    }
    else if (b && b.rfp && b.rfp.closes_on_date) {
      bDate = new Date(b.rfp.closes_on_date)
    }
    if (sort === 'desc') {
      if (aDate < bDate) {
        return -1
      }
      else if (aDate > bDate){
        return 1;
      }
      else {
        return 0;
      }
    }
    else {
      if (aDate < bDate) {
        return 1
      }
      else if (aDate > bDate){
        return -1;
      }
      else {
        return 0;
      }
    }
  });
}