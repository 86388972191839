import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import { colors } from './../design';

class ViewClient extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){
    const historyState = this.props.history.location.state;
    if (historyState && historyState.client_profile){
      console.log('historyState.client_profile', historyState.client_profile);
      console.log('historyState.rfp', historyState.rfp);
    }
    else {
      this.props.history.push('/');
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    var historyState = this.props.history.location.state;
    if (historyState.client_profile) {
      const profile = historyState.client_profile;
      const rfp = historyState.rfp;
      const meta = historyState.rfp_meta;

      console.log('profile', profile);

      return (
        <div>
          <div style={{ margin: 50 }}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="display1">
                  RFP: {rfp.title}
                </Typography>
                <Typography variant="subheading" style={{marginTop:20}}>
                  Status: {rfp.status === 'AWARDED' ? 'Won' : 'Lost'}
                </Typography>
                <Typography variant="subheading">
                  Marketer: {profile.company_name}
                </Typography>
                <Typography variant="subheading">
                  Viewed By: {meta ? meta.views : 0}
                </Typography>
                <Typography variant="subheading">
                  RFP Opened On: {moment(rfp.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="subheading">
                  RFP Closed On: {moment(rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider
            style={{
              marginTop: 60,
              marginBottom: 20,
              marginLeft: 100,
              marginRight: 100,
              backgroundColor: grey[300]
            }}
          />
          <div style={{ margin: 60 }}>
            <Grid container spacing={24}>
              <Grid item md={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    profile.profile_image ?
                      (
                        <img
                          style={{ width: 75, height: 75 }}
                          src={profile.profile_image}
                        />
                      )
                      :
                      (
                        <div />
                      )
                  }
                  <Typography variant="display1" style={{ marginLeft: profile.profile_image ? 20 : 0 }}>
                    {profile.company_name}
                  </Typography>
                </div>
              </Grid>

              {/* Contact Info */}
              <Grid item md={12} style={{backgroundColor: colors.lightAccent}}>
                <Typography variant="headline">
                  Contact Info:
                </Typography>
              </Grid>
              <Grid container spacing={24} style={{margin:10}}>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Location: {profile.company_city}, {profile.company_state}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    RFP Creator Name: {profile.rfp_creator_name}
                  </Typography>
                </Grid>
                  
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Company Phone Number: {profile.company_phone_number}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Company Phone Email: {profile.company_email}
                  </Typography>
                </Grid>
              </Grid>

              {/* Company Background */}
              <Grid item md={12} style={{backgroundColor: colors.lightAccent}}>
                <Typography variant="headline">
                  Company Background:
                </Typography>
              </Grid>
              <Grid container spacing={24} style={{margin:10}}>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Job Function: {profile.job_function}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Company Type: {profile.company_type}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Years in Business: {profile.years_in_business}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Number of Employees: {profile.number_of_employees}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Has Outsourced Marketing: {profile.has_outsourced_marketing}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    New Customer Worth: {profile.new_customer_worth}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Number of Marketers: {profile.number_of_marketers}
                  </Typography>
                </Grid>
              </Grid>

              {/* Target Audience */}
              <Grid item md={12} style={{backgroundColor: colors.lightAccent}}>
                <Typography variant="headline">
                  Target Audience:
                </Typography>
              </Grid>
              <Grid container spacing={24} style={{margin:10}}>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Industry Type: {profile.industry_type}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Market Focus: {profile.market_focus}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Audience Type: {profile.audience_type}
                  </Typography>
                </Grid>
              </Grid>


              {/* Project Overview */}
              <Grid item md={12} style={{backgroundColor: colors.lightAccent}}>
                <Typography variant="headline">
                  Project Overview:
                </Typography>
              </Grid>
              <Grid container spacing={24} style={{margin:10}}>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Internal Employee Titles: {profile.internal_employee_titles}
                  </Typography>
                </Grid>
              </Grid>
                  
              {/* Criteria for Selection */}
              <Grid item md={12} style={{backgroundColor: colors.lightAccent}}>
                <Typography variant="headline">
                  Criteria for Selection:
                </Typography>
              </Grid>
              <Grid container spacing={24} style={{margin:10}}>
                <Grid item md={4}>
                  <Typography variant="subheading">
                    Service Provider Partner Location: {profile.agency_partner_location}
                  </Typography>
                </Grid>
              </Grid>


              
            </Grid>
          </div>
        </div>
      );
    }
    else {
      props.history.push('/');
      return (<div/>);
    }
  }
}

ViewClient.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(ViewClient));