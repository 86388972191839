import * as React from 'react';
import { connect } from 'react-redux';
import OpenRFPs from './OpenRFPsComponent';
import { 
  GetClientOpenRFPs, 
  GetClientDashboard, 
  GetAgencyDashboard,
  GetAgencyOpenRFPs 
} from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';
import { 
  setClientOpenRFPs, 
  sortClientOpenRFPS,
  setClientDashboard,
  setAgencyDashboard,
  setAgencyOpenRFPs,
  sortAgencyOpenRFPS 
} from './../reducers/rfpActions';
import OpenRFP from './OpenRFPComponent';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    client_rfps: state.rfpReducer.client_open_rfps,
    agency_rfps: state.rfpReducer.agency_open_rfps,
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPS: (props) => {
      const { location } = props.history;
      var reloadData = (location && location.state && location.state.reloadData === true);
      
      if (props.profile && props.profile.type === "CLIENT") {
        dispatch(loading(true));
        dispatch(GetClientOpenRFPs())
        .then(res => {
          dispatch(setClientOpenRFPs(res.value.data.rfps));
          dispatch(loading(false));
        })
        .catch(err => {
          dispatch(loading(false));
          console.log("GetClientOpenRFPs err", err);
        });
  
        dispatch(GetClientDashboard())
        .then(res => {
          dispatch(setClientDashboard(res.value.data));
        })
        .catch(err => {
          console.log("GetClientDashboard err", err);
        });
      }
      if (props.profile && props.profile.type === "AGENCY") {

        dispatch(GetAgencyDashboard())
        .then(res => {
          dispatch(setAgencyDashboard(res.value.data));
        })
        .catch(err => {
          console.log("GetAgencyDashboard err", err);
        });

        dispatch(loading(true));
        dispatch(GetAgencyOpenRFPs())
        .then(res => {
          dispatch(setAgencyOpenRFPs(res.value.data.rfps));
          dispatch(loading(false));
        })
        .catch(err => {
          dispatch(loading(false));
          console.log("GetAgencyOpenRFPs err", err);
        });
      }
    },
    getViews: (props) => {
      var views = [];
      if (props.profile && props.profile.type === "CLIENT") {
        props.client_rfps.forEach((rfp) => {
          var viewCount = 0;
          if (rfp.rfp_meta) {
            viewCount = rfp.rfp_meta.views;
          }
          views.push(
            <OpenRFP
              {...props}
              rfp_id={rfp.data.id}
              title={rfp.data.title}
              status={rfp.data.status}
              views={viewCount}
              opened_date={rfp.data.opened_date}
              closes_on_date={rfp.data.closes_on_date}
              response_count={rfp.response_count}
              type="CLIENT"
            />
          )
        })
      }
      else if (props.profile && props.profile.type === "AGENCY") {
        props.agency_rfps.forEach((data) => 
        {
          var viewCount = 0;
          if (data.rfp_meta) {
            viewCount = data.rfp_meta.views;
          }
          views.push(
            <OpenRFP
              {...props}
              rfp_id={data.rfp.id}
              title={data.rfp.title}
              views={viewCount}
              opened_date={data.rfp.opened_date}
              closes_on_date={data.rfp.closes_on_date}
              response_count={data.rfp.response_count}
              industry_type={data.industry_type}
              services_needed={data.services_needed}
              budget_range={data.budget_range}
              type="AGENCY"
            />
          )
        })
      }

      return views;
    },
    sortRFP: (props, sort) => {
      if (props.profile && props.profile.type === "CLIENT") {
        dispatch(sortClientOpenRFPS(sort));
      }
      else if (props.profile && props.profile.type === "AGENCY") {
        dispatch(sortAgencyOpenRFPS(sort));
      }
    }
  }
}

const OpenRFPsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenRFPs);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default OpenRFPsContainer;