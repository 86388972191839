import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import numeral from 'numeral';

class OpenRFP extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){

  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;
    var budgetRange = null;
    if (props.budget_range) {
      budgetRange = JSON.parse(props.budget_range);
    }
    return (
      <div style={{margin:50}}>
        <Grid container>
          <Grid item md={2}>
            <Typography variant="body">
              {moment(props.closes_on_date).format('M/D/YYYY')}
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Button
              style={{
                padding: 0, 
                margin: 0, 
                minWidth: 0,
                minHeight: 0,
                fontSize: 20,
                textTransform: 'none'
              }} 
              color="secondary"
              onClick={() => {
                if (props.profile.type === "CLIENT") {
                  props.history.push('/marketer-view-rfp', {
                    mode: 'VIEW',
                    rfp_id: props.rfp_id
                  })
                }
                else if (props.profile.type === "AGENCY") {
                  props.history.push('/edit-agency-response', {rfp_id: props.rfp_id})
                }
              }}>
              {props.title}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}/>
          <Grid item md={4}>
            <Typography variant="body">
              Opened On: {moment(props.opened_date).format('M/D/YYYY')}
            </Typography>
            <Typography variant="body">
              Closes On: {moment(props.closes_on_date).format('M/D/YYYY')}
            </Typography>
            {
              props.profile.type === "AGENCY" ?
              <Typography variant="body">
                Company Industry: {props.industry_type}
              </Typography>
              : null
            }
            {
              props.services_needed && props.services_needed.length > 0 ?
              <Typography variant="body">
                Services Needed: {JSON.parse(props.services_needed).join(', ')}
              </Typography>
              :
              null
            }
          </Grid>
          <Grid item md={4}>
            <Typography variant="body">
              Viewed By: {props.views}
            </Typography>
            {
              budgetRange ? 
              <Typography variant="body">
                Budget Range: High: {numeral(budgetRange["High"]).format('$0,0')}, Low {numeral(budgetRange['Low']).format('$0,0')}
              </Typography>
              :
              null
            }
            {
              props.profile.type === "CLIENT" ? 
              (
                <Button
                  color="secondary"
                  style={{padding: 0, margin: 0}}
                  disabled={!(props.response_count > 0)}
                  onClick={() => {
                    props.history.push('/compare-responses', {
                      rfp_id: props.rfp_id,
                      rfpTitle: props.title
                    });
                    // props.history.push('/view-rfp-responses', {
                    //   rfp_id: props.rfp_id,
                    //   title: props.title,
                    //   views: props.views,
                    //   opened_date: props.closes_on_date,
                    //   closes_on_date: props.closes_on_date,
                    //   response_count: props.response_count,
                    //   status: props.status,
                    // });
                  }}>
                  <Typography variant="body">
                    Responses: {props.response_count}
                  </Typography>
                </Button>
              )
              :
              (<div/>)
            }
          </Grid>
          <Grid item md={1}>
            <Button
              onClick={() => this.props.history.push('/test-pdf', {
                account_type: props.type,
                rfp_details: {
                  rfp_id: props.rfp_id,
                  title: props.title,
                  views: props.views,
                  opened_date: props.closes_on_date,
                  closes_on_date: props.closes_on_date,
                  response_count: props.response_count,
                }
              })}>
              <img 
                src={require('./../assets/print-icon.png')} 
                style={{maxWidth:25}}
              />
            </Button>
          </Grid>
        </Grid>
        <Divider 
          style={{
            marginTop:60, 
            marginBottom:20, 
            marginLeft:100, 
            marginRight:100, 
            backgroundColor: grey[300]
          }}
        />
      </div>
    );
  }
}

OpenRFP.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  opened_date: PropTypes.string.isRequired,
  closes_on_date: PropTypes.string.isRequired,
  response_count: PropTypes.number.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

export default withRoot(withStyles(styles)(OpenRFP));