import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import CssBaseline from '@material-ui/core/CssBaseline';
import { colors } from './design';

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: grey[900]
  //   },
  //   secondary: {
  //     main: blue[500]
  //   },
  // }
  palette: {
    primary: {
      main: colors.darkShades
    },
    secondary: {
      main: colors.darkAccent,
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
