import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

class EditRFP extends React.PureComponent {

  constructor() {
    super();
    console.log('EditRFP constructor')
    this.state = {
      title: '',
      desired_partner_location: null
    };
  }

  componentDidMount(){
    this.props.getTemplates(this.props, this.setState.bind(this));
    sessionStorage.setItem('rfp_client_custom_questions', null);
    sessionStorage.setItem('rfp_client_answers', null);
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.profile && props.profile.type === 'AGENCY') {
      props.history.push('/');
    }

    var historyState = props.history.location.state;
    var disabled = false;
    if (historyState && historyState.mode === 'VIEW') {
      disabled = true;
    }

    return (
      <div style={{marginTop:50}}>
        {
          disabled ? 
          <Typography 
            style={{marginTop:60, marginLeft:40}} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Your Answers
          </Typography>
        :
          <Typography 
            style={{marginTop:60, marginLeft:40}} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Please answer the following questions
          </Typography>
        }
        <div style={{marginLeft:50, marginTop:20}}>
          <TextField
            disabled={disabled}
            value={this.state.title}
            label="* RFP Title"
            onChange={(event) => {
              this.setState({title: event.target.value});
            }}
            style={{width:500}}
          />
        </div>
        {this.props.getTempRFPQuestions(props, this.setState.bind(this), 'ANSWER', disabled, state)}
        {/* {
          disabled ?
          <Typography 
            style={{margin:40}} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Agency Questions
          </Typography>
          :
          <Typography 
            style={{margin:40}} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Editable Agency Questions
          </Typography>
        }
        {this.props.getTempRFPQuestions(props, this.setState.bind(this), 'EDIT', disabled)} */}
        {
          historyState && historyState.mode === 'VIEW' ?
          (
            <div/>
          )
          :
          (
            <div>
              <Typography 
                style={{margin:40}} 
                className={classes.typography} variant="display1" align='left' gutterBottom={false}>
                Create RFP
              </Typography>
              <Button
                color="primary"
                variant="contained"
                style={{marginLeft:40, width:200}} 
                onClick={() => {
                  const history = props.history.location.state;
                  if (history && history.rfp_id) {
                    props.updateRFP(props, this.state, false, history.rfp_id)
                  }
                  else {
                    props.saveRFP(props, this.state, false)
                  }
                }}>
                Save as Draft
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{marginLeft:40, width:200}} 
                onClick={() => {
                  const history = props.history.location.state;
                  if (history && history.rfp_id) {
                    props.updateRFP(props, this.state, true, history.rfp_id)
                  }
                  else {
                    props.saveRFP(props, this.state, true)
                  }
                }}>
                Publish
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}

EditRFP.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

export default withRoot(withStyles(styles)(EditRFP));