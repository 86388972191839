import * as React from 'react';
import { connect } from 'react-redux';
import ViewResponses from './ViewResponsesComponent';
import { 
  GetRFPResponses,
  AwardRFP,
  SetResponseFavoriteStatus,
  DeclineResponse,
  GetRFPClientAnswer
} from './../util/requests';
import { 
  setRFPResponses,
  setLocalResponseFavorite
} from './../reducers/rfpActions';
import {
  loading, 
  simpleAlert, 
  setClientHeader
} from './../reducers/displayActions';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FaHeart } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfpResponses: state.rfpReducer.rfp_responses
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    awardRFP: (props, state) => {
      const history = props.history.location.state;
      if (state.rfp && history && history.rfp_id) {
        dispatch(loading(true))
        dispatch(AwardRFP({
          rfp_response_id: state.rfp.rfp_response.id,
          rfp_id: history.rfp_id
        }))
        .then(res => {
          dispatch(simpleAlert({
            title:'Success',
            body: "The RFP has been awarded"
          }));
          dispatch(loading(false));
          props.history.push('/', {reloadData: true});
          dispatch(setClientHeader('OPEN'));
        })
        .catch(err => {
          dispatch(loading(false));
          if (
            err.response && err.response.data && 
            err.response.data.results && err.response.data.results.display_message
          ) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.results.display_message
            }));
          }
          else if (err.response && err.response.data && err.response.data.display_message) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error awarding this RFP'
            }));
          }
          console.log('AwardRFP err', err);
        });
      }
    },
    getRFP: (rfpId) => {

    },
    getRFPResponses: (rfpId, property, setState) => {
      dispatch(loading(true));
      dispatch(GetRFPResponses({rfp_id: rfpId}))
      .then(res => {
        dispatch(setRFPResponses(res.value.data));
        if (res.value.data.length > 0) {
          const rfp = res.value.data[0];
          var compareFirst = false;
          var compareResponses = sessionStorage.getItem('compare_responses');
          compareResponses = JSON.parse(compareResponses);
          if (compareResponses && compareResponses[rfp.rfp_response.id]) {
            compareFirst = true;
          }
          setState({
            rfp: rfp,
            compare: compareFirst,
            favorite: rfp.rfp_response.client_favorite === true
          });
        }
        return dispatch(GetRFPClientAnswer({
          rfp_id: rfpId,
          property: property
        }));
      })
      .then(res => {
        dispatch(loading(false));
        setState({
          service_needed: res.value.data.answer
        });
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('GetRFPResponses err', err);
      });
    },
    getResponseViews: (props, state, setState) => {
      var views = [];
      var compareResponses = sessionStorage.getItem('compare_responses');
      compareResponses = JSON.parse(compareResponses);
      props.rfpResponses.forEach(rfpResponse => {
        var icons = [];
        if (compareResponses && compareResponses[rfpResponse.rfp_response.id]) {
          icons.push(
            <Tooltip title="Compare">
              <img 
                src={require('./../assets/compare-icon.png')} 
                style={{width:20, height: 20, marginRight: 5}}
              />
            </Tooltip>
          );
        }
        if (rfpResponse.agency_profile.vetted === true) {
          icons.push(
            <Tooltip title="Vetted Service Provider">
              <img 
                src={require('./../assets/vetted-icon.png')} 
                style={{width:20, height: 20, marginRight: 5}}
              />
            </Tooltip>
          );
        }
        if (rfpResponse.rfp_response.client_favorite === true) {
          icons.push(
            <Tooltip title="Favorited Service Provider">
              <FaHeart size={20} color="red"/>
            </Tooltip>
          );
        }

        if (state.rfp && state.rfp.rfp_response.id === rfpResponse.rfp_response.id) {
          views.push(
            <div style={{display:"flex"}}>
              <Button 
                color='secondary'
                style={{
                  fontSize: 15
                }}>
                {moment(rfpResponse.rfp_response.created_at).format('M/D/YYYY')}: {rfpResponse.rfp_response.id.substring(0, 3)}
              </Button>
              <div style={{display:"flex", margin:"10 10 0 0"}}>
                {icons}
              </div>
            </div>
          );
        }
        else {
          views.push(
            <div style={{display:"flex"}}>
              <Button 
                style={{
                  fontSize: 15,
                  color:'gray'
                }}
                onClick={() => {
                  var compare = false;
                  if (compareResponses && compareResponses[rfpResponse.rfp_response.id]) {
                    compare = true;
                  }
                  setState({
                    rfp: rfpResponse,
                    compare: compare,
                    favorite: rfpResponse.rfp_response.client_favorite === true
                  });
                }}>
                {moment(rfpResponse.rfp_response.created_at).format('M/D/YYYY')}: {rfpResponse.rfp_response.id.substring(0, 3)}
              </Button>
              <div style={{display:"flex", margin:"10 10 0 0"}}>
                {icons}
              </div>
            </div>
          );
        }
      });
      return views;
    },
    setFavoriteStatus: (responseId, favorite, setState) => {
      dispatch(loading(true));
      dispatch(SetResponseFavoriteStatus({rfp_response_id: responseId, favorite: favorite}))
      .then(res => {
        dispatch(loading(false));
        setState({favorite: favorite});
        dispatch(setLocalResponseFavorite({
          responseId: responseId,
          favorite: favorite
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('SetFavoriteStatus err', err);
      });
    },
    declineResponse: (props, responseId) => {
      dispatch(loading(true));
      dispatch(DeclineResponse({rfp_response_id: responseId}))
      .then(res => {
        dispatch(loading(false));
        props.history.push('/open-rfps', {reloadData: true});
        dispatch(setClientHeader('OPEN'))
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('DeclineResponse err', err);
      });
    }
  }
}

const ViewResponsesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewResponses);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ViewResponsesContainer;