var init = {
  agency_profiles: null
};

const reducer = (state = init, action) => {

  switch (action.type) {
    case 'ADMIN_SET_AGENCY_PROFILES': {
      return Object.assign({}, state, {
        agency_profiles: action.payload
      });
      break;
    }
    case 'ADMIN_SET_LOCAL_AGENCY_VETTED_STATUS': {
      var copy = Object.assign({}, state);
      if (copy.agency_profiles){
        for (let i = 0; i < copy.agency_profiles.length; i++) {
          const profile = copy.agency_profiles[i];
          if (profile.id === action.payload.agency_profile_id) {
            copy.agency_profiles[i].vetted = action.payload.vetted;
            break;
          }
        }
      }
      return copy;
    }
    case 'ADMIN_SET_LOCAL_AGENCY_ACTIVE_STATUS': {
      var copy = Object.assign({}, state);
      if (copy.agency_profiles){
        for (let i = 0; i < copy.agency_profiles.length; i++) {
          const profile = copy.agency_profiles[i];
          if (profile.id === action.payload.agency_profile_id) {
            copy.agency_profiles[i].is_active = action.payload.is_active;
            break;
          }
        }
      }
      return copy;
    }
    default: {
      return Object.assign({}, state);
    }
  }
}

export default reducer;
