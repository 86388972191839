import * as React from 'react';
import { connect } from 'react-redux';
import ClientHeader from './ClientHeaderComponent';
import { setClientHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    dashboard: state.rfpReducer.client_dashboard,
    clientHeader: state.displayReducer.client_header_tab
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setClientHeaderTab: (tab) => {
      dispatch(setClientHeader(tab));
    }
  }
}

const ClientHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientHeader);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ClientHeaderContainer;