import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class AgencySentResponses extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){
    this.props.getRFPS(this.props, this.state);
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.rfps) {
      if (props.rfps.length > 0) {
        return (
          <div>
            <Typography variant="headline" style={{margin:30}}>
              Your Sent RFP Responses:
            </Typography>
            {props.getViews(props)}
          </div>
        );
      }
      else {
        return (
          <div>

          </div>
        );
      }
    }
    else {
      return (<div/>);
    }
  }
}

AgencySentResponses.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(AgencySentResponses));