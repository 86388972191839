import React from 'react';
import { connect } from 'react-redux';
import NavBar from './NavBarComponent';
import {
  loading
} from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: (props) => {
      localStorage.clear();
      window.location.reload();
    }
  }
}

const NavBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {pure:true}
)(NavBar);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default NavBarContainer;
