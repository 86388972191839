import * as React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { LoadEnvironment } from './../util/persist';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withRoot from './../withRoot';
import { Typography } from '@material-ui/core';
import TermsAndConditions from './TermsAndConditionsComponent';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem'
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minWidth:500
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width:300
  }
});

class Login extends React.Component { 

  constructor() {
    super();
    this.state = {
      redirectToReferrer: false,
      mode: "SIGN_IN",
      account_type: null,
      email: '',
      password: '',
      confirmed_password: '',
      openTsCs: false
    };
  }

  componentDidMount() {
    if (this.props.isAuthenticated === true) {
      this.props.history.push('/');
    }
  }

  render() {

    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;
    const { classes } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div 
        className={classes.root}
        style={{
          display:'flex',
          justifyContent:'center'
        }}>
          <TermsAndConditions 
            open={this.state.openTsCs}
            termsAccepted={(accepted) => {
              this.setState({openTsCs: false})
              if (accepted === true) {
                this.props.signUp(this.props, this.state);
              }
            }}
          />
          <Paper className={classes.paper}>
            <img src={require('./../assets/pitchless-logo-white.png')} width="200" height="75" style={{margin:20}}/>
            {/* <h2>Welcome to Agency Saver</h2> */}
            {
              this.state.mode === "SIGN_IN" ?
              (
                <div>
                  <div>
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={(val) => this.setState({email: val.target.value})}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      className={classes.textField}
                      value={this.state.password}
                      onChange={(val) => this.setState({password: val.target.value})}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <Button
                      color="primary"
                      style={{marginTop:40}}
                      variant="contained"
                      onClick={() => this.props.signIn(
                        this.props,
                        this.state.email,
                        this.state.password
                      )}>
                      Sign In
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      style={{margin:30}}
                      onClick={() => this.setState({
                        mode:"SIGN_UP",
                        email: '',
                        password: '',
                        confirmed_password: ''
                      })}>
                      Create New Account
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="secondary"
                      onClick={() => this.setState({
                        mode:"FORGOT_PASSWORD",
                        password: '',
                        confirmed_password: ''
                      })}>
                      Forgot Password
                    </Button>
                  </div>
                </div>
              )
              :
              (
                <div/>
              )
            }
            {
              this.state.mode === 'SIGN_UP' ? 
              (
                <div>
                  <div>
                    <TextField
                      label="Email"
                      type="email"
                      onChange={(val) => this.setState({email: val.target.value})}
                      className={classes.textField}
                      value={this.state.email}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <TextField
                      label="Password"
                      type="password"
                      className={classes.textField}
                      value={this.state.password}
                      onChange={(val) => this.setState({password: val.target.value})}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <TextField
                      label="Confirm Password"
                      type="password"
                      className={classes.textField}
                      value={this.state.confirmed_password}
                      onChange={(val) => this.setState({confirmed_password: val.target.value})}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <h3>Select Account Type</h3>
                  </div>
                  <div>
                    <Button
                      style={{marginRight:20}}
                      onClick={() => this.setState({account_type: 'CLIENT'})}
                      variant={this.state.account_type === null ? "outlined" : this.state.account_type === 'CLIENT' ? "contained" : "outlined"}>
                      Marketer
                    </Button>
                    <Button
                      style={{marginTop:0}}
                      onClick={() => this.setState({account_type: 'AGENCY'})}
                      variant={this.state.account_type === null ? "outlined" : this.state.account_type === 'AGENCY' ? "contained" : "outlined"}>
                      Service Provider
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      style={{marginTop:40}}
                      variant="contained"
                      onClick={() => {
                        this.setState({openTsCs: true});
                      }}>
                      Register
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      style={{margin:30}}
                      onClick={() => this.setState({
                        mode:"SIGN_IN",
                        password: '',
                        confirmed_password: ''
                      })}>
                      Have an Existing Account?
                    </Button>
                  </div>
                </div>
              )
              :
              (
                <div/>
              )
            }
            {
              this.state.mode === "FORGOT_PASSWORD" ?
              (
                <div>
                  <div>
                    <Typography variant="title" style={{marginTop:40}}>
                      Please enter your email
                    </Typography>
                  </div>
                  <div>
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={(val) => this.setState({email: val.target.value})}
                      margin="normal"
                    />
                  </div>
                  <div>
                    <Button
                      color="primary"
                      style={{margin:30}}
                      onClick={() => this.props.forgotPassword(this.state.email, this.setState.bind(this))}>
                      Send Email
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="secondary"
                      onClick={() => this.setState({
                        mode:"SIGN_IN",
                        password: '',
                        confirmed_password: ''
                      })}>
                      Have an Existing Account?
                    </Button>
                  </div>
                </div>
              )
              :
              (
                <div/>
              )
            }
          </Paper>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Login));
