export const SetEnvironment = (environment) => {
  switch (environment) {
    case 'DEVELOP': {
      localStorage.setItem('environment', JSON.stringify(
        Object.assign({}, LoadEnvironment(), {
          environment: environment,
          bs_api_url: 'http://localhost:3001'
        })
      ));
      break;
    }
    case 'STAGING': {
      localStorage.setItem('environment', JSON.stringify(
        Object.assign({}, LoadEnvironment(), {
          environment: environment,
          bs_api_url: 'https://api.pitchless.app'
        })
      ));
      break;
    }
    case 'PRODUCTION': {
      localStorage.setItem('environment', JSON.stringify(
        Object.assign({}, LoadEnvironment(), {
          environment: environment,
          bs_api_url: 'https://api.pitchless.app'
        })
      ));
      break;
    }
    default: {

    }
  }

  return;
}

export const LoadEnvironment = () => {
  return loadObject('environment');
}

export const UpdateEvironment = (object) => {
  var currentEnvironment = LoadEnvironment();
  const updatedEnvironment = Object.assign({}, currentEnvironment, object);
  localStorage.setItem('environment', JSON.stringify(updatedEnvironment));
}

const loadObject = (key) => {
  var result = localStorage.getItem(key);
  if(result){
    return JSON.parse(result);
  }
  else {
    return null;
  }
}
