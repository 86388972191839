import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MdCancel, MdCheckCircle, MdClose } from 'react-icons/md';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import AgencyViewRFP from './AgencyViewRFPComponent';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

class CompareResponses extends React.Component {

  constructor() {
    super();
    this.state = {
      viewResponseIsOpen: false,
      rfp: {},
      rfpDetails: {},
      sortByVetted: 'asc',
      sortAllServicesProvided: 'asc',
      sortFavorite: 'asc',
      declineResponseOpen: false,
      declineResponseId: null,
      service_needed: [],
      favorites: {}
    };
  }

  componentDidMount(){

    const history = this.props.history.location.state;
    if (history && history.rfp_id){
      this.props.getRFPResponses(history.rfp_id, 'service_needed', this.setState.bind(this), this.props);
    }
    else {
      this.props.history.push('/');
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes, rfpResponses } = this.props;

    const historyState = this.props.history.location.state;

    var headerCells = [
      (
        <TableCell style={{padding: 0}}>
          <Button
            color="primary"
            style={{
              margin: "0 0 0 10",
              padding: "5",
              minHeight: 0,
              minWidth: 0,
              fontSize: 10,
              textAlign: 'left'
            }}onClick={() => {
              props.sortMostRecent(props);
            }}>
            Sort by Most Recent
          </Button>
          <Button
            color="primary"
            style={{
              margin: "0 0 0 10",
              padding: "5",
              minHeight: 0,
              minWidth: 0,
              fontSize: 10,
              textAlign: 'left'
            }}
            onClick={() => {
              props.sortByMatches(props);
            }}>
            Sort by Best Match to Worst Match
          </Button>
        </TableCell>
      ),
      (
        <TableCell>
          Decline Response
        </TableCell>
      ),
      (
        <TableCell>
          <div style={{display: 'flex'}}>
            Favorite Response
            <Button className={classes.sortButton}
              onClick={() => {
                if (state.sortFavorite === 'asc') {
                  this.setState({sortFavorite: 'desc'});
                  props.sortByFavorite(props, 'desc');
                }
                else {
                  this.setState({sortFavorite: 'asc'});
                  props.sortByFavorite(props, 'asc');
                }
              }}>
              {
                state.sortFavorite === 'asc' ? 
                (
                  <MdKeyboardArrowDown size={30}/>
                )
                :
                (
                  <MdKeyboardArrowUp size={30}/>
                )
              }
            </Button>
          </div>
        </TableCell>
      ),
      (
        <TableCell>
          <div style={{display: 'flex'}}>
            Service Provider Vetted
            <Button className={classes.sortButton}
              onClick={() => {
                if (state.sortByVetted === 'asc') {
                  this.setState({sortByVetted: 'desc'});
                  props.sortVettedStatus(props, 'desc');
                }
                else {
                  this.setState({sortByVetted: 'asc'});
                  props.sortVettedStatus(props, 'asc');
                }
              }}>
              {
                state.sortByVetted === 'asc' ? 
                (
                  <MdKeyboardArrowDown size={30}/>
                )
                :
                (
                  <MdKeyboardArrowUp size={30}/>
                )
              }
            </Button>
          </div>
        </TableCell>
      ),
      (
        <TableCell>
          <div style={{display: 'flex'}}>
            Provide All services listed in RFP
            <Button className={classes.sortButton}
              onClick={() => {
                if (state.sortAllServicesProvided === 'asc') {
                  this.setState({sortAllServicesProvided: 'desc'});
                  props.sortByAllServicesProvided(props, 'desc');
                }
                else {
                  this.setState({sortAllServicesProvided: 'asc'});
                  props.sortByAllServicesProvided(props, 'asc');
                }
              }}>
              {
                state.sortAllServicesProvided === 'asc' ? 
                (
                  <MdKeyboardArrowDown size={30}/>
                )
                :
                (
                  <MdKeyboardArrowUp size={30}/>
                )
              }
            </Button>
          </div>
        </TableCell>
      )
    ];
    var tableRows = [];
    var displayTemplateQuestion = [];

    if (rfpResponses.length > 0) {
      rfpResponses[0].question_response.forEach((questionRes) => {
        if (questionRes.template_question.response_type === "BOOLEAN") {
          displayTemplateQuestion.push(questionRes.template_question);
          const description = questionRes.custom_description ? questionRes.custom_description : questionRes.template_question.description;
          headerCells.push(
            <TableCell>
              <div style={{display: 'flex'}}>
                {description}
                <Button className={classes.sortButton}
                  onClick={() => {
                    if (state[questionRes.template_question.id] === 'asc') {
                      this.setState({[questionRes.template_question.id]: 'desc'});
                      props.setSort(props, questionRes.template_question.id, 'desc');
                    }
                    else {
                      this.setState({[questionRes.template_question.id]: 'asc'});
                      props.setSort(props, questionRes.template_question.id, 'asc');
                    }
                  }}>
                  {
                    state[questionRes.template_question.id] === 'asc' ? 
                    (
                      <MdKeyboardArrowDown size={30}/>
                    )
                    :
                    (
                      <MdKeyboardArrowUp size={30}/>
                    )
                  }
                </Button>
              </div>
            </TableCell>
          );
        }
      });
      
      rfpResponses.forEach((rfpResponse, index) => {
        var agencyResponses = {};
        rfpResponse.question_response.forEach(questionRes => {
          agencyResponses[questionRes.rfp_question_response.template_question_id] = questionRes.rfp_question_response;
        });
        tableRows.push(
          <TableRow>
            <TableCell>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    viewResponseIsOpen: true,
                    rfp: rfpResponse
                  })
                }}>
                Read Response: {rfpResponse.rfp_response.id.substring(0, 4)}
              </Button>
            </TableCell>
            <TableCell>
              <Button
                style={{
                  padding: "2 0 2 10",
                  margin: 0,
                  minHeight: 0,
                  color:'red'
                }}
                onClick={() => {
                  this.setState({
                    declineResponseOpen: true,
                    declineResponseId: rfpResponse.rfp_response.id
                  });
                }}>
                Decline
              </Button>
            </TableCell>
            <TableCell>
              <Typography style={{margin:5}} variant="subheadline">
                <Checkbox
                  style={{padding: "0 5 0 0"}}
                  checked={rfpResponse.rfp_response.client_favorite === true}
                  onChange={(event) => {
                    props.setFavoriteStatus(
                      rfpResponse.rfp_response.id, 
                      event.target.checked,
                      this.setState.bind(this),
                      state
                    );
                  }}
                  color="primary"
                />
              </Typography>
            </TableCell>
            <TableCell>
              {
                rfpResponse.agency_profile.vetted === true ? 
                <MdCheckCircle color="green" size={25}/> : <MdCancel color="red" size={25}/>
              }
            </TableCell>
            <TableCell>
              {
                rfpResponse.all_services_provided === true ? 
                <MdCheckCircle color="green" size={25}/> : <MdCancel color="red" size={25}/>
              }
            </TableCell>
              {
                displayTemplateQuestion.map(templateQuestion => {
                  const answer = agencyResponses[templateQuestion.id].answer;
                  return (
                    <TableCell>
                      {answer === true ? <MdCheckCircle color="green" size={25}/> : <MdCancel color="red" size={25}/>}
                    </TableCell>
                  );
                })
              }
          </TableRow>
        );
      });
    }

    return (
      <div style={{margin:20}}>
        <Dialog
          open={state.declineResponseOpen}
          onClose={() => null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Would you like to decline this response?</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
             <Button
              onClick={() => {
                this.setState({declineResponseOpen: false});
                if (state.declineResponseId) {
                  props.declineResponse(props, state.declineResponseId);
                }
              }}
              color="primary">
              Yes
            </Button>
            <Button
              onClick={() => this.setState({declineResponseOpen: false})}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={this.state.viewResponseIsOpen}
          onRequestClose={() => this.setState({viewResponseIsOpen: false})}
          style={{ height:"90%", overflow: 'scroll'}}>
          <Grid container spacing={24} style={{paddingTop:100}}>
            <Grid item md={1}/>
            <Grid item xs={10}>
              <Paper className={classes.paper}>
                <div style={{textAlign:'right'}}>
                  <Button
                    onClick={() => this.setState({viewResponseIsOpen: false})}>
                    <MdClose color="gray" size={30} />
                  </Button>
                </div>
                <div style={{textAlign:'left'}}>
                  <AgencyViewRFP 
                    rfp_response={this.state.rfp} 
                    rfp_id={historyState.rfp_id}
                    {...this.props}
                    awardRFP={(rfpId, rfpResponseId) => {
                      console.log('rfpId', rfpId);
                      console.log('rfpResponseId', rfpResponseId);
                      this.props.awardRFP(props, rfpId, rfpResponseId);
                      this.setState({viewResponseIsOpen: false});
                    }}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item md={1}/>
          </Grid>
        </Modal>
        <Typography 
          variant="display1" 
          style={{
            marginBottom:10,
          }}>
          Compare Service Provider Responses for {historyState.rfpTitle}
        </Typography>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
                <TableRow>
                  {headerCells}
                </TableRow>
                {tableRows}
            </TableHead>
          </Table>
        </Paper>
      </div>
    );
  }
}

CompareResponses.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    width: 2000
  },
  sortButton: {
    margin: "-5 0 0 10",
    padding: 0,
    minHeight: 0,
    minWidth: 0
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
});

export default withRoot(withStyles(styles)(CompareResponses));