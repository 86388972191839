import axios from 'axios';
import { LoadEnvironment, UpdateEvironment } from './persist';
import queryString from 'query-string';


export const GetAgencyRFPResponse = (query) => {
  return {
    type: 'BS_GET_AGENCY_RFP_RESPONSE',
    payload: BackstrapAuthRequest('GET', '/custom/agencyrfp/agency_response_for_rfp/1.0.0', query)
  }
}

export const GetRFPClientAnswer = (query) => {
  return {
    type: 'BS_GET_RFP_CLIENT_ANSWER',
    payload: BackstrapAuthRequest('GET', '/custom/clientrfp/rfp_response_answer/1.0.0', query)
  }
}

export const GetAwardedResponderName = (query) => {
  return {
    type: 'BS_GET_AWARDDED_RESPONDER_NAME',
    payload: BackstrapAuthRequest('GET', '/custom/rfp/awarded_responder_name/1.0.0', query)
  }
}

export const DeclineResponse  = (body) => {
  return {
    type: 'BS_DECLINE_RESPONSE',
    payload: BackstrapAuthRequest('POST', '/custom/clientrfp/decline_response/1.0.0', body)
  }
}

export const SetResponseFavoriteStatus  = (body) => {
  return {
    type: 'BS_SET_RESPONSE_FAVORITE_STATUS',
    payload: BackstrapAuthRequest('POST', '/custom/clientrfp/favorite_response/1.0.0', body)
  }
}

export const SetAgencyActiveStatus  = (body) => {
  return {
    type: 'BS_SET_AGENCY_ACTIVE_STATUS',
    payload: BackstrapAuthRequest('POST', '/custom/admin/agency_profile_active_status/1.0.0', body)
  }
}

export const SetAgencyVettedStatus  = (body) => {
  return {
    type: 'BS_SET_AGENCY_VETTED_STATUS',
    payload: BackstrapAuthRequest('POST', '/custom/admin/vetted_status/1.0.0', body)
  }
}

export const GetAgencyProfiles = (query) => {
  return {
    type: 'BS_GET_AGENCY_PROFILES',
    payload: BackstrapAuthRequest('GET', '/custom/admin/all_agency_profiles/1.0.0', query)
  }
}

export const DeletePaymentSource  = (body) => {
  return {
    type: 'BS_DELETE_PAYMENT_SOURCE',
    payload: BackstrapAuthRequest('POST', '/custom/stripe/delete_payment_source/1.0.0', body)
  }
}

export const SetPaymentSource  = (body) => {
  return {
    type: 'BS_POST_PAYMENT_SOURCE',
    payload: BackstrapAuthRequest('POST', '/custom/stripe/payment_source/1.0.0', body)
  }
}

export const AwardRFP = (body) => {
  return {
    type: 'BS_AWARD_RFP',
    payload: BackstrapAuthRequest('POST', '/custom/clientrfp/award_rfp/1.0.0', body)
  }
}

export const GetRFPResponses = (query) => {
  return {
    type: 'BS_GET_RFP_RESPONSES',
    payload: BackstrapAuthRequest('GET', '/custom/clientrfp/rfp_responses/1.0.0', query)
  }
}

export const UpdateRFP = (body) => {
  return {
    type: 'BS_UPDATE_RFP',
    payload: BackstrapAuthRequest('PATCH', '/custom/clientrfp/rfp/1.0.0', body)
  }
}

export const GetClientRFP = (query) => {
  return {
    type: 'BS_GET_CLIENT_RFP',
    payload: BackstrapAuthRequest('GET', '/custom/rfp/client_rfp/1.0.0', query)
  }
}

export const GetRFPAgencyQuestions = (query) => {
  return {
    type: 'BS_GET_RFP_AGENCY_QUESTIONS',
    payload: BackstrapAuthRequest('GET', '/custom/rfp/get_rfp_agency_questions/1.0.0', query)
  }
}

export const UpdateRFPQuestionResponses = (body) => {
  return {
    type: 'BS_GET_UPDATE_RFP_RESPONSES',
    payload: BackstrapAuthRequest('patch', '/custom/agencyrfp/update_draft_rfp_responses/1.0.0', body)
  }
}

export const GetRFPResponse = (query) => {
  return {
    type: 'BS_GET_RFP_RESPONSE',
    payload: BackstrapAuthRequest('get', '/custom/rfp/rfp_response/1.0.0', query)
  }
}

export const GetAgencyClosedRFPs = () => {
  return {
    type: 'BS_GET_AGENCY_CLOSED_RESPONSES',
    payload: BackstrapAuthRequest('get', '/custom/agencyrfp/agency_closed_rfps/1.0.0')
  }
}

export const GetAgencySentResponses = () => {
  return {
    type: 'BS_GET_AGENCY_SENT_RESPONSES',
    payload: BackstrapAuthRequest('get', '/custom/agencyrfp/agency_sent_rfp_responses/1.0.0')
  }
}

export const GetAgencyDraftResponses = () => {
  return {
    type: 'BS_GET_AGENCY_DRAFT_RESPONSES',
    payload: BackstrapAuthRequest('get', '/custom/agencyrfp/agency_draft_rfp_responses/1.0.0')
  }
}

export const PublishRFPResponse = (body) => {
  return {
    type: 'BS_PUBLISH_RFP_RESPONSE',
    payload: BackstrapAuthRequest('post', '/custom/agencyrfp/publish_draft_rfp_response/1.0.0', body)
  }
}

export const GetRFP = (rfpId) => {
  return {
    type: 'BS_GET_RFP',
    payload: BackstrapAuthRequest('get', '/custom/rfp/rfp/1.0.0', {rfp_id: rfpId})
  }
}

export const CreateResponse = (body) => {
  return {
    type: 'BS_CREATE_RESPONSE',
    payload: BackstrapAuthRequest('post', '/custom/agencyrfp/save_draft_rfp_response/1.0.0', body)
  }
}

export const GetAgencyDashboard = () => {
  return {
    type: 'BS_GET_AGENCY_DASHBOARD',
    payload: BackstrapAuthRequest('get', '/custom/agencyrfp/agency_dashboard/1.0.0')
  }
}

export const GetClientClosedRFPs = () => {
  return {
    type: 'BS_GET_CLIENT_CLOSED_RFPS',
    payload: BackstrapAuthRequest('get', '/custom/clientrfp/client_closed_rfps/1.0.0')
  }
}

export const GetClientDraftRFPs = () => {
  return {
    type: 'BS_GET_CLIENT_DRAFT_RFPS',
    payload: BackstrapAuthRequest('get', '/custom/clientrfp/client_draft_rfps/1.0.0')
  }
}

export const GetAgencyOpenRFPs = () => {
  return {
    type: 'BS_GET_AGENCY_OPEN_RFPS',
    payload: BackstrapAuthRequest('get', '/custom/agencyrfp/agency_open_rfps/1.0.0')
  }
}

export const GetClientOpenRFPs = () => {
  return {
    type: 'BS_GET_CLIENT_OPEN_RFPS',
    payload: BackstrapAuthRequest('get', '/custom/clientrfp/client_open_rfps/1.0.0')
  }
}

export const GetClientDashboard = () => {
  return {
    type: 'BS_GET_CLIENT_DASHBOARD',
    payload: BackstrapAuthRequest('get', '/custom/clientrfp/client_dashboard/1.0.0')
  }
}

export const PublishRFP = (body) => {
  return {
    type: 'BS_PUBLISH_RFP',
    payload: BackstrapAuthRequest('post', '/custom/clientrfp/publish_draft_rfp/1.0.0', body)
  }
}

export const GetTemplate = (title, role) => {
  return {
    type: 'BS_GET_TEMPLATE',
    payload: BackstrapAuthRequest('get', '/common/models/template/1.0.0', {
      title: title,
      responder_role: role
    })
  }
}

export const CreateRFP = (body) => {
  return {
    type: 'BS_CREATE_RFP',
    payload: BackstrapAuthRequest('post', '/custom/clientrfp/save_draft_rfp/1.0.0', body)
  }
}

export const SignUp = (body) => {
  return {
    type: 'BS_SIGN_UP',
    payload: Request('post', '/custom/accounts/sign_up/1.0.0', body)
  }
}

export const SignIn = (body) => {
  return {
    type: 'BS_SIGN_IN',
    payload: Request('post', '/common/accounts/signIn/1.0.0', body)
  }
}

export const ForgotPassword = (body) => {
  return {
    type: 'BS_FORGOT_PASSWORD',
    payload: Request('post', '/common/accounts/forgotPassword/1.0.0', body)
  }
}

export const ResetPassword = (body) => {
  return {
    type: 'BS_RESET_PASSWORD',
    payload: Request('post', '/common/accounts/resetPassword/1.0.0', body)
  }
}

export const GetUserProfile = () => {
  return {
    type: 'BS_GET_USER_PROFILE',
    payload: BackstrapAuthRequest('get', '/custom/profile/profile/1.0.0', )
  }
}

export const CreateClientProfile = (body) => {
  return {
    type: 'BS_CREATE_CLIENT_PROFILE',
    payload: BackstrapAuthRequest('post', '/custom/profile/client_profile/1.0.0', body)
  }
}

export const UpdateClientProfile = (body) => {
  return {
    type: 'BS_UPDATE_CLIENT_PROFILE',
    payload: BackstrapAuthRequest('patch', '/custom/profile/client_profile/1.0.0', body)
  }
}

export const CreateAgencyProfile = (body) => {
  return {
    type: 'BS_CREATE_AGENCY_PROFILE',
    payload: BackstrapAuthRequest('post', '/custom/profile/agency_profile/1.0.0', body)
  }
}

export const UpdateAgencyProfile = (body) => {
  return {
    type: 'BS_UPDATE_AGENCY_PROFILE',
    payload: BackstrapAuthRequest('patch', '/custom/profile/agency_profile/1.0.0', body)
  }
}

export const SaveProfileImage = (body) => {
  return {
    type: 'BS_SAVE_PROFILE_IMAGE',
    payload: BackstrapAuthRequest('post', '/custom/profile/profile_image/1.0.0', body)
  }
}

const RefreshBackStrapToken = () => {

  return new Promise((resolve, reject) => {

    const environment = LoadEnvironment();

    if (environment.bs_login_type === 'BACKSTRAP') {

      reject("Failed to refresh token");

      // Request('post', '/nic/accounts/sign_in/1.0.0', {
      //   email: environment.bs_email,
      //   password: environment.bs_password
      // })
      // .then((res) => {
      //   UpdateEvironment({
      //     contact_id: res.data.csp__hcpg_id__c,
      //     bs_api_token: res.data.bs_api_token,
      //     roles: res.data.roles
      //   });
      //   return;
      // })
      // .then(() => {
      //   resolve();
      // })
      // .catch((err) => {
      //   reject("Failed to refresh token");
      // });
    }
  });
}

const buildBackStrapEndpoint = (path) => {
  return LoadEnvironment().bs_api_url+path;
}

const Request = (verb, path, body) => {

  return new Promise((resolve, reject) => {

    switch (verb.toLowerCase()) {

      case 'post': {
        axios.post(buildBackStrapEndpoint(path), body)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
        break;
      }
      default: {
        reject('VERB NOT FOUND');
      }
    }
  })
}

const BackstrapAuthRequest = (verb, path, body) => {

  return new Promise((resolve, reject) => {

    var token = LoadEnvironment().bs_api_token;

    if (token === null) {
      token = "";
    }

    switch (verb.toLowerCase()) {

      case 'post': {

        axios.post(buildBackStrapEndpoint(path), body, {
          headers: {
            bs_api_token: token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            RefreshBackStrapToken()
            .then(() => {
              resolve(BackstrapAuthRequest(verb, path, body))
            })
            .catch((err) => {
              localStorage.clear();
              window.location.reload();
              // reject(err);
            })
          }
          else {
            reject(e);
          }
        });

        break;
      }
      case 'patch': {

        axios.patch(buildBackStrapEndpoint(path), body, {
          headers: {
            bs_api_token: token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            RefreshBackStrapToken()
            .then(() => {
              resolve(BackstrapAuthRequest(verb, path, body))
            })
            .catch((err) => {
              localStorage.clear();
              window.location.reload();
              // reject(err);
            })
          }
          else {
            reject(e);
          }
        });

        break;
      }
      case 'put': {

        axios.put(buildBackStrapEndpoint(path), body, {
          headers: {
            bs_api_token: token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            RefreshBackStrapToken()
            .then(() => {
              resolve(BackstrapAuthRequest(verb, path, body))
            })
            .catch((err) => {
              localStorage.clear();
              window.location.reload();
              // reject(err);
            })
          }
          else {
            reject(e);
          }
        });

        break;
      }
      case 'get': {
        var query = '';
        if (body) {
          var params = queryString.stringify(body);
          if (params) {
            query = '?' + params;
          }
        }
        axios.get(buildBackStrapEndpoint(path+query), { headers: { bs_api_token: token } })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            RefreshBackStrapToken()
            .then(() => {
              resolve(BackstrapAuthRequest(verb, path, body))
            })
            .catch((err) => {
              localStorage.clear();
              window.location.reload();
            })
          }
          else {
            reject(e);
          }
        });

        break;
      }
      case 'delete': {

        axios.delete(buildBackStrapEndpoint(path), { headers: { bs_api_token: token } })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            RefreshBackStrapToken()
            .then(() => {
              resolve(BackstrapAuthRequest(verb, path, body))
            })
            .catch((err) => {
              localStorage.clear();
              window.location.reload();
              // reject(err);
            })
          }
          else {
            reject(e);
          }
        });

        break;
      }
      default: {
        reject('VERB NOT FOUND');
      }
    }
  })
}
