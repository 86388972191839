import * as React from 'react';
import { connect } from 'react-redux';
import EditClientProfile from './EditClientProfileComponent';
import { 
  UpdateClientProfile,
  CreateClientProfile,
  SaveProfileImage
} from './../util/requests';
import {setProfile} from './../reducers/userActions';
import { loading, simpleAlert, setClientHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveProfile: (props, state) => {

      var profile = {
        rfp_creator_name: getPropOrState(props, state, 'rfp_creator_name'),
        job_function: getPropOrState(props, state, 'job_function'),
        certifications: getPropOrState(props, state, 'certifications'),
        industry_awards: getPropOrState(props, state, 'industry_awards'),
        seniority_level: getPropOrState(props, state, 'seniority_level'),
        company_name: getPropOrState(props, state, 'company_name'),
        company_city: getPropOrState(props, state, 'company_city'),
        company_state: getPropOrState(props, state, 'company_state'),
        company_email: getPropOrState(props, state, 'company_email'),
        company_phone_number: getPropOrState(props, state, 'company_phone_number'),
        company_type: getPropOrState(props, state, 'company_type'),
        industry_type: getPropOrState(props, state, 'industry_type'),
        years_in_business: getPropOrState(props, state, 'years_in_business'),
        number_of_employees: getPropOrState(props, state, 'number_of_employees'),
        number_of_marketers: getPropOrState(props, state, 'number_of_marketers'),
        has_outsourced_marketing: getPropOrState(props, state, 'has_outsourced_marketing'),
        new_customer_worth: getPropOrState(props, state, 'new_customer_worth'),
        customer_acquisition_cost: getPropOrState(props, state, 'customer_acquisition_cost'),
        customer_acquisition_time: getPropOrState(props, state, 'customer_acquisition_time'),
        rpf_judge_name: getPropOrState(props, state, 'rpf_judge_name'),
        internal_employee_titles: getPropOrState(props, state, 'internal_employee_titles'),
        company_zip: getPropOrState(props, state, 'company_zip')
      }

      if (
        isEmpty(profile.rfp_creator_name) || isEmpty(profile.job_function) || isEmpty(profile.company_name) ||
        isEmpty(profile.company_email) || isEmpty(profile.company_phone_number) || isEmpty(profile.company_type) ||
        isEmpty(profile.industry_type) || isEmpty(profile.number_of_employees) ||
        isEmpty(profile.number_of_marketers) || isEmpty(profile.has_outsourced_marketing) ||
        isEmpty(profile.rpf_judge_name) || isEmpty(profile.company_city) ||
        isEmpty(profile.company_state) || isEmpty(profile.company_zip)
      ) {
        dispatch(simpleAlert({
          title: 'Error',
          body: "Please enter all required fields"
        }));
        return;
      }

      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRegex.test(profile.company_email) === false) {
        dispatch(simpleAlert({
          title: 'Error',
          body: "Please enter a valid email address"
        }));
        return;
      } 

      // SET CURRENT PROFILE ID IF CURRENT PROFILE IS NOT NULL

      if (props.profile) {
        profile.id = props.profile.id;
      }

      // UPLOAD PROFILE IMAGE IF NEEDED

      dispatch(loading(true));
      
      new Promise(function(resolve, reject) {
        if (state.profile_image && state.profile_image_type) {
          dispatch(SaveProfileImage({
            profile_image: state.profile_image,
            image_type: state.profile_image_type
          }))
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            console.log('SaveProfileImage err', err);
            reject(err);
          });
        }
        else {
          resolve(null);
        }
      })

      // SET NEW PROFILE IMAGE URL IF NEEDED

      .then(res => {
        
        if (res && res.value && res.value.data && res.value.data.url) {
          profile.profile_image = res.value.data.url
        }

        // UPDATE OR CREATE NEW PROFILE

        if (props.profile === null || (props.profile && props.profile.type === "UNDEFINED")) {
          profile.name = 'profile';
          return dispatch(CreateClientProfile(profile))
        }
        else {
          return dispatch(UpdateClientProfile(profile))
        }        
      })
      .then(res => {
        dispatch(setProfile(res.value.data));
        dispatch(loading(false));

        dispatch(simpleAlert({
          title: 'Success',
          body: 'Your profile has been updated'
        }));
        props.history.push('/', {reloadData: true});
        dispatch(setClientHeader('OPEN'));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('CreateClientProfile err', err);
        dispatch(simpleAlert({
          title: "Error",
          body: "An error occurred while updating your profile"
        }));
      });
    }
  }
}

const EditClientProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClientProfile);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

const getPropOrState = (props, state, prop) => {
  
  if (props.profile) {
    return state[prop] === undefined ? props.profile[prop] : state[prop]
  }
  else {
    return state[prop];
  }
}

const isEmpty = (prop) => {
  return prop === null || prop === undefined || prop === ''
}

export default EditClientProfileContainer;