import * as React from 'react';
import { connect } from 'react-redux';
import Home from './HomeComponent';
import {loading, simpleAlert} from './../reducers/displayActions';
import { setClientHeader, setAgencyHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.userReducer.is_authenticated,
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setClientHeaderTab: (tab) => {
      dispatch(setClientHeader(tab));
    },
    setAgencyHeaderTab: (tab) => {
      dispatch(setAgencyHeader(tab));
    }
  }
}

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default HomeContainer;
