export const simpleAlert = (data) => {
  return {
    type: 'DISPLAY_REDUCER_SIMPLE_ALERT_BODY',
    payload: data
  }
}

export const loading = (data) => {
  return {
    type: 'DISPLAY_REDUCER_LOADING',
    payload: data
  }
}

export const setClientHeader = (data) => {
  return {
    type: 'SET_CLIENT_HEADER_TAB',
    payload: data
  }
}

export const setAgencyHeader = (data) => {
  return {
    type: 'SET_AGENCY_HEADER_TAB',
    payload: data
  }
}