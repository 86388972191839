import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class Home extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){

  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;
    const { location } = this.props.history;
    var reloadData = false;
    if (location && location.state && location.state.reloadData === true) {
      reloadData = true
    }  
    if (props.profile && props.profile.type === 'UNDEFINED') {
      this.props.history.push('/edit-profile');
      return (<div/>);
    }
    else if (props.profile && props.profile.type === 'ADMIN') {
      this.props.history.push('/agency-profiles');
      return (<div/>);
    }
    else if (props.profile && props.profile.type === 'CLIENT') {
      this.props.history.push('/open-rfps', {reloadData: reloadData});
      this.props.setClientHeaderTab('OPEN');
      return (<div/>);
    }
    else if (props.profile && props.profile.type === 'AGENCY') {
      this.props.history.push('/open-rfps', {reloadData: reloadData});
      this.props.setAgencyHeaderTab('OPEN');
      return (<div/>);
      // if (props.profile.payment_source) {
      //   this.props.history.push('/open-rfps', {reloadData: reloadData});
      //   this.props.setClientHeaderTab('OPEN');
      //   return (<div/>);
      // }
      // else {
      //   this.props.history.push('/edit-profile');
      //   return (<div/>);
      // }
    }
    else {
      return (
        <div> 
          
        </div>
      );
    }
  }
}

Home.propTypes = {

};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(Home));