import * as React from 'react';
import { connect } from 'react-redux';
import ClientRFP_PDF from './ClientRFP_PDFComponent';
import PDFDocument from 'pdfkit'
import blobStream from 'blob-stream';
import moment from 'moment';
import { 
  GetClientRFP, 
  GetRFPResponses,
  GetAgencyRFPResponse
} from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';
const logo = require('./../assets/pitchless-logo-white.png');

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return { 
    client_template: state.rfpReducer.client_template,
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showPDF: (setState, rfp_responses, rfp_details, client_rfp) => {

      var doc = new PDFDocument();
      var stream = doc.pipe(blobStream());

      if (rfp_responses && client_rfp) {

        dispatch(loading(true));

        var oReq = new XMLHttpRequest();
        oReq.open("GET", logo, true);
        oReq.responseType = "arraybuffer";
        oReq.onload = function(oEvent) {
          
          var arrayBuffer = oReq.response;
          const title = 'RFP: ' + rfp_details.title;
          const opened = 'Opened On: ' + moment(rfp_details.opened_date).format('M/D/YYYY');
          const closed = 'Closes On: ' + moment(rfp_details.closes_on_date).format('M/D/YYYY');
          const views = 'Views: ' + rfp_details.views;
          const responses = 'Responses: ' + rfp_details.response_count;
          const noResponses = 'This RFP does not have any responses';
          doc.image(arrayBuffer, {
            width: 150
          });
          doc.moveDown()
          doc.fontSize(15).text(title).moveDown();
          doc.fontSize(12).text(opened);
          doc.fontSize(12).text(closed);
          doc.fontSize(12).text(views);
          if (rfp_details.response_count !== undefined) {
            doc.fontSize(12).text(responses);
          }
          doc.moveDown().fontSize(15).text('Marketer Questions');

          client_rfp.answers.forEach(answer => {
            doc.moveDown().fontSize(12).text(answer.template_question.description);
            if (answer.rfp_question_response.answer != null) {
              writeAnswer(doc, answer.rfp_question_response);
            }
            else {
              doc.fontSize(12).text('Answer: N/A');
            }
          });

          if (rfp_responses.length > 0) {
            
            rfp_responses.forEach((rfpResponse, index) => {

              doc.moveDown().fontSize(15).text('Agency RFP Response: ' + moment(rfpResponse.rfp_response.created_at).format('M/D/YYYY') ).moveDown();
              
              doc.fontSize(13).text('Agency Details').moveDown();

              doc.fontSize(12).text('Years in Business: ' + rfpResponse.agency_profile.years_in_business);
              doc.fontSize(12).text('Number of Employees: ' + rfpResponse.agency_profile.number_of_employees);
              doc.fontSize(12).text('Can partner with other agencies or contractors on projects: ' + (rfpResponse.agency_profile.can_partner_with_agencies === true ? ' Yes' : ' No'));
              doc.fontSize(12).text('Do you prefer to work on one-time projects or in an ongoing retainer arrangement or both: ' + rfpResponse.agency_profile.project_freq_pref);
              doc.fontSize(12).text('# of proposals submitted: ' + rfpResponse.agency_profile.total_responses);
              doc.fontSize(12).text('# of proposals awarded: ' + rfpResponse.agency_profile.total_awarded_responses);
              doc.fontSize(12).text('Services Provided:');

              rfpResponse.agency_profile.services_provided.forEach(service => {
                doc.fontSize(12).text('   -' +  service);
              })

              doc.moveDown().fontSize(13).text('Agency Answers');

              var agencyAnswersByTemplateId = {};

              if (rfpResponse && rfpResponse.question_response.length > 0) {
                rfpResponse.question_response.forEach((response, index) => {
                  if (response.rfp_question_response && response.rfp_question_response.template_question_id) {
                    agencyAnswersByTemplateId[response.rfp_question_response.template_question_id] = response.rfp_question_response;
                  }
                  else if (response.template_question_id) {
                    agencyAnswersByTemplateId[response.template_question_id] = response;
                  }
                });
              }

              client_rfp.questions.forEach(question => {
                const templateId = question.template_question.id;
                var answer = agencyAnswersByTemplateId[templateId];
                doc.moveDown().fontSize(12).text(question.template_question.description);
                writeAnswer(doc, answer);
              });
            });
          }
          else {
            doc.fontSize(12).moveDown().text(noResponses);
          }

          doc.end();

          stream.on('finish', function() {
            dispatch(loading(false));
            setState({src: stream.toBlobURL('application/pdf')});
          });
        };
        oReq.send(null);
      }
    },
    getData: (props, setState, historyState) => {

      dispatch(loading(true));
      
      var rfp_responses = [];
      var client_rfp = null;

      var promises = [];

      if (historyState.client_rfp) {
        promises.push(historyState.client_rfp);
      }
      else {
        promises.push(dispatch(GetClientRFP({rfp_id: historyState.rfp_details.rfp_id})));
      }

      if (historyState.rfp_responses && historyState.rfp_responses.length >= 0) {
        promises.push(promises.push(historyState.rfp_responses));
      }
      else {
        if (historyState.account_type === 'AGENCY') {
          if (props.profile && props.profile.id) {
            promises.push(dispatch(GetAgencyRFPResponse({
              rfp_id: historyState.rfp_details.rfp_id,
              agency_profile_id: props.profile.id
            })));
          }
        }
        else {
          promises.push(dispatch(GetRFPResponses({rfp_id: historyState.rfp_details.rfp_id})));
        }
      }

      Promise.all(promises)
      .then(results => {

        dispatch(loading(false));

        const res1 = results[0];
        const res2 = results[1];

        if (res1.value && res1.value.data) {
          client_rfp = res1.value.data;
        }
        else {
          client_rfp = res1;
        }

        if (res2.value && res2.value.data) {

          rfp_responses = res2.value.data;
        }
        else {
          rfp_responses = res2;
        }

        props.showPDF(setState, rfp_responses, historyState.rfp_details, client_rfp)
      }) 
      .catch(err => {
        console.log('Getting Data for client pdf failed', err);
        dispatch(loading(false));
      });
    }
  }
}

const ClientRFP_PDFContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientRFP_PDF);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

const writeAnswer = (doc, response) => {
  if (response === null || response === undefined) {
    doc.fontSize(12).text('Answer: N/A');
  }
  else if (response.answer === null || response.answer === undefined) {
    doc.fontSize(12).text('Answer: N/A');
  }
  else {
    const answerType = response.answer_type.toUpperCase();
    if (answerType === 'BOOLEAN') {
      doc.fontSize(12).text('Answer: ' + (response.answer === true ? 'Yes' : 'No'));
    }
    else if (answerType === 'DATE') {
      doc.fontSize(12).text('Answer: ' + moment(response.answer).format('M/D/YYYY'));
    }
    else if (answerType === 'OBJECT') {
      Object.keys(response.answer).forEach(key => {
        doc.fontSize(12).text('Answer ('+key+'): ' + response.answer[key]);
      })
    }
    else {
      doc.fontSize(12).text('Answer: ' + response.answer);
    }
  }
}

export default ClientRFP_PDFContainer;