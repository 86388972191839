import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import rootReducer from "./rootReducer.js";
import promiseMiddleware from 'redux-promise-middleware';
import { LoadEnvironment } from './util/persist';
import { SetEnvironment } from './util/persist';

// SetEnvironment('DEVELOP');
SetEnvironment('STAGING');
// SetEnvironment('PRODUCTION');

var store = null;

if (LoadEnvironment().environment === 'DEVELOP' || LoadEnvironment().environment === 'STAGING') {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        logger,
        promiseMiddleware()
      )
    ),
  );
}
else {
  store = createStore(
    rootReducer,
    applyMiddleware(
      promiseMiddleware()
    ),
  );
}

export default store;
