import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

class SelectMenu extends React.Component {

  button = null;

  constructor() {
    super();
    this.state = {
      anchorEl: null,
      selectedIndex: null
    };
  }

  componentDidMount() {

  }

  handleClickListItem = event => {
    if (this.props.disabled !== true) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, anchorEl: null });
    this.props.optionSelected(index, this.props.options[index]);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, options } = this.props;
    const { anchorEl } = this.state;

    if (
      this.props.initialValue && this.props.initialValue !== '' && 
      this.state.selectedIndex === null
    ){
      for (let index = 0; index < this.props.options.length; index++) {
        const element = this.props.options[index];
        if (element === this.props.initialValue){
          this.setState({selectedIndex: index});
          break;
        }
      }
    }

    return (
      <div className={classes.root} style={{width:300}}>
        <List component="nav">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label={this.props.title}
            onClick={this.handleClickListItem}
            style={{paddingLeft:0}}
          >
            <ListItemText

              primary={this.props.title}
              secondary={options[this.state.selectedIndex]}
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === this.state.selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

SelectMenu.propTypes = {
  options: PropTypes.array.isRequired,
  optionSelected: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(SelectMenu));