import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import ClientClosedRFP from './ClientClosedRFPComponent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AgencyViewRFP from './AgencyViewRFPComponent';
import Modal from '@material-ui/core/Modal';
import {MdClose} from 'react-icons/md';

class ViewAgency extends React.Component {

  constructor() {
    super();
    this.state = {
      viewResponseIsOpen: false,
      rfp: null,
      rfp_id: null
    };
  }

  componentDidMount() {
    var historyState = this.props.history.location.state;

    if (historyState.agency_profile) {

    }
    else {
      this.props.history.push('/');
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    var historyState = this.props.history.location.state;
    if (historyState.agency_profile) {
      const profile = historyState.agency_profile;

      return (
        <div>
          <Modal
            open={this.state.viewResponseIsOpen}
            onRequestClose={() => this.setState({viewResponseIsOpen: false})}
            style={{ height:"90%", overflow: 'scroll'}}>
            <Grid container spacing={24} style={{paddingTop:100}}>
              <Grid item md={1}/>
              <Grid item xs={10}>
                <Paper className={classes.paper}>
                  <div style={{textAlign:'right'}}>
                    <Button
                      onClick={() => this.setState({viewResponseIsOpen: false})}>
                      <MdClose color="gray" size={30} />
                    </Button>
                  </div>
                  <div style={{textAlign:'left'}}>
                    <AgencyViewRFP 
                      rfp_response={this.state.rfp} 
                      rfp_id={historyState.rfp_id}
                      {...this.props}
                      awardRFP={(rfpId, rfpResponseId) => {
                        console.log('rfpId', rfpId);
                        console.log('rfpResponseId', rfpResponseId);
                        this.props.awardRFP(props, rfpId, rfpResponseId);
                        this.setState({viewResponseIsOpen: false});
                      }}
                    />
                  </div>
                </Paper>
              </Grid>
              <Grid item md={1}/>
            </Grid>
          </Modal>
          <div style={{ margin: 50 }}>
            <Grid container>
              <Grid item md={12}>
                <Button
                  style={{
                    padding: 0,
                    margin: 0,
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 25,
                    textTransform: 'none'
                  }}
                  onClick={() => {
                    props.history.push('/marketer-view-rfp', {
                      mode: 'VIEW',
                      rfp_id: historyState.rfp_id
                    })
                  }}
                  color="secondary">
                  {historyState.title}
                </Button>
              </Grid>
            </Grid>
          
            <Grid container spacing={24}>
              <Grid item md={4}>
                <Typography variant="subheading">
                  Status: {historyState.status}
                </Typography>
                <Typography variant="subheading">
                  Opened On: {moment(historyState.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="subheading">
                  Closes On: {moment(historyState.closes_on_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="subheading">
                  Awarded Date: {moment(historyState.awarded_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subheading">
                  Awarded: {profile.agency_name}
                </Typography>
                {/* <Button
                  style={{
                    padding: 0, 
                    margin: 0, 
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 17,
                    textTransform: 'none'
                  }} 
                  onClick={() => {
                    this.props.history.push('/view-agency', {
                      agency_profile: profile,
                      closes_on_date: historyState.closes_on_date,
                      id: historyState.id,
                      title: historyState.title,
                      opened_date: historyState.opened_date,
                      awarded_date: historyState.awarded_date,
                      views: historyState.views,
                      response_count: historyState.response_count,
                      status: historyState.status
                    })
                  }}
                  color="secondary">
                  Awarded: {profile.agency_name}
                </Button> */}
                <Typography variant="body">
                  Viewed By: {historyState.views}
                </Typography>
                <Button
                  color="secondary"
                  style={{padding: 0, margin: 0}}
                  disabled={!(historyState.response_count > 0)}
                  onClick={() => {
                    props.getAgencyResponse(
                      historyState.agency_profile.id, 
                      historyState.rfp_id, 
                      this.setState.bind(this)
                    );
                  }}>
                  <Typography variant="body">
                    View Response
                  </Typography>
                </Button>
              </Grid>
            </Grid>

          </div>
          <Divider
            style={{
              marginTop: 60,
              marginBottom: 20,
              marginLeft: 100,
              marginRight: 100,
              backgroundColor: grey[300]
            }}
          />

          <div style={{ margin: 60 }}>
            <Grid container spacing={24}>
              <Grid item md={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    profile.profile_image ?
                      (
                        <img
                          style={{ width: 75, height: 75 }}
                          src={profile.profile_image}
                        />
                      )
                      :
                      (
                        <div />
                      )
                  }
                  <Typography variant="display1">
                    {profile.agency_name} - Contact Information
                  </Typography>
                </div>
              </Grid>
              <Grid item md={12}>
                <Typography style={{ marginTop: 10 }}variant="headline">
                  Service Provider City: {profile.agency_city}
                </Typography>
                <Typography style={{ marginTop: 10 }} variant="headline">
                  Service Provider State: {profile.agency_state}
                </Typography>
                <Typography style={{ marginTop: 10 }} variant="headline">
                  Service Provider Phone Number: {profile.agency_phone_number}
                </Typography>
                <Typography style={{ marginTop: 10 }} variant="headline">
                  Service Provider Email: {profile.agency_email}
                </Typography>
                {
                  historyState.awarded_responder_name ? 
                  <Typography style={{ marginTop: 10 }} variant="headline">
                    Responder Name: {historyState.awarded_responder_name}
                  </Typography>
                  : null
                }
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
    else {
      props.history.push('/');
      return (<div/>);
    }
  }
}

ViewAgency.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

const getServicesProvided = profile => {
  var views = [];

  if (profile.services_provided) {
    profile.services_provided.forEach(service => {
      views.push(
        <Typography variant="subheading" style={{ marginLeft: 10 }}>- {service}</Typography>
      );
    })
  }

  return views;
}

export default withRoot(withStyles(styles)(ViewAgency));