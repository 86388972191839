import * as React from 'react';
import { connect } from 'react-redux';
import EditAgencyProfile from './EditAgencyProfileComponent';
import { 
  UpdateAgencyProfile,
  CreateAgencyProfile,
  SaveProfileImage,
  SetPaymentSource,
  GetUserProfile,
  DeletePaymentSource
} from './../util/requests';
import {setProfile} from './../reducers/userActions';
import {loading, simpleAlert, setAgencyHeader} from './../reducers/displayActions';

const stripe = Stripe('pk_test_xlX7kvrqSIIH39C4y6jvYR4E');
const elements = stripe.elements();

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deletePaymentSource: (props) => {
      if (props.profile && props.profile.payment_source) {
        dispatch(loading(true));
        dispatch(DeletePaymentSource({payment_source:props.profile.payment_source}))
        .then(res => {
          return dispatch(GetUserProfile());
        })
        .then(res => {
          dispatch(loading(false));
          dispatch(setProfile(res.value.data));
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your payment source has been deleted'
          }));
        })
        .catch(err => {
          dispatch(loading(false));
          console.log('SetPaymentSource err', err);
        });
      }
    },
    stripeTokenHandler: (token) => {
      dispatch(loading(true));
      dispatch(SetPaymentSource({payment_source:token.id}))
      .then(res => {
        return dispatch(GetUserProfile());
      })
      .then(res => {
        dispatch(loading(false));
        dispatch(setProfile(res.value.data));
        dispatch(simpleAlert({
          title: 'Success',
          body: 'Your new payment method has been saved'
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        if (err.response && err.response.data && err.response.data.display_message) {
          dispatch(simpleAlert({
            title:'Error',
            body: err.response.data.display_message
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'An error occurred while setting your payment source'
          }));
        }
        console.log('SetPaymentSource err', err);
      });
    },
    saveProfile: (props, state) => {

      var profile = {
        years_in_business: getPropOrState(props, state, 'years_in_business'),
        number_of_employees: getPropOrState(props, state, 'number_of_employees'),
        agency_name: getPropOrState(props, state, 'agency_name'),
        agency_city: getPropOrState(props, state, 'agency_city'),
        agency_state: getPropOrState(props, state, 'agency_state'),
        agency_email: getPropOrState(props, state, 'agency_email'),
        agency_phone_number: getPropOrState(props, state, 'agency_phone_number'),
        job_function: getPropOrState(props, state, 'job_function'),
        certifications: getPropOrState(props, state, 'certifications'),
        industry_awards: getPropOrState(props, state, 'industry_awards'),
        can_partner_with_agencies: getPropOrState(props, state, 'can_partner_with_agencies'),
        project_freq_pref: getPropOrState(props, state, 'project_freq_pref'),
        services_provided: state.selectedServicesProvided
      }

      if (state.profile_image) {
        profile.profile_image = state.profile_image;
      }

      if (
        isEmpty(profile.years_in_business) || isEmpty(profile.number_of_employees) || isEmpty(profile.agency_name) ||
        isEmpty(profile.agency_city) || isEmpty(profile.agency_state) || isEmpty(profile.agency_email) ||
        isEmpty(profile.agency_phone_number) || isEmpty(profile.can_partner_with_agencies) || isEmpty(profile.project_freq_pref) || profile.services_provided.length === 0
      ) {
        dispatch(simpleAlert({
          title: 'Error',
          body: "Please enter all required fields"
        }));
        return;
      }

      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRegex.test(profile.agency_email) === false) {
        dispatch(simpleAlert({
          title: 'Error',
          body: "Please enter a valid email address"
        }));
        return;
      }

      // SET CURRENT PROFILE ID IF CURRENT PROFILE IS NOT NULL

      if (props.profile) {
        profile.id = props.profile.id;
      }

      // UPLOAD PROFILE IMAGE IF NEEDED
      
      dispatch(loading(true));
      new Promise(function(resolve, reject) {
        if (state.profile_image && state.profile_image_type) {
          dispatch(SaveProfileImage({
            profile_image: state.profile_image,
            image_type: state.profile_image_type
          }))
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            console.log('SaveProfileImage err', err);
            reject(err);
          });
        }
        else {
          resolve(null);
        }
      })

      // SET NEW PROFILE IMAGE URL IF NEEDED

      .then(res => {
        
        if (res && res.value && res.value.data && res.value.data.url) {
          profile.profile_image = res.value.data.url
        }

        // UPDATE OR CREATE NEW PROFILE

        if (props.profile === null || (props.profile && props.profile.type === "UNDEFINED")) {
          profile.name = 'profile';
          return dispatch(CreateAgencyProfile(profile))
        }
        else {
          return dispatch(UpdateAgencyProfile(profile))
        }        
      })
      .then(res => {
        dispatch(setProfile(res.value.data));
        dispatch(loading(false));
        dispatch(simpleAlert({
          title: 'Success',
          body: 'Your profile has been updated'
        }));
        props.history.push('/', {reloadData: true});
        dispatch(setAgencyHeader('OPEN'));
        sessionStorage.removeItem('agency_responses');
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('CreateAgencyProfile err', err);
        if (err.response && err.response.data && err.response.data.display_message) {
          dispatch(simpleAlert({
            title:'Error',
            body: err.response.data.display_message
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'An error occurred while updating your profile'
          }));
        }
      });
    }
  }
}

const EditAgencyProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAgencyProfile);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

const getPropOrState = (props, state, prop) => {
  if (props.profile) {
    return state[prop] === undefined ? props.profile[prop] : state[prop]
  }
  else {
    return state[prop];
  }
}

const isEmpty = (prop) => {
  return prop === null || prop === undefined || prop === ''
}

export default EditAgencyProfileContainer;