import * as React from 'react';
import { connect } from 'react-redux';
import AgencySentResponses from './AgencySentResponsesComponent';
import { GetAgencySentResponses, GetAgencyDashboard } from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';
import { setAgencySentRFPs } from './../reducers/rfpActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import { setAgencyDashboard } from './../reducers/rfpActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfps: state.rfpReducer.agency_sent_rfps,
    totalSentResponses: state.rfpReducer.agency_dashboard.total_sent_responses
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPS: (props) => {

      dispatch(GetAgencyDashboard())
      .then(res => {
        dispatch(setAgencyDashboard(res.value.data));
      })
      .catch(err => {
        console.log("GetAgencyDashboard err", err);
      });

      dispatch(loading(true));
      dispatch(GetAgencySentResponses())
      .then(res => {
        dispatch(setAgencySentRFPs(res.value.data));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetAgencySentResponses err", err);
      })
    },
    getViews: (props) => {
      var views = [];
      props.rfps.forEach((sent, index) => {
        console.log('sent', sent);

        var viewCount = 0;
        if (sent.rfp_meta) {
          viewCount = sent.rfp_meta.views;
        }

        views.push(
          <div style={{margin:50}}>
            <Grid container>
              <Grid item md={2}>
                <Typography variant="body">
                  {moment(sent.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Button
                  style={{
                    padding: 0, 
                    margin: 0, 
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 20,
                    textTransform: 'none'
                  }} 
                  color="secondary"
                  onClick={() => {
                    props.history.push('/view-agency-response', {
                      rfp_id: sent.rfp.id,
                      rfp_response_id: sent.rfp_response.id,
                      mode: 'VIEW'
                    })
                  }}>
                  {sent.rfp.title}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2}/>
              <Grid item md={4}>
                <Typography variant="body">
                  Opened On: {moment(sent.rfp.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="body">
                  Closes On: {moment(sent.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant="body">
                  Viewed By: {viewCount}
                </Typography>
                {/* <Typography variant="body">
                  Responses: {sent.rfp.response_count}
                </Typography> */}
              </Grid>
              <Grid item md={2}>
                <Button
                  onClick={() => props.history.push('/test-pdf', {
                    account_type: "AGENCY",
                    rfp_details: {
                      rfp_id: sent.rfp.id,
                      title: sent.rfp.title,
                      views: viewCount,
                      opened_date: sent.rfp.closes_on_date,
                      closes_on_date: sent.rfp.closes_on_date,
                      response_count: sent.rfp.response_count,
                    }
                  })}>
                  <img 
                    src={require('./../assets/print-icon.png')} 
                    style={{maxWidth:25}}
                  />
                </Button>
              </Grid>
            </Grid>
            <Divider 
              style={{
                marginTop:60, 
                marginBottom:20, 
                marginLeft:100, 
                marginRight:100, 
                backgroundColor: grey[300]
              }}
            />
          </div>
        );
      })
      return views;
    }
  }
}

const AgencySentResponsesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencySentResponses);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AgencySentResponsesContainer;