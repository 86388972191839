import * as React from 'react';
import { connect } from 'react-redux';
import AgencyHeader from './AgencyHeaderComponent';
import { setAgencyHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    dashboard: state.rfpReducer.agency_dashboard,
    agencyHeader: state.displayReducer.agency_header_tab
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAgencyHeaderTab: (tab) => {
      dispatch(setAgencyHeader(tab));
    }
  }
}

const AgencyHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyHeader);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AgencyHeaderContainer;