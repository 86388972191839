import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import { colors } from './../design';

class AgencyViewRFP extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){

  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    console.log('props', props);

    if (props.rfp_response) {

      const profile = props.rfp_response.agency_profile;
      const responses = props.rfp_response.question_response;
      const rfpResponse = props.rfp_response.rfp_response;
      const rfpDetails = props.rfp_details;

      return (
        <div style={{margin: "0 40 0 40"}}>
          <Grid container>
            <Grid item md={10}>
              {
                rfpResponse.status !== 'AGENCY_AWARDED' ?
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.awardRFP(props.rfp_id, rfpResponse.id);
                  }}>
                  AWARD RFP
                </Button>
                : null
              }
            </Grid>
            <Grid item md={2}>
              <div 
                style={{
                  margin: "0 -40 0 -40",
                  paddingLeft: 40
                }}>
                {
                  rfpDetails ? 
                  (
                    <Button
                      onClick={() => this.props.history.push('/test-pdf', {
                        rfp_responses: [props.rfp_response],
                        rfp_details: rfpDetails
                      })}>
                      <img 
                        src={require('./../assets/print-icon.png')} 
                        style={{maxWidth:25}}
                      />
                    </Button>
                  )
                  :
                  (
                    <div/>
                  )
                }
              </div>
            </Grid>
            <Grid item md={12}>
              <Typography variant="title" style={{marginTop: 40}}> 
                Date: {moment(rfpResponse.created_at).format('M/D/YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Typography 
             style={{
              paddingLeft: 40, 
              marginTop:30, 
              width:"%100",
              backgroundColor: colors.lightAccent
            }}  
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Service Provider Details
          </Typography>
          {
            rfpResponse.status === 'AGENCY_AWARDED' ? 
            (
              <Typography variant="title" style={{marginTop:20}}>
                Status: Awarded
              </Typography>
            )
            :
            (
              <div/>
            )
          }
          
          <Grid container className={classes.section}>
            <Grid item md={12}>
              {
                profile.vetted === true ? 
                (
                  <img 
                    src={require('./../assets/vetted-icon.png')} 
                    style={{width:40, height: 40, marginBottom:10}}
                  />
                )
                :
                (
                  <div/>
                )
              }
              <Typography className={classes.detail}variant="subheading">
                Years in Business: {profile.years_in_business}
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                Number of Employees: {profile.number_of_employees}
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                Can partner with other agencies or contractors on projects: 
                {profile.can_partner_with_agencies === true ? ' Yes' : ' No' }
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                Do you prefer to work on one-time projects or in an ongoing 
                retainer arrangement or both: {profile.project_freq_pref}
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                # of proposals submitted: {profile.total_responses}
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                # of proposals awarded: {profile.total_awarded_responses}
              </Typography>
              <Typography className={classes.detail}variant="subheading">
                Services Provided
              </Typography>
              {getServicesProvided(profile)}
            </Grid>
          </Grid>

          <Typography 
            style={{
              paddingLeft: 40, 
              marginTop:30, 
              marginBottom:30, 
              width:"%100",
              backgroundColor: colors.lightAccent
            }} 
            className={classes.typography} variant="display1" align='left' gutterBottom={false}>
            Service Provider RFP Response
          </Typography>
          <Grid container spacing={24}>
            {getAgencyResponses(props.rfp_response)}
          </Grid>
        </div>
      );
    }
    else {
      return (
        <div/>
      );
    }
  }
}

const getServicesProvided = profile => {
  var views = [];

  if (profile.services_provided) {
    profile.services_provided.forEach(service => {
      views.push(
        <Typography variant="subheading" style={{marginLeft:20}}>- {service}</Typography>
      );
    })
  }

  return views;
}

const getAgencyResponses = (rfp) => {
  var views = [];
  if (rfp && rfp.question_response) {
    const responses = rfp.question_response;        
    console.log('responses', responses);
    responses.forEach(_response => {
      const templateQuestion = _response.template_question;
      const response = _response.rfp_question_response;
      if (templateQuestion && templateQuestion.visible_to_responder) {
        console.log('response.answer_type', response.answer_type);
        switch (response.answer_type.toLowerCase()) {
          case 'string': {
            views.push(
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <Typography variant="subheading">
                  {rfp.custom_description ? rfp.custom_description : templateQuestion.description}
                </Typography>
                <Typography variant="body2">
                  {response.answer}
                </Typography>
              </Grid>
            );
            break;
          }
          case 'array': {
            views.push(
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <Typography variant="subheading">
                  {rfp.custom_description ? rfp.custom_description : templateQuestion.description}
                </Typography>
                <Typography variant="body2">
                  {response.answer.toString().replace(new RegExp(',', 'g'), ', ')}
                </Typography>
              </Grid>
            );
            break;
          } 
          case 'boolean': {
            views.push(
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <Typography variant="subheading">
                  {rfp.custom_description ? rfp.custom_description : templateQuestion.description}
                </Typography>
                <Typography variant="body2">
                  {response.answer ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            );
            break;
          } 
          case 'date': {
            views.push(
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <Typography variant="subheading">
                  {rfp.custom_description ? rfp.custom_description : templateQuestion.description}
                </Typography>
                <Typography variant="body2">
                  {moment(response.answer).format('M/D/YYYY')}
                </Typography>
              </Grid>
            );
            break;
          } 
          default:
            break;
        }
      }
    })
  }

  return views;
}

AgencyViewRFP.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  section: {
    margin: "30 0 30 0"
  },
  detail: {
    marginTop: 10
  }
});

export default withRoot(withStyles(styles)(AgencyViewRFP));