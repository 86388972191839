import * as React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PrivateRoute from './PrivateRouteContainer';
import NavBar from './NavBarContainer';
import SimpleAlert from './SimpleAlertContainer';
import { ClipLoader } from 'react-spinners';
import grey from '@material-ui/core/colors/grey';
import Login from './LoginContainer';
import Home from './HomeContainer';
import EditClientProfile from './EditClientProfileContainer';
import EditAgencyProfile from './EditAgencyProfileContainer';
import EditProfile from './EditProfileContainer';
import EditRFP from './EditRFPContainer';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ClientHeader from './ClientHeaderContainer';
import AgencyHeader from './AgencyHeaderContainer';
import OpenRFPs from './OpenRFPsContainer';
import ClientDraftRFPs from './ClientDraftRFPsContainer';
import ClientClosedRFPs from './ClientClosedRFPsContainer';
import ResetPassword from './ResetPasswordContainer';
import EditAgencyResponse from './EditAgencyResponseContainer';
import AgencyDraftResponses from './AgencyDraftResponsesContainer';
import AgencySentResponses from './AgencySentResponsesContainer';
import AgencyClosedRFPs from './AgencyClosedRFPsContainer';
import ViewResponses from './ViewResponsesContainer'
import ViewAgency from './ViewAgencyContainer';
import ViewClient from './ViewClientComponent';
import ClientRFP_PDF from './ClientRFP_PDFContainer';
import AgencyProfiles from './AgencyProfilesContainer';
import CompareResponses from './CompareResponsesContainer';
import { colors } from './../design';
import numeral from 'numeral';

class AppRouter extends React.Component {

  componentDidMount(){
    this.props.checkAuth(this.props);
  }

  render() {
    var winPercentage = 0;
    if (this.props.agencyDashboard.total_responses > 0) {
      winPercentage = ((this.props.agencyDashboard.total_awarded_responses / this.props.agencyDashboard.total_responses) * 100).toFixed(2);
    }
    return (
      <Router>
        <div style={{}}>
          <SimpleAlert/>
          <div
            style={{
              position:'fixed',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 80,
              left: 0,
              right: 0,
              width:35,
              zIndex:1000
            }}>
            <ClipLoader
              color='gray'
              loading={this.props.loading}
            />
          </div>
          {/* HEADER */}
          {
            this.props.isAuthenticated ?
            <div style={{}}>
              <NavBar {...this.props}/>
            </div>
            :
            null 
          }
          {/* BODY */}
          <Grid container>
            {
              this.props.isAuthenticated && this.props.profile && this.props.profile.type !== "ADMIN" ? 
              (
                <Grid item xs={12} sm={2} md={2} lg={2} 
                  style={{
                    minHeight:'100%',
                    backgroundColor: colors.darkShades
                  }}>
                  <div style={{display: 'flex', justifyContent:'center', marginTop:40}}>
                    {
                      this.props.profile && this.props.profile.profile_image ? 
                      (
                        <img
                          style={{
                            width:150, 
                            height:150, 
                            backgroundColor:'white',
                            borderRadius:5
                          }}
                          src={this.props.profile.profile_image}
                        />
                      )
                      :
                      (
                        <img 
                          src={require('./../assets/profile-icon.png')} 
                          width="150" 
                          height="150" 
                          style={{
                            padding: 10,
                            backgroundColor:'white', 
                            borderRadius:5
                          }}
                        />
                      )
                    }
                  </div>
                  <div style={{display: 'flex', justifyContent:'center'}}>
                    <Button 
                      style={{color:'white'}}
                      component={Link}
                      to="/edit-profile">
                      edit profile
                    </Button>
                  </div>
                  {
                    this.props.profile && this.props.profile.type === "CLIENT" ? 
                    (
                      <div>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            margin:"40 20 0 20",
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          RFP Stats
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Total Number of Responses Received
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {this.props.clientDashboard.agency_responses}
                        </Typography>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Total Number of Awarded RFPs
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {this.props.clientDashboard.awarded_responses}
                        </Typography>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Total # of RFP's Created
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {this.props.clientDashboard.all_rfps}
                        </Typography>
                      </div>
                    )
                    :
                    (
                      <div/>
                    )
                  }
                  {
                    this.props.profile && this.props.profile.type === "AGENCY" ? 
                    (
                      <div>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            margin:"40 20 0 20",
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          RFP Response Stats
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Total Number of RFP Responses
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {this.props.agencyDashboard.total_responses}
                        </Typography>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Total Number of Awarded RFPs
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {this.props.agencyDashboard.total_awarded_responses}
                        </Typography>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          RFP Win Rate
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {winPercentage} %
                        </Typography>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Divider style={{margin:20, backgroundColor: grey[300]}}/>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            margin:"20 20 0 20"
                          }}>
                          Estimated Value of RFP's Won
                        </Typography>
                        <Typography
                          variant="subheading" 
                          style={{
                            color:'white', 
                            textAlign:'center',
                            fontWeight: 600
                          }}>
                          {numeral(this.props.agencyDashboard.estimated_value_of_awarded_rfps).format('$0,0')}
                        </Typography>
                      </div>
                    )
                    :
                    (
                      <div/>
                    )
                  }
                </Grid>
              )
              :
              (
                <div/>
              )
            }
            <Grid
              style={{backgroundColor:'white'}} 
              item 
              xs={this.props.profile ? 12 : 12} 
              sm={this.props.isAuthenticated && this.props.profile && this.props.profile.type !== "ADMIN" ? 10 : 12} 
              md={this.props.isAuthenticated && this.props.profile && this.props.profile.type !== "ADMIN" ? 10 : 12} 
              lg={this.props.isAuthenticated && this.props.profile && this.props.profile.type !== "ADMIN" ? 10 : 12}>
              {
                this.props.isAuthenticated && this.props.profile && this.props.profile.type === "CLIENT" ? 
                (
                  <ClientHeader {...this.props}/>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.isAuthenticated && this.props.profile && this.props.profile.type === "AGENCY" ? 
                (
                  <AgencyHeader {...this.props}/>
                )
                :
                (
                  <div/>
                )
              }
              <div style={{marginBottom:150}}>
                <PrivateRoute exact path="/" protectedComponent={Home} {...this.props}/>
                <PrivateRoute exact path="/edit-marketer-profile" protectedComponent={EditClientProfile} {...this.props}/>
                <PrivateRoute exact path="/edit-agency-profile" protectedComponent={EditAgencyProfile} {...this.props}/>
                <PrivateRoute exact path="/edit-profile" protectedComponent={EditProfile} {...this.props}/>
                <PrivateRoute exact path="/edit-rfp" protectedComponent={EditRFP} {...this.props}/>
                <PrivateRoute exact path="/marketer-view-rfp" protectedComponent={EditRFP} {...this.props}/>
                <PrivateRoute exact path="/open-rfps" protectedComponent={OpenRFPs} {...this.props}/>
                <PrivateRoute exact path="/marketer-draft-rfps" protectedComponent={ClientDraftRFPs} {...this.props}/>
                <PrivateRoute exact path="/marketer-closed-rfps" protectedComponent={ClientClosedRFPs} {...this.props}/>
                <PrivateRoute exact path="/edit-agency-response" protectedComponent={EditAgencyResponse} {...this.props}/>
                <PrivateRoute exact path="/view-agency-response" protectedComponent={EditAgencyResponse} {...this.props}/>
                <PrivateRoute exact path="/agency-draft-responses" protectedComponent={AgencyDraftResponses} {...this.props}/>
                <PrivateRoute exact path="/agency-sent-responses" protectedComponent={AgencySentResponses} {...this.props}/>
                <PrivateRoute exact path="/agency-closed-rfps" protectedComponent={AgencyClosedRFPs} {...this.props}/>
                <PrivateRoute exact path="/view-rfp-responses" protectedComponent={ViewResponses} {...this.props}/>
                <PrivateRoute exact path="/view-agency" protectedComponent={ViewAgency} {...this.props}/>
                <PrivateRoute exact path="/view-marketer" protectedComponent={ViewClient} {...this.props}/>
                <PrivateRoute exact path="/test-pdf" protectedComponent={ClientRFP_PDF} {...this.props}/>
                <PrivateRoute exact path="/compare-responses" protectedComponent={CompareResponses} {...this.props}/>
                <PrivateRoute exact path="/agency-profiles" protectedComponent={AgencyProfiles} {...this.props} role="ADMIN"/>
                <Route path="/login" component={Login} {...this.props}/>
                <Route path="/reset-password" component={ResetPassword} {...this.props}/>
              </div>
            </Grid>
          </Grid>
        </div>
      </Router>
    );
  }
}

AppRouter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default AppRouter;
