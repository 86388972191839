import * as React from 'react';
import { connect } from 'react-redux';
import EditRFP from './EditRFPComponent';
import { 
  GetTemplate, 
  CreateRFP, 
  PublishRFP, 
  GetClientRFP,
  UpdateRFP 
} from './../util/requests';
import { 
  setEditRFPProperty, 
  setSetClientTemplate,
  setAgencyTemplate,
  setExistingClientQuestions,
  setExistingAgencyQuestions,
  clearForEditRFP
} from './../reducers/rfpActions';
import {loading, simpleAlert} from './../reducers/displayActions';
import RFPQuestion from './RFPQuestionComponent';
import moment from 'moment';
import { setClientHeader } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    client_template: state.rfpReducer.client_template,
    agency_template: state.rfpReducer.agency_template,
    tempAgencyQuestions: state.rfpReducer.template_agency_questions,
    tempClientQuestions: state.rfpReducer.template_client_questions,
    existingRFPQuestions: state.rfpReducer.existing_agency_questions,
    existingRFPAnswers: state.rfpReducer.existing_client_answers,
    profile: state.userReducer.profile
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateRFP: (props, state, publish, rfpId) => {

      if (rfpId) {
        var rfp = {
          rfp_id: rfpId,
          client_answers: [],
          agency_questions: []
        };
        
        var agencyQuestions = sessionStorage.getItem('rfp_client_custom_questions');
        agencyQuestions = JSON.parse(agencyQuestions);

        var clientAnswers = sessionStorage.getItem('rfp_client_answers');
        clientAnswers = JSON.parse(clientAnswers);

        if (agencyQuestions) {
          Object.keys(agencyQuestions).forEach(key => {
            const agencyQuestion = agencyQuestions[key];
            rfp.agency_questions.push(agencyQuestion);
          });
        }

        // BUILD AN ARRAY OF MODIFIED CLIENT ANSWERS

        if (clientAnswers) {

          Object.keys(clientAnswers).forEach(key => {
            
            const clientAnswer = clientAnswers[key];

            if (clientAnswer.answer_type === "OBJECT") {

              // GET THE NEW ANSWERS
              const newAnswers = clientAnswer.answer;

              console.log('props.existingRFPAnswers[clientAnswer.template_question_id]', props.existingRFPAnswers[clientAnswer.template_question_id]);
              
              // GET THE EXISTING ANSWERS
              const existingAnswer = props.existingRFPAnswers[clientAnswer.template_question_id];

              // REPLACE THE EXISTING ANSWERS WITH THE CURRENT ANSWERS
              if (existingAnswer && existingAnswer.rfp_question_response) {
                clientAnswer.answer = Object.assign({}, existingAnswer.rfp_question_response.answer, newAnswers);
              }
            }

            rfp.client_answers.push(clientAnswer);
          });
        }

        if (state.title && state.title != '') {
          rfp.rfp_title = state.title;
        }

        if (state.close_rfp_date) {
          rfp.closes_on_date = state.close_rfp_date;
        }

        if (state.max_agency_miles) {
          rfp.max_agency_miles = state.max_agency_miles;
        }

        if (state.desired_partner_location) {
          rfp.desired_partner_location = state.desired_partner_location;
        }

        dispatch(loading(true))
        dispatch(UpdateRFP(rfp))
        .then(res => {
          dispatch(loading(false));

          if (res && publish) {
            return dispatch(PublishRFP({rfp_id: rfpId}));
          }
          else {
            dispatch(simpleAlert({
              title: 'Success',
              body: 'Your RFP has been saved as a draft'
            }));
            return null;
          }
        })
        .then(res => {
          if (res) {
            dispatch(simpleAlert({
              title: 'Success',
              body: 'Your RFP has been published'
            }));
          }
  
          dispatch(loading(false));
          props.history.push('/', {reloadData: true});
          dispatch(setClientHeader('OPEN'));
          sessionStorage.removeItem('rfp_client_answers');
          sessionStorage.removeItem('rfp_client_custom_questions');
          dispatch(clearForEditRFP());
        })
        .catch(err => {
          dispatch(loading(false));
          console.log('Update RFP err', err);
          console.log('err.response.data', err.response.data);
          if (
            err.response && err.response.data && 
            err.response.data && err.response.data.display_message
          ) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error creating this RFP. Please try again later.'
            }));
          }
          props.history.push('/');
        });
      }
    },
    saveRFP: (props, state, publish) => {

      var formattedQuestions = [];
      var formattedAnswers = [];
      
      if (state.title === '') {
        dispatch(simpleAlert({
          title: 'Error',
          body: 'Please enter a title'
        }));
        return;
      }

      // BUILDING A LIST OF QUESTIONS FOR THE AGENCY TO ANSWER BASED ON THE TEMPLATE
      // IF NO CUSTOM QUESTION DESCRIPTIONS WAS CHOSEN USE NULL

      if (props.tempAgencyQuestions) {
        var customDescriptions = sessionStorage.getItem('rfp_client_custom_questions');
        customDescriptions = JSON.parse(customDescriptions);
        Object.keys(props.tempAgencyQuestions).forEach(key => {
          const question = props.tempAgencyQuestions[key];
          var customDescription = null;
          if (customDescriptions && customDescriptions[question.id]) {
            customDescription = customDescriptions[question.id].custom_description;
          }
          formattedQuestions.push({
            custom_description: customDescription ? customDescription : null,
            template_question_id: question.id
          });
        });
      }

      // BUILDING A LIST OF ANSWERS TO THE CLIENT QUESTIONS BASED ON THE TEMPLATE
      // VALIDATE IF AN ANSWER IS REQUIRED AND IS THE RIGHT TYPE

      if (props.tempClientQuestions) {

        var clientAnswers = sessionStorage.getItem('rfp_client_answers');
        clientAnswers = JSON.parse(clientAnswers);
        const clientTemplateQuestionKeys = Object.keys(props.tempClientQuestions);

        for (let i = 0; i < clientTemplateQuestionKeys.length; i++) {

          const clientTemplateQuestionKey = clientTemplateQuestionKeys[i];
          const clientTemplateQuestion = props.tempClientQuestions[clientTemplateQuestionKey];
          console.log('clientTemplateQuestion', clientTemplateQuestion);
          if (clientAnswers && clientAnswers[clientTemplateQuestion.id]) {
            const clientAnswer = clientAnswers[clientTemplateQuestion.id].answer;
            formattedAnswers.push(
              {
                template_question_id: clientTemplateQuestion.id,
                answer_type: clientTemplateQuestion.response_type,
                answer: clientAnswer
              }
            );
          }
        }
      }

      dispatch(loading(true));
      var newRFP = {
        title: state.title,
        answers: formattedAnswers,
        questions: formattedQuestions,
        closes_on_date: state.close_rfp_date,
        max_agency_miles: state.max_agency_miles,
        desired_partner_location: state.desired_partner_location
      };
      dispatch(CreateRFP(newRFP))
      .then(res => {

        if (res.value && res.value.data && res.value.data.rfp && publish) {
          return dispatch(PublishRFP({rfp_id: res.value.data.rfp.id}));
        }
        else {
          dispatch(loading(false));
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your RFP has been saved as a draft'
          }));
          return null;
        }
      })
      .then(res => {
        if (res) {
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your RFP has been published'
          }));
        }
        dispatch(loading(false));
        props.history.push('/', {reloadData: true});
        dispatch(setClientHeader('OPEN'));
        sessionStorage.removeItem('rfp_client_answers');
        sessionStorage.removeItem('rfp_client_custom_questions');
        dispatch(clearForEditRFP());
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('err.response.data', err.response.data);
        if (
          err.response && err.response.data && 
          err.response.data && err.response.data.display_message
        ) {
          dispatch(simpleAlert({
            title:'Error',
            body: err.response.data.display_message
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'There was an error creating this RFP. Please try again later.'
          }));
        }
        props.history.push('/');
        console.log('Create RFP err', err);
      })
    },
    getTemplates: (props, setState) => {
      dispatch(clearForEditRFP());
      const state = props.history.location.state;
      dispatch(loading(true));
        dispatch(GetTemplate("Template 1", "CLIENT"))
        .then(res => {
          if (res.value && res.value.data && res.value.data.results) {
            if (res.value.data.results.length > 0) {
              dispatch(setSetClientTemplate(res.value.data.results[0]));
              var template = res.value.data.results[0];
              if(template && template.template_questions && template.template_questions.default) {
                var tempQuestions = template.template_questions.default;
                var rfpQuestions = {};
                tempQuestions.forEach((tempQuestion) => {
                  rfpQuestions[tempQuestion.id] = tempQuestion;
                });
                dispatch(setEditRFPProperty({
                  value: rfpQuestions,
                  prop: 'template_client_questions'
                }));
              }
            }
          }
          
          return dispatch(GetTemplate("Template 1", "AGENCY"));
        })
        .then(res => {
          if (res.value && res.value.data && res.value.data.results) {
            if (res.value.data.results.length > 0) {
              dispatch(setAgencyTemplate(res.value.data.results[0]));
              var template = res.value.data.results[0];
              if(template && template.template_questions && template.template_questions.default) {
                var tempQuestions = template.template_questions.default;
                var rfpQuestions = {};
                tempQuestions.forEach((tempQuestion) => {
                  rfpQuestions[tempQuestion.id] = tempQuestion;
                });
                dispatch(setEditRFPProperty({
                  value: rfpQuestions,
                  prop: 'template_agency_questions'
                }));
              }
            }
          }

          if (state && state.rfp_id) {
            return dispatch(GetClientRFP({rfp_id: state.rfp_id}));
          }
          else {
            dispatch(loading(false));
          }
        })
        .then(res => {
          if (res) {
            var answers = {};
            res.value.data.answers.forEach(answer => {
              answers[answer.template_question.id] = answer;
            });
            var questions = {};
            res.value.data.questions.forEach(question => {
              questions[question.template_question.id] = question;
            });
            dispatch(setExistingAgencyQuestions(answers));
            dispatch(setExistingClientQuestions(questions));            
            if (res.value.data.rfp){
              setState({title: res.value.data.rfp.title})
            }
          }
          dispatch(loading(false));
        })
        .catch(err => {
          dispatch(loading(false));
          console.log('GetTemplate err', err);
        });
    },
    getTempRFPQuestions: (props, setState, role, disabled, state) => {
      var questions = [];
      if (role === 'EDIT') {
        if (props.tempAgencyQuestions) {
          var keys = Object.keys(props.tempAgencyQuestions);
          var rfpQuestionsArray = [];
          keys.forEach(key => {
            rfpQuestionsArray.push(props.tempAgencyQuestions[key]);
          })
          rfpQuestionsArray.sort((a, b) => {
            if (a.order < b.order) {
              return -1
            }
            else if (a.order > b.order){
              return 1;
            }
            else {
              return 0;
            }
          });
  
          rfpQuestionsArray.forEach(rfpQuestion => {
            var originalDescription = rfpQuestion.description;
            var description = rfpQuestion.description;
            if (props.existingRFPQuestions) {
              var question = props.existingRFPQuestions[rfpQuestion.id];
              if ( question && question.custom_description != undefined ) {
                description = question.custom_description
              }
              else if ( question && question.template_question ) {
                description = question.template_question.description
              }
            }

            questions.push(
              <RFPQuestion
                role="EDIT"
                disabled={disabled}
                title={rfpQuestion.title}
                description={description}
                originalDescription={originalDescription}
                id={rfpQuestion.id}
                answers={rfpQuestion.answers}
                questionType={rfpQuestion.question_type}
                responseType={rfpQuestion.response_type}
                required={rfpQuestion.required}
                descriptionChanged={(customDescription) => {
                  var questions = sessionStorage.getItem('rfp_client_custom_questions');
                  questions = JSON.parse(questions);
                  if (questions === null || questions === undefined) {
                    questions = {};
                  }
                  questions[rfpQuestion.id] = {
                    custom_description: customDescription,
                    template_question_id: rfpQuestion.id
                  }
                  sessionStorage.setItem('rfp_client_custom_questions', JSON.stringify(questions));
                }}
              />
            )
          })
        }
      }
      else if (role === 'ANSWER') {
        if (props.tempClientQuestions) {
          var keys = Object.keys(props.tempClientQuestions);
          var rfpQuestionsArray = [];
          keys.forEach(key => {
            rfpQuestionsArray.push(props.tempClientQuestions[key]);
          })
          rfpQuestionsArray.sort((a, b) => {
            if (a.order < b.order) {
              return -1
            }
            else if (a.order > b.order){
              return 1;
            }
            else {
              return 0;
            }
          });

          rfpQuestionsArray.forEach(rfpQuestion => {
            var initialValue = null;
            if (props.existingRFPAnswers) {
              var answer = props.existingRFPAnswers[rfpQuestion.id];
              if (answer && answer.rfp_question_response && answer.rfp_question_response.answer != undefined) {
                initialValue = answer.rfp_question_response.answer
              }
            }

            if (rfpQuestion.property === 'desired_partner_location' && state.desired_partner_location === null && initialValue) {
              console.log('initialValue', initialValue);
              setState({desired_partner_location: initialValue});
            }

            // HIDE MAX AGENCY MILES IF DESIRED PARTNER LOCATION IS NOT LOCAL

            if (rfpQuestion.property === 'max_agency_miles' && state.desired_partner_location !== 'Local') {
              return;
            }

            questions.push(
              <RFPQuestion
                disabled={disabled}
                role="ANSWER"
                title={rfpQuestion.title}
                description={rfpQuestion.description}
                initialValue={initialValue}
                id={rfpQuestion.id}
                showIDK={rfpQuestion.show_idk}
                answers={rfpQuestion.answers}
                questionType={rfpQuestion.question_type}
                responseType={rfpQuestion.response_type}
                textFields={rfpQuestion.text_fields}
                required={rfpQuestion.required}
                answerChanged={(answer, multiTextFieldProp) => {
                  var answers = sessionStorage.getItem('rfp_client_answers');
                  answers = JSON.parse(answers);
                  if (answers === null || answers === undefined) {
                    answers = {};
                  }
                  if (answers[rfpQuestion.id] === undefined) {
                    answers[rfpQuestion.id] = {};
                  }
                  if (multiTextFieldProp) {
                    if (answers[rfpQuestion.id].answer === undefined) {
                      answers[rfpQuestion.id].answer = {};
                    }
                    answers[rfpQuestion.id].answer[multiTextFieldProp] = answer;
                  }
                  else {
                    answers[rfpQuestion.id].answer = answer;
                  }

                  answers[rfpQuestion.id].template_question_id = rfpQuestion.id;
                  answers[rfpQuestion.id].answer_type = rfpQuestion.response_type;

                  if (props.existingRFPAnswers) {
                    var existingAnswer = props.existingRFPAnswers[rfpQuestion.id];
                    if (existingAnswer && existingAnswer.rfp_question_response) {
                      answers[rfpQuestion.id].rfp_question_response_id = existingAnswer.rfp_question_response.id
                    }
                  }

                  sessionStorage.setItem('rfp_client_answers', JSON.stringify(answers));
                  if (rfpQuestion.property === 'close_rfp_date') {
                    setState({close_rfp_date: answer});
                  }
                  if (rfpQuestion.property === 'max_agency_miles') {
                    setState({max_agency_miles: answer});
                  }
                  
                  if (rfpQuestion.property === 'desired_partner_location') {
                    setState({desired_partner_location: answer});
                  }
                }}
              />
            )
          })
        }
      }
      return questions;
    },
    validateRFP: (props, newClientAnswers, rfpTitle) => {

      if (rfpTitle === null || rfpTitle === undefined || rfpTitle === '') {
        return false;
      }

      // FOR EACH CLIENT TEMPLATE QUESTION MAKE SURE THERE IS A SUPPLIED CLIENT ANSWER EITHER NEW OR EXISTING

      var validRequest = true;
      if (props.tempClientQuestions) {

        var clientAnswers = Object.assign({}, props.existingRFPAnswers, newClientAnswers);

        const clientTemplateQuestionKeys = Object.keys(props.tempClientQuestions);

        for (let i = 0; i < clientTemplateQuestionKeys.length; i++) {

          const clientTemplateQuestionKey = clientTemplateQuestionKeys[i];
          const clientTemplateQuestion = props.tempClientQuestions[clientTemplateQuestionKey];
          console.log('clientTemplateQuestion', clientTemplateQuestion);
          var clientAnswerObj = clientAnswers[clientTemplateQuestionKey];
          if (clientAnswerObj && clientAnswerObj.rfp_question_response) {
            clientAnswerObj = clientAnswerObj.rfp_question_response;
          }

          if (clientTemplateQuestion.required === true) {

            if (clientAnswerObj) {
              if (clientTemplateQuestion.response_type === 'OBJECT') {

                // SINCE THIS IS AN OBJECT GO THROUGH EACH SUB CLIENT TEMPLATE QUESTION AND 
                // VERIFY THERE IS A CLIENT ANSWER IF REQUIRED 
  
                for (let i = 0; i < clientTemplateQuestion.text_fields.length; i++) {
                  const clientTextField = clientTemplateQuestion.text_fields[i];
                  if (clientTextField.required === true) {
                    const clientSubAnswer = clientAnswerObj.answer[clientTextField.description];
                    if (
                      clientSubAnswer === null || clientSubAnswer === undefined ||
                      clientSubAnswer === ''
                    )
                    {
                      validRequest = false;
                      break;
                    }
                  }
                }
                if (validRequest === false) {
                  break;
                }
              }
              else {
                if (
                  clientAnswerObj.answer === null || 
                  clientAnswerObj.answer === undefined ||
                  clientAnswerObj.answer === ''
                ) {
                  validRequest = false;
                  break;
                }
              }
            } 
            else {
              validRequest = false;
              break;
            }
          }
        }
      }
      return validRequest;
    }
  }
}

const EditRFPContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRFP);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default EditRFPContainer;