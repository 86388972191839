import * as React from 'react';
import { connect } from 'react-redux';
import AgencyClosedRFPs from './AgencyClosedRFPsComponent';
import { GetAgencyClosedRFPs, GetAgencyDashboard } from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';
import { setAgencyClosedRFPs } from './../reducers/rfpActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import { setAgencyDashboard } from './../reducers/rfpActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfps: state.rfpReducer.agency_closed_rfps,
    rfps_won: state.rfpReducer.agency_closed_rfps_won,
    rfps_lost: state.rfpReducer.agency_closed_rfps_lost,
    total_closed_rfps: state.rfpReducer.agency_dashboard.total_closed_rfps
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPS: (props) => {
      dispatch(loading(true));
      dispatch(GetAgencyClosedRFPs())
      .then(res => {
        dispatch(setAgencyClosedRFPs(res.value.data));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetAgencyClosedRFPs err", err);
      });

      dispatch(GetAgencyDashboard())
      .then(res => {
        dispatch(setAgencyDashboard(res.value.data));
      })
      .catch(err => {
        console.log("GetAgencyDashboard err", err);
      });
    },
    getViews: (props, state) => {
      var views = [];
      var rfps = [];

      switch (state.toggleSelected) {
        case "All":
          rfps = props.rfps;
          break;
        case "Won":
          rfps = props.rfps_won;
          break;
        case "Lost":
          rfps = props.rfps_lost;
          break;
      }

      rfps.forEach((closed, index) => {

        views.push(
          <div style={{margin:50}}>
            <Grid container>
              <Grid item md={2}>
                <Typography variant="body">
                  {moment(closed.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Button
                  style={{
                    padding: 0, 
                    margin: 0, 
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 20,
                    textTransform: 'none'
                  }} 
                  color="secondary"
                  onClick={() => {
                    props.history.push('/view-agency-response', {
                      rfp_id: closed.rfp.id,
                      rfp_response_id: closed.rfp_response.id,
                      mode: 'VIEW'
                    })
                  }}>
                  {closed.rfp.title}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2}/>
              <Grid item md={4}>
                <Typography variant="body">
                  Opened On: {moment(closed.rfp.opened_date).format('M/D/YYYY')}
                </Typography>
                <Typography variant="body">
                  Closes On: {moment(closed.rfp.closes_on_date).format('M/D/YYYY')}
                </Typography>
                {
                  closed.rfp_response.status === 'AGENCY_AWARDED' && closed.client_profile ?
                  (
                    <Button
                      style={{
                        padding: 0, 
                        margin: 0, 
                        minWidth: 0,
                        minHeight: 0,
                        fontSize: 17,
                        textTransform: 'none'
                      }} 
                      onClick={() => {
                        props.history.push('/view-marketer', {
                          client_profile: closed.client_profile,
                          rfp: closed.rfp,
                          rfp_meta: closed.rfp_meta
                        })
                      }}
                      color="secondary">
                      Marketer: {closed.client_profile.company_name}
                    </Button>
                  ) 
                  :
                  (
                    <div/>
                  )
                }
              </Grid>
              <Grid item md={2}>
                <Typography variant="body">
                  Viewed By: {closed.rfp_meta ? closed.rfp_meta.views : 0}
                </Typography>
                <Typography variant="body">
                  Status: {closed.rfp_response.status === 'AGENCY_AWARDED' ? 'Won' : 'Lost' }
                </Typography>
                {/* <Typography variant="body">
                  Responses: {closed.rfp.response_count}
                </Typography> */}
              </Grid>
              <Grid item md={2}>
                <Button
                  onClick={() => props.history.push('/test-pdf', {
                    account_type: "AGENCY",
                    rfp_details: {
                      rfp_id: closed.rfp.id,
                      title: closed.rfp.title,
                      views: closed.rfp_meta ? closed.rfp_meta.views : 0,
                      opened_date: closed.rfp.opened_date,
                      closes_on_date: closed.rfp.closes_on_date,
                      response_count: closed.rfp.response_count,
                    }
                  })}>
                  <img 
                    src={require('./../assets/print-icon.png')} 
                    style={{maxWidth:25}}
                  />
                </Button>
              </Grid>
            </Grid>
            <Divider 
              style={{
                marginTop:60, 
                marginBottom:20, 
                marginLeft:100, 
                marginRight:100, 
                backgroundColor: grey[300]
              }}
            />
          </div>
        );
      })
      return views;
    }
  }
}

const AgencyClosedRFPsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyClosedRFPs);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AgencyClosedRFPsContainer;