import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SelectBool from './SelectBoolComponent';
import SelectMenu from './SelectMenuComponent';
import MultiSelect from './MultiSelectComponent';
import Button from '@material-ui/core/Button';
import {MdRefresh} from 'react-icons/md';
import moment from 'moment';
import numeral from 'numeral';

class RFPQuestion extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      customDescription: null,
      text: null,
      date: null,
      selectedItems: null,
      textFields: null,
      number: null
    };
  }

  componentDidMount(){

  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (
      this.props.questionType === 'TEXT' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.text === null
    ) {
      this.setState({text: this.props.initialValue});
    }
    else if (
      this.props.questionType === 'DATE' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.date === null
    ) {
      this.setState({date: moment(this.props.initialValue).format('YYYY-MM-DD')});
    }
    else if (
      this.props.questionType === 'CHECK_BOXES' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.selectedItems === null
    ) {
      this.setState({selectedItems: this.props.initialValue})
    }
    else if (
      this.props.questionType === 'TEXT_FIELDS' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.textFields === null
    ) {
      this.setState({textFields: this.props.initialValue});
    }
    else if (
      this.props.questionType === 'NUMBER' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.number === null
    ) {
      this.setState({number: this.props.initialValue});
    }
    else if (
      this.props.questionType === 'CURRENCY' && this.props.initialValue !== null && 
      this.props.initialValue !== undefined && this.state.number === null
    ) {
      this.setState({number: this.props.initialValue});
    }

    return (
      <div style={{margin:50}}>
        {
          this.props.role === 'EDIT' ?
          (
            <div>
              <TextField
                variant="filled"
                multiline
                rowsMax="4"
                className={classes.textField}
                disabled={props.disabled}
                value={state.customDescription ? state.customDescription : props.description}
                label={props.title}
                onChange={(event) => {
                  this.setState({customDescription: event.target.value});
                  this.props.descriptionChanged(event.target.value);
                }}
                margin="normal"
              />
              <Button
                onClick={() => {
                  this.setState({customDescription: props.originalDescription});
                  this.props.descriptionChanged(props.originalDescription);
                }}>
                <MdRefresh color="gray" size={20} style={{marginBottom:15}}/>
              </Button>

            </div>
          )
          :
          (
            <div>
              <Typography className={classes.typography} variant="title" align='left' gutterBottom={false}>
                {props.required === true ? '* ': ''} {props.title}
              </Typography>
              <Typography 
                style={{marginTop:20}}
                className={classes.typography} variant="body" align='left' gutterBottom={false}>
                {props.description}
              </Typography>
              {
                props.sub_text !== null && props.sub_text !== undefined ? 
                <Typography 
                  style={{marginTop:20}}
                  className={classes.typography} variant="body" align='left' gutterBottom={false}>
                  Marketer Answer: {props.sub_text}
                </Typography>
                : null
              }
              {
                this.props.questionType === "TEXT_FIELDS" ? 
                (
                  <div>
                    {
                      props.textFields.map(textField => {
                        if (textField.type === 'CURRENCY') {
                          var value = '';
                          if (this.state.textFields) {
                            if (isNaN(this.state.textFields[textField.description]) === false){
                              value = numeral(this.state.textFields[textField.description]).format('$0,0')
                            }
                          }
                          
                          return (
                            <TextField
                              variant="filled"
                              label={textField.description}
                              required={textField.required}
                              disabled={props.disabled}
                              multiline
                              rowsMax="4"
                              className={classes.textField}
                              value={value}
                              onChange={(event) => {
                                const value = numeral(event.target.value).value();
                                this.props.answerChanged(value, textField.description);
                                this.setState({"textFields": {
                                  ...this.state.textFields,
                                  [textField.description]: value
                                }});
                              }}
                              margin="normal"
                            />
                          )
                        } 
                        else {
                          return (
                            <TextField
                              variant="filled"
                              label={textField.description}
                              required={textField.required}
                              disabled={props.disabled}
                              multiline
                              rowsMax="4"
                              className={classes.textField}
                              value={this.state.textFields ? this.state.textFields[textField.description] : ''}
                              onChange={(event) => {
                                this.props.answerChanged(event.target.value, textField.description);
                                this.setState({"textFields": {
                                  ...this.state.textFields,
                                  [textField.description]: event.target.value
                                }});
                              }}
                              margin="normal"
                            />
                          )
                        }
                      })
                    }
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'CURRENCY' ? 
                (
                  <div>
                    <TextField
                      variant="filled"
                      disabled={props.disabled}
                      className={classes.textField}
                      value={this.state.number ? numeral(this.state.number).format('$0,0') : ''}
                      onChange={(event) => {
                        const value = numeral(event.target.value).value();
                        this.props.answerChanged(value);
                        this.setState({number: value})
                      }}
                      margin="normal"
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'TEXT' ? 
                (
                  <div>
                    <TextField
                      variant="filled"
                      disabled={props.disabled}
                      multiline
                      rowsMax="4"
                      className={classes.textField}
                      value={this.state.text ? this.state.text : ''}
                      onChange={(event) => {
                        this.props.answerChanged(event.target.value);
                        this.setState({text: event.target.value})
                      }}
                      margin="normal"
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'NUMBER' ? 
                (
                  <div>
                    <TextField
                      variant="filled"
                      disabled={props.disabled}
                      multiline
                      type="number"
                      className={classes.textField}
                      value={this.state.number ? this.state.number : ''}
                      onChange={(event) => {
                        var value = numeral(event.target.value).value();
                        if (event.target.value === '') {
                          value = '';
                        }
                        this.props.answerChanged(value);
                        this.setState({number: value})
                      }}
                      margin="normal"
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'DATE' ? 
                (
                  <div>
                    <TextField
                      id="date"
                      variant="filled"
                      disabled={props.disabled}
                      type="date"
                      style={{maxWidth:250}}
                      className={classes.textField}
                      defaultValue={this.state.date}
                      value={this.state.date ? this.state.date : null}
                      onChange={(event) => {
                        console.log('event.target.value', event.target.value);
                        this.setState({date: event.target.value});
                        this.props.answerChanged(event.target.value)
                      }}
                      margin="normal"
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'BOOLEAN' ? 
                (
                  <div>
                    <SelectBool
                      disabled={props.disabled}
                      optionSelected={(selected) => this.props.answerChanged(selected)}
                      initialValue={props.initialValue}
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'LIST' ? 
                (
                  <div>
                    <SelectMenu
                      disabled={props.disabled}
                      options={props.answers}
                      initialValue={props.initialValue}
                      optionSelected={(index, option) => this.props.answerChanged(option)}
                      title="Select"
                    />
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              {
                this.props.questionType === 'CHECK_BOXES' ? 
                (
                  <div>
                    <Button
                      color="primary"
                      style={{marginLeft:-20, marginTop:15}} 
                      onClick={() => {
                        if (props.disabled !== true) {
                          this.setState({open: true})
                        }
                      }}>
                      Selected Options ({this.state.selectedItems? this.state.selectedItems.length : 0})
                    </Button>
                    <MultiSelect
                      showIDK={this.props.showIDK}
                      initSelectedItems={props.initialValue}
                      open={this.state.open}
                      closeModal={(selectedItems => {
                        this.props.answerChanged(selectedItems);
                        this.setState({
                          open: false,
                          selectedItems: selectedItems
                        });
                      })}
                      title="Select"
                      options={props.answers}
                    />
                    <Typography style={{width: "50%"}}>
                      {state.selectedItems ? state.selectedItems.join(", ") : null}
                    </Typography>
                  </div>
                )
                :
                (
                  <div/>
                )
              }
              <Divider style={{marginTop:40}}/>
            </div>
          )
        }
      </div>
    );
  }
}

RFPQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  answers: PropTypes.array,
  questionType: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
  required: PropTypes.string,
  answerChanged: PropTypes.func,
  descriptionChanged: PropTypes.func,
  role: PropTypes.string.isRequired
};

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '80%'
  }
});

export default withRoot(withStyles(styles)(RFPQuestion));