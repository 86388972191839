import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';

class OpenRFPs extends React.Component {

  constructor() {
    super();
    this.state = {
      sort: 'desc'
    };
  }

  componentDidMount(){
    this.props.getRFPS(this.props, this.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile === null && this.props.profile !== null) {
      this.props.getRFPS(this.props, this.state);
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (
      (props.client_rfps && props.client_rfps.length > 0) || 
      (props.agency_rfps && props.agency_rfps.length > 0)
    ) {
      return (
        <div>
          <Typography variant="headline" style={{margin:30}}>
            Available Open RFPs:
          </Typography>
          <Typography variant="subheading" style={{marginLeft:55}}>
            Close Date
          </Typography>
          {
            state.sort === 'desc' ? 
            (
              <Button 
                style={{marginLeft:60}}
                onClick={() => {
                  this.setState({sort: 'asc'});
                  props.sortRFP(props, state.sort);
                }}>
                <MdKeyboardArrowDown size={30}/>
              </Button>
            )
            :
            (
              <Button 
                style={{marginLeft:60}}
                onClick={() => {
                  this.setState({sort: 'desc'});
                  props.sortRFP(props, state.sort);
                }}>
                <MdKeyboardArrowUp size={30}/>
              </Button>
            )
          }
          {props.getViews(props)}
        </div>
      );
    }
    else if (props.client_rfps && props.client_rfps.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography 
            variant="display1"
            style={{marginTop:200}}>
            You currently have no RFPs
          </Typography>
          <Button
            onClick={() => props.history.push('/edit-rfp')}
            style={{
              fontSize: 20,
              textTransform: 'none'
            }} 
            color="secondary">
            Start your first one now
          </Button>
        </div>
      );
    }
    else if (props.agency_rfps && props.agency_rfps.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography 
            variant="display1"
            style={{marginTop:200}}>
            You currently have no matches
          </Typography>
        </div>
      );
    }
    else {
      return (<div/>);
    }
  }
}

OpenRFPs.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(OpenRFPs));