import * as React from 'react';
import { connect } from 'react-redux';
import ClientDraftRFPs from './ClientDraftRFPsComponent';
import { GetClientDraftRFPs, GetClientDashboard, PublishRFP } from './../util/requests';
import { loading, simpleAlert, setClientHeader} from './../reducers/displayActions';
import { setClientDraftRFPs, setClientDashboard } from './../reducers/rfpActions';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {
    rfps: state.rfpReducer.client_draft_rfps,
    clientDashboard: state.rfpReducer.client_dashboard
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRFPS: (props) => {
      dispatch(loading(true));
      dispatch(GetClientDraftRFPs())
      .then(res => {
        dispatch(setClientDraftRFPs(res.value.data.rfps));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log("GetClientDraftRFPs err", err);
      });

      dispatch(GetClientDashboard())
      .then(res => {
        dispatch(setClientDashboard(res.value.data));
      })
      .catch(err => {
        console.log("GetClientDashboard err", err);
      });
    },
    publishRFP: (props, rfpId) => {
      dispatch(loading(true));
      dispatch(PublishRFP({rfp_id: rfpId}))
      .then(res => {
        if (res) {
          dispatch(simpleAlert({
            title: 'Success',
            body: 'Your RFP has been published'
          }));
        }
        dispatch(loading(false));
        props.history.push('/', {reloadData: true});
        dispatch(setClientHeader('OPEN'));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('Update RFP err', err);
        if (
          err.response && err.response.data && 
          err.response.data && err.response.data.display_message
        ) {
          dispatch(simpleAlert({
            title:'Error',
            body: err.response.data.display_message
          }));
        }
        else {
          dispatch(simpleAlert({
            title:'Error',
            body: 'There was an error publishing this RFP. Please try again later.'
          }));
        }
      });
    },
    getViews: (props, setState) => {
      var views = [];
      props.rfps.forEach((rfp, index) => {
        views.push(
          <div style={{margin:"30 30 30 60"}}>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Typography variant="title">
                  {index + 1}. {rfp.title} - 
                  <Button 
                    color="secondary"
                    onClick={() => props.history.push('/edit-rfp', {rfp_id: rfp.id})}>
                    edit
                  </Button>
                </Typography>
                <Typography variant="body" style={{marginLeft:27, marginTop:10}}>
                  Date Created: {moment(rfp.opened_date).format('M/D/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setState({
                      rfpId: rfp.id,
                      publishDialogOpened: true
                    })
                  }}>
                  Publish Now
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      })
      return views;
    }
  }
}

const ClientDraftRFPsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDraftRFPs);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ClientDraftRFPsContainer;