import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import {MdClose} from 'react-icons/md';

class MultiSelect extends React.Component {

  constructor() {
    super();
    this.state = {
      selected_items: null,
      checked_box: false
    };
  }

  componentDidMount(){
    
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props; 

    if (this.props.initSelectedItems && this.state.selected_items === null){
      var selectedItems = {};
      this.props.initSelectedItems.forEach(item => {
        selectedItems[item] = true;
      });
      console.log('selectedItems', selectedItems);
      this.setState({selected_items: selectedItems});
    }

    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={() => closeModal(state, props)}
          style={{

          }}>
          <Grid container spacing={24} 
            style={{
              height: '100%',
              padding: 100
            }}>
            <Grid item md={3}/>
            <Grid item xs={12} md={6}>
              <Paper 
                style={{

                }}>
                <div style={{textAlign:'right'}}>
                  <Button
                    onClick={() => closeModal(state, props)}>
                    <MdClose color="gray" size={30}/>
                  </Button>
                </div>
                <div
                 style={{
                  margin: 20,
                  overflow: 'auto',
                  height: '100%'
                 }}>
                  <FormControl 
                    component="fieldset" 
                    style={{

                    }}>
                    <FormLabel component="legend">{this.props.title}</FormLabel>
                    <FormGroup
                      style={{
                        
                      }}>
                      {getCheckBoxes(this.props.options, this.setState.bind(this), this.state)}
                    </FormGroup>
                  </FormControl>
                </div>
              </Paper>
              <Grid item md={3}/>
            </Grid>
          </Grid>
        </Modal>
      </div>
    );
  }
}

const closeModal = (state, props) => {
  var selectedOptions = [];
  if (state.selected_items) {
    Object.keys(state.selected_items).forEach(option => {
      if (state.selected_items[option] === true){
        selectedOptions.push(option);
      }
    })
  }
  props.closeModal(selectedOptions);
}

const getCheckBoxes = (options, setState, state) => {
  var checkBoxes = [];
  options.forEach(option => {
    checkBoxes.push(
      <FormControlLabel
        control={
          <Checkbox
            checked={state.selected_items && state.selected_items[option]}
            onChange={(event) => {
              setState({
                selected_items: {
                  ...state.selected_items,
                  [option]: event.target.checked,
                },
                checked_box: false
              });
            }}
            value={option}
          />
        }
        label={option}
      />
    )
  });
  return checkBoxes;
}

MultiSelect.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    display: 'flex',
  }
});

export default withRoot(withStyles(styles)(MultiSelect));