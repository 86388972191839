import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MdEmail } from 'react-icons/md';
import { MdReplay  } from 'react-icons/md';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';

class ClientClosedRFP extends React.Component {

  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount(){

  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    return (
      <div style={{margin:50}}>
        <Grid container>
          <Grid item md={2}>
            <Typography variant="body">
              {moment(props.closes_on_date).format('M/D/YYYY')}
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Button
              style={{
                padding: 0, 
                margin: 0, 
                minWidth: 0,
                minHeight: 0,
                fontSize: 20,
                textTransform: 'none'
              }} 
              onClick={() => {
                props.history.push('/marketer-view-rfp', {
                  mode: 'VIEW',
                  rfp_id: props.id
                })
              }}
              color="secondary">
              {props.title}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2}/>
          <Grid item md={3}>
            <Typography variant="body">
              Opened On: {moment(props.opened_date).format('M/D/YYYY')}
            </Typography>
            {
              props.awarded_date ?
              (
                <Typography variant="body">
                  Awarded On: {moment(props.awarded_date).format('M/D/YYYY')}
                </Typography>
              )
              :
              (
                <div/>
              )
            }
            <Typography variant="body">
              Closes On: {moment(props.closes_on_date).format('M/D/YYYY')}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body">
              Viewed By: {props.views}
            </Typography>
            <Typography variant="body">
              Responses: {props.response_count}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body">
              Status: {props.status}
            </Typography>
            {
              props.awarded_agency ?
              (
                <Button
                  style={{
                    padding: 0, 
                    margin: 0, 
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 17,
                    textTransform: 'none'
                  }} 
                  onClick={() => {
                    this.props.showAgencyView(props);
                  }}
                  color="secondary">
                  {props.awarded_agency.agency_name}
                </Button>
              ) 
              :
              (
                <div/>
              )
            }
          </Grid>
          <Grid item md={2}>
            {
              props.status === 'AWARDED' && props.mode !== 'AGENCY_VIEW' ?
              (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  {/* <Typography variant="body">
                    Clone RFP
                  </Typography>
                  <Button>
                    <MdReplay size={30}/>
                  </Button> */}
                </div>
              )
              :
              (
                <div/>
              )
            }
            {/* {
              (props.status === 'PUBLISHED' || props.status === 'DRAFT') && props.mode !== 'AGENCY_VIEW' ?
              (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Typography variant="body">
                    Reopen RFP 
                  </Typography>
                  <Button>
                    <MdReplay size={30}/>
                  </Button>
                </div>
              )
              :
              (
                <div/>
              )
            } */}
          </Grid>
        </Grid>
        <Divider 
          style={{
            marginTop:60, 
            marginBottom:20, 
            marginLeft:100, 
            marginRight:100, 
            backgroundColor: grey[300]
          }}
        />
      </div>
    );
  }
}

ClientClosedRFP.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  opened_date: PropTypes.string.isRequired,
  closes_on_date: PropTypes.string.isRequired,
  response_count: PropTypes.number.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

export default withRoot(withStyles(styles)(ClientClosedRFP));