import React from 'react';
import SelectMenu from './SelectMenuComponent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import TextField from '@material-ui/core/TextField';
import SelectBool from './SelectBoolComponent';
import Input from '@material-ui/core/Input';
import { colors } from './../design';
import numeral from 'numeral';

class EditClientProfile extends React.Component {

  constructor(){
    super();
    this.state = {

    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.profile && props.profile.type === 'AGENCY') {
      props.history.push('edit-agency-profile');
    }

    return (
      <div style={{margin:40}}>
        {this.props.profile ? null :null}
        <Typography className={classes.typography} variant="display1" align='left' gutterBottom={false}>
          Edit Profile
        </Typography>
        <Button
          color="primary"
          variant='contained'
          style={{marginTop:30, width:200}}
          onClick={() => this.props.saveProfile(this.props, this.state)}>
          Save Profile
        </Button>
        <div style={{marginTop:20, marginBottom:20}}>
        {
          this.state.profile_image ? 
          (
            <img
              style={{width:200, height:200}}
              src={this.state.profile_image}
            />
          )
          :
          (
            <div>
            {
              props.profile && props.profile.profile_image ? 
              (
                <img
                  style={{width:200, height:200}}
                  src={props.profile.profile_image}
                />
              )
              :
              (
                <img 
                  src={require('./../assets/profile-icon.png')} 
                  width="200" 
                  height="200" 
                  style={{
                    padding: 10,
                    backgroundColor:'white', 
                    borderRadius:5
                  }}
                />
              )
            }
            </div>
          )
        }
        </div>
        <div>
          <Input 
            type="file"
            onChange={(event) => {
              var file = event.target.files[0];
              var imageType = file.type.replace('image/', '');
              if (!file.type.match('image.*')){
                return;
              }
              var reader = new FileReader();
              reader.onload = (file) => {
                this.setState({
                  profile_image: file.target.result,
                  profile_image_type: imageType
                })
              };
              reader.readAsDataURL(file);
            }}
          />
        </div>
        <Typography variant="body2" align='left' style={{marginTop:10}}>
          Recommended image size 250 x 250
        </Typography>
        <div
          style={{
            margin: "0 -40 0 -40",
            paddingLeft: 40,
            backgroundColor: colors.lightAccent
          }}>
          <Typography 
            className={classes.selectTitle} 
            variant="headline"
            lign='left' 
            gutterBottom={false}>
            Company Background
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Accounting",
                  "Marketing",
                  "Administrative",
                  "Arts and Design",
                  "Business Development",
                  "Community and Social Services",
                  "Consulting",
                  "Education",
                  "Engineering",
                  "Entrepreneurship",
                  "Finance",
                  "Healthcare Services",
                  "Human Resources",
                  "Information Technology",
                  "Legal",
                  "Media and Communication",
                  "Military and Protective Services",
                  "Operations",
                  "Product Management",
                  "Program and Project Management",
                  "Purchasing",
                  "Quality Assurance",
                  "Real Estate",
                  "Research",
                  "Sales",
                  "Support"
                ]
              }
              initialValue={getPropertyValue('job_function', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({job_function: option});
              }}
              title="* Job Function"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "CXO",
                  "Partner",
                  "Owner",
                  "VP",
                  "Director",
                  "Manager",
                  "Senior",
                  "Entry",
                  "Training"
                ]
              }
              initialValue={getPropertyValue('seniority_level', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({seniority_level: option});
              }}
              title="Seniority Level"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Public Company",
                  "Privately Held",
                  "Non Profit",
                  "Educational Institution",
                  "Partnership",
                  "Self Employed",
                  "Self Owned",
                  "Government Agency"
                ]
              }
              initialValue={getPropertyValue('company_type', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({company_type: option});
              }}
              title="* What's your company type?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Accounting",
                  "Airlines/Aviation",
                  "Alternative Dispute Resolution",
                  "Alternative Medicine",
                  "Animation",
                  "Apparel & Fashion",
                  "Architecture & Planning",
                  "Arts and Crafts",
                  "Automotive",
                  "Aviation & Aerospace",
                  "Banking",
                  "Biotechnology",
                  "Broadcast Media",
                  "Building Materials",
                  "Business Supplies and Equipment",
                  "Capital Markets",
                  "Chemicals",
                  "Civic & Social Organization",
                  "Civil Engineering",
                  "Commercial Real Estate",
                  "Computer & Network Security",
                  "Computer Games",
                  "Computer Hardware",
                  "Computer Networking",
                  "Computer Software",
                  "Construction",
                  "Consumer Electronics",
                  "Consumer Goods",
                  "Consumer Services",
                  "Cosmetics",
                  "Dairy",
                  "Defense & Space",
                  "Design",
                  "Education Management",
                  "E-Learning",
                  "Electrical/Electronic Manufacturing",
                  "Entertainment",
                  "Environmental Services",
                  "Events Services",
                  "Executive Office",
                  "Facilities Services",
                  "Farming",
                  "Financial Services",
                  "Fine Art",
                  "Fishery",
                  "Food & Beverages",
                  "Food Production",
                  "Fund-Raising",
                  "Furniture",
                  "Gambling & Casinos",
                  "Glass, Ceramics & Concrete",
                  "Government Administration",
                  "Government Relations",
                  "Graphic Design",
                  "Health, Wellness and Fitness",
                  "Higher Education",
                  "Hospital & Health Care",
                  "Hospitality",
                  "Human Resources",
                  "Import and Export",
                  "Individual & Family Services",
                  "Industrial Automation",
                  "Information Services",
                  "Information Technology and Services",
                  "Insurance",
                  "International Affairs",
                  "International Trade and Development",
                  "Internet",
                  "Investment Banking",
                  "Investment Management",
                  "Judiciary",
                  "Law Enforcement",
                  "Law Practice",
                  "Legal Services",
                  "Legislative Office",
                  "Leisure, Travel & Tourism",
                  "Libraries",
                  "Logistics and Supply Chain",
                  "Luxury Goods & Jewelry",
                  "Machinery",
                  "Management Consulting",
                  "Maritime",
                  "Marketing and Advertising",
                  "Market Research",
                  "Mechanical or Industrial Engineering",
                  "Media Production",
                  "Medical Devices",
                  "Medical Practice",
                  "Mental Health Care",
                  "Military",
                  "Mining & Metals",
                  "Motion Pictures and Film",
                  "Museums and Institutions",
                  "Music",
                  "Nanotechnology",
                  "Newspapers",
                  "Nonprofit Organization Management",
                  "Oil & Energy",
                  "Online Media",
                  "Outsourcing/Offshoring",
                  "Package/Freight Delivery",
                  "Packaging and Containers",
                  "Paper & Forest Products",
                  "Performing Arts",
                  "Pharmaceuticals",
                  "Philanthropy",
                  "Photography",
                  "Plastics",
                  "Political Organization",
                  "Primary/Secondary Education",
                  "Printing",
                  "Professional Training & Coaching",
                  "Program Development",
                  "Public Policy",
                  "Public Relations and Communications",
                  "Public Safety",
                  "Publishing",
                  "Railroad Manufacture",
                  "Ranching",
                  "Real Estate",
                  "Recreational Facilities and Services",
                  "Religious Institutions",
                  "Renewables & Environment",
                  "Research",
                  "Restaurants",
                  "Retail",
                  "Security and Investigations",
                  "Semiconductors",
                  "Shipbuilding",
                  "Sporting Goods",
                  "Sports",
                  "Staffing and Recruiting",
                  "Supermarkets",
                  "Telecommunications",
                  "Textiles",
                  "Think Tanks",
                  "Tobacco",
                  "Translation and Localization",
                  "Transportation/Trucking/Railroad",
                  "Utilities",
                  "Venture Capital & Private Equity",
                  "Veterinary",
                  "Warehousing",
                  "Wholesale",
                  "Wine and Spirits",
                  "Wireless",
                  "Writing and Editing"
                ]
              }
              initialValue={getPropertyValue('industry_type', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({industry_type: option});
              }}
              title="* What industry are you in?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "1-3", 
                  "4-7", 
                  "8-15", 
                  "16-25", 
                  "26-40", 
                  "41-60", 
                  "61-100", 
                  "100+"
                ]
              }
              initialValue={getPropertyValue('years_in_business', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({years_in_business: option});
              }}
              title="* How many years have you been in business?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "10,000+",
                  "5,001-10,000",
                  "1,001-5,000",
                  "501-1000",
                  "201-500",
                  "51-200",
                  "11-50",
                  "1-10",
                  "Self-employed"
                ]
              }
              initialValue={getPropertyValue('number_of_employees', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({number_of_employees: option});
              }}
              title="* How many employees does your company have?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "0", "1", "2-5", "6-10", "11-20", "21+"
                ]
              }
              initialValue={getPropertyValue('number_of_marketers', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({number_of_marketers: option});
              }}
              title="* How many people are in your marketing department?"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* RFP Creator's Name"
              className={classes.textField}
              value={getPropertyValue('rfp_creator_name', props.profile, state)}
              onChange={(event) => this.setState({rfp_creator_name: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Company Name"
              className={classes.textField}
              value={getPropertyValue('company_name', props.profile, state)}
              onChange={(event) => this.setState({company_name: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Company City"
              className={classes.textField}
              value={getPropertyValue('company_city', props.profile, state)}
              onChange={(event) => this.setState({company_city: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={usStates}
              initialValue={getPropertyValue('company_state', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({company_state: option});
              }}
              title="* Company State"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Company Zip Code"
              helperText="Example: 70119"
              type="number"
              className={classes.textField}
              value={getPropertyValue('company_zip', props.profile, state)}
              onChange={(event) => this.setState({company_zip: event.target.value.substring(0, 9)})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Email Address"
              className={classes.textField}
              value={getPropertyValue('company_email', props.profile, state)}
              onChange={(event) => this.setState({company_email: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Phone Number"
              className={classes.textField}
              value={getPropertyValue('company_phone_number', props.profile, state)}
              onChange={(event) => this.setState({company_phone_number: event.target.value.substring(0, 10)})}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectBool
              initialValue={getPropertyValue('has_outsourced_marketing', props.profile, state)}
              title="* Have you worked with an agency or contractor to outsource marketing services before?"
              optionSelected={(selected) => this.setState({has_outsourced_marketing: selected})}
            />
          </Grid>
        </Grid>
        <div
          style={{
            margin: "0 -40 0 -40",
            paddingLeft: 40,
            backgroundColor: colors.lightAccent
          }}>
          <Typography 
            className={classes.selectTitle} 
            variant="headline" a
            lign='left' 
            gutterBottom={false}>
            Audience
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Days",
                  "Weeks",
                  "Months",
                  "Years"
                ]
              }
              initialValue={getPropertyValue('customer_acquisition_time', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({customer_acquisition_time: option});
              }}
              title="How long does it take you to acquire a new customer (on average)?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              multiline
              label="How do you acquire new customers?"
              className={classes.textField}
              value={getPropertyValue('customer_acquisition_time', props.profile, state)}
              onChange={(event) => this.setState({customer_acquisition_time: event.target.value})}
              margin="normal"
            />            
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              InputLabelProps={{
                style: {
                  marginTop: -15
                }
              }}
              label="How much is a new customer worth to you (average annual value)?"
              fullWidth={true}
              className={classes.textField}
              value={numeral(getPropertyValue('new_customer_worth', props.profile, state)).format('$0,0')}
              onChange={(event) => {
                var value = numeral(event.target.value).value();
                if (value === null) {
                  value = '';
                }
                this.setState({new_customer_worth: value})
              }}
              style={{marginTop:30}}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              InputLabelProps={{
                style: {
                  marginTop: -15
                }
              }}
              label="How much does it cost you to acquire a new customer (on average)?"
              fullWidth={true}
              className={classes.textField}
              value={numeral(getPropertyValue('customer_acquisition_cost', props.profile, state)).format('$0,0')}
              onChange={(event) => {
                var value = numeral(event.target.value).value();
                if (value === null) {
                  value = '';
                }
                this.setState({customer_acquisition_cost: value});
              }}
              style={{marginTop:30}}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Typography 
          className={classes.selectTitle} 
          variant="headline" a
          lign='left' 
          gutterBottom={false}>
          Project Overview
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              style={{width:600}}
              label="* Will internal employees be involved in this project? If yes, please list their titles:"
              className={classes.textField}
              value={getPropertyValue('internal_employee_titles', props.profile, state)}
              onChange={(event) => this.setState({internal_employee_titles: event.target.value})}
              margin="normal"
            />
          </Grid>
        </Grid>
        {/* <Typography 
          className={classes.selectTitle} 
          variant="headline" a
          lign='left' 
          gutterBottom={false}>
          Scope of Work / Deliverables
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              style={{width:450}}
              label="Please describe your needs based on your services selected"
              className={classes.textField}
              value={getPropertyValue('needs_based_on_services', props.profile, state)}
              onChange={(event) => this.setState({needs_based_on_services: event.target.value})}
              margin="normal"
            />
          </Grid>
        </Grid> */}
        <Typography 
          className={classes.selectTitle} 
          variant="headline" a
          lign='left' 
          gutterBottom={false}>
          Criteria for Selection
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Myself", "immediate manager", "company owner", "board of directors"
                ]
              }
              initialValue={getPropertyValue('rpf_judge_name', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({rpf_judge_name: option});
              }}
              title="* Who will decide on the winning proposal?"
            />
          </Grid>
        </Grid>
        
        <Button
          color="primary"
          variant='contained'
          style={{marginTop:30, width:200}}
          onClick={() => this.props.saveProfile(this.props, this.state)}>
          Save Profile
        </Button>
      </div>
    );
  }
}

const getPropertyValue = (prop, propsProfile, state) => {

  const stateValue = state[prop];
  if (stateValue != undefined && stateValue != null) {
    return stateValue;
  }
  else if (propsProfile && propsProfile[prop] != undefined && propsProfile[prop] != null) {
    return propsProfile[prop];
  }
  else {
    return '';
  }
}

const styles = theme => ({
  selectTitle: {
    marginTop: 40
  },
  sectionBody: {
    display: 'flex'
  },
  textField: {
    width:300
  }
});

const usStates = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

EditClientProfile.propTypes = {

};

export default withRoot(withStyles(styles)(EditClientProfile));