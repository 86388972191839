import * as React from 'react';
import { connect } from 'react-redux';
import CompareResponses from './CompareResponsesComponent';
import { setCompareResponses } from './../reducers/rfpActions';
import { 
  GetRFPResponses,
  AwardRFP,
  SetResponseFavoriteStatus,
  DeclineResponse,
  GetRFPClientAnswer
} from './../util/requests';
import { 
  setRFPResponses,
  setLocalResponseFavorite,
  declineRFPResponse
} from './../reducers/rfpActions';
import {
  loading, 
  simpleAlert, 
  setClientHeader
} from './../reducers/displayActions';
import { FaHeart } from 'react-icons/fa';
import moment from 'moment';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    rfpResponses: state.rfpReducer.compare_responses
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    awardRFP: (props, rfp_id, rfp_response_id) => {
      if (rfp_id && rfp_response_id) {
        dispatch(loading(true))
        dispatch(AwardRFP({
          rfp_response_id: rfp_response_id,
          rfp_id: rfp_id
        }))
        .then(res => {
          dispatch(simpleAlert({
            title:'Success',
            body: "The RFP has been awarded"
          }));
          dispatch(loading(false));
          props.history.push('/', {reloadData: true});
          dispatch(setClientHeader('OPEN'));
        })
        .catch(err => {
          dispatch(loading(false));
          if (
            err.response && err.response.data && 
            err.response.data.results && err.response.data.results.display_message
          ) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.results.display_message
            }));
          }
          else if (err.response && err.response.data && err.response.data.display_message) {
            dispatch(simpleAlert({
              title:'Error',
              body: err.response.data.display_message
            }));
          }
          else {
            dispatch(simpleAlert({
              title:'Error',
              body: 'There was an error awarding this RFP'
            }));
          }
          console.log('AwardRFP err', err);
        });
      }
    },
    getRFPResponses: (rfpId, property, setState, props) => {
      dispatch(loading(true));
      dispatch(GetRFPResponses({rfp_id: rfpId}))
      .then(res => {
        var rfpResponses = res.value.data;
        dispatch(GetRFPClientAnswer({
          rfp_id: rfpId,
          property: property
        }))
        .then(res => {
          dispatch(loading(false));
          const servicesNeeded = res.value.data.answer;
          rfpResponses = rfpResponses.map(rfpResponse => {
            var agencyServicesProvidedByProp = {};
            rfpResponse.all_services_provided = true;
            if (rfpResponse.agency_profile && rfpResponse.agency_profile.services_provided) {
              var agencyServicesProvided = rfpResponse.agency_profile.services_provided;
              agencyServicesProvided.forEach(serviceProvided => {
                agencyServicesProvidedByProp[serviceProvided] = true;
              });
            };
            for (let i = 0; i < servicesNeeded.length; i++) {
              const serviceNeeded = servicesNeeded[i];
              if (agencyServicesProvidedByProp[serviceNeeded] === undefined) {
                rfpResponse.all_services_provided = false;
                break;
              }
            }
            return rfpResponse;
          });

          rfpResponses.filter((response) => {
            var count = 0;

            if (response.all_services_provided === true) {
              count++;
            }

            if (response.agency_profile && response.agency_profile.vetted === true) {
              count++;
            }

            response.question_response.forEach((questionRes) => {
              if (
                questionRes.rfp_question_response.answer_type === "BOOLEAN" && 
                questionRes.rfp_question_response.answer === true
              ) {
                count++;
              }
            });
            response.matches = count;;
            return response;
          });

          dispatch(setCompareResponses(rfpResponses.sort((a, b) => {
            return b.matches - a.matches;
          })));
        })
        .catch(err => {
          dispatch(loading(false));
          console.log('GetRFPClientAnswer err', err);
        });
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('GetRFPResponses err', err);
      });
    },
    setSort: (props, templateQuestionId, order) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        var answerA = null;
        var answerB = null;
        for (let i = 0; i < a.question_response.length; i++) {
          const aQuestionResponse = a.question_response[i];
          if (aQuestionResponse.rfp_question_response.template_question_id === templateQuestionId) {
            answerA = aQuestionResponse.rfp_question_response.answer;
            break;
          }
        }
        for (let i = 0; i < b.question_response.length; i++) {
          const aQuestionResponse = b.question_response[i];
          if (aQuestionResponse.rfp_question_response.template_question_id === templateQuestionId) {
            answerB = aQuestionResponse.rfp_question_response.answer;
            break;
          }
        }
        if (answerA === true && (answerB === null || answerB === false)) {
          if (order === 'asc') {
            return -1;
          }
          else {
            return 1;
          }
        }
        else if (answerB === true && (answerA === null || answerA === false)) {
          if (order === 'asc') {
            return 1;
          }
          else {
            return -1;
          }
        }
        else {
          return 0;
        }
      })));
    },
    sortVettedStatus: (props, order) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        var answerA = a.agency_profile.vetted;
        var answerB = b.agency_profile.vetted;
        if (answerA === true && (answerB === null || answerB === false || answerB === undefined)) {
          if (order === 'asc') {
            return -1;
          }
          else {
            return 1;
          }
        }
        else if (answerB === true && (answerA === null || answerA === false || answerA === undefined)) {
          if (order === 'asc') {
            return 1;
          }
          else {
            return -1;
          }
        }
        else {
          return 0;
        }
      })));
    },
    sortByFavorite: (props, order) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        var answerA = a.rfp_response.client_favorite;
        var answerB = b.rfp_response.client_favorite;
        if (answerA === true && (answerB === null || answerB === false || answerB === undefined)) {
          if (order === 'asc') {
            return -1;
          }
          else {
            return 1;
          }
        }
        else if (answerB === true && (answerA === null || answerA === false || answerA === undefined)) {
          if (order === 'asc') {
            return 1;
          }
          else {
            return -1;
          }
        }
        else {
          return 0;
        }
      })));
    },
    sortByAllServicesProvided: (props, order) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        var answerA = a.all_services_provided;
        var answerB = b.all_services_provided;
        if (answerA === true && (answerB === null || answerB === false || answerB === undefined)) {
          if (order === 'asc') {
            return -1;
          }
          else {
            return 1;
          }
        }
        else if (answerB === true && (answerA === null || answerA === false || answerA === undefined)) {
          if (order === 'asc') {
            return 1;
          }
          else {
            return -1;
          }
        }
        else {
          return 0;
        }
      })));
    },
    setFavoriteStatus: (responseId, favorite, setState, state) => {
      dispatch(loading(true));
      dispatch(SetResponseFavoriteStatus({rfp_response_id: responseId, favorite: favorite}))
      .then(res => {
        dispatch(loading(false));
        setState({favorites: {
          ...state.favorites,
          [responseId]: true
        }});
        dispatch(setLocalResponseFavorite({
          responseId: responseId,
          favorite: favorite
        }));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('SetFavoriteStatus err', err);
      });
    },
    sortByMatches: (props) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        return b.matches - a.matches;
      })));
    },
    declineResponse: (props, responseId) => {
      dispatch(loading(true));
      dispatch(DeclineResponse({rfp_response_id: responseId}))
      .then(res => {
        dispatch(loading(false));
        dispatch(declineRFPResponse(responseId));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('DeclineResponse err', err);
        dispatch(simpleAlert({
          title: 'Error',
          body: 'Failed to decline this RFP Response'
        }));
      });
    },
    sortMostRecent: (props, order) => {
      dispatch(setCompareResponses(props.rfpResponses.sort((a, b) => {
        var answerA = moment(a.rfp_response.created_at);
        var answerB = moment(b.rfp_response.created_at);
        return answerA.diff(answerB);
      })));
    },
  }
  
}

const CompareResponsesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareResponses);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default CompareResponsesContainer;