import { combineReducers } from 'redux'
import displayReducer from './reducers/displayReducer';
import userReducer from './reducers/userReducer';
import rfpReducer from './reducers/rfpReducer';
import adminReducer from './reducers/adminReducer';

const rootReducer = combineReducers({
  userReducer,
  displayReducer,
  rfpReducer,
  adminReducer
});

export default rootReducer;
