import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { CSVLink, CSVDownload } from "react-csv";

class AgencyProfiles extends React.Component {

  constructor() {
    super();
    this.state = {
      setStatusOpen: false,
      setActiveOpen: false,
      selectedProfile: {

      },
      dateSort: 'asc',
      agencyNameSort: 'asc',
      statusSort: 'asc',
      csvData: [
        ["Date Added", "Status" ,"Active", "Agency Name", "Agency Address", "Agency Email", "Agency Profile"]
      ]
    };
  }

  componentDidMount(){
    this.props.getAgencyProfiles(this.props, this.setState.bind(this));
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={state.setStatusOpen}
          onClose={() => null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Set Agency Vetting Status</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Is the agency cool?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
             <Button
              onClick={() => {
                this.setState({setStatusOpen: false});
                if (state.selectedProfile.id) {
                  props.setVettedStatus(state.selectedProfile.id, true)
                }
              }}
              color="primary">
              Yes
            </Button>
            <Button
              onClick={() => {
                this.setState({setStatusOpen: false});
                if (state.selectedProfile.id) {
                  props.setVettedStatus(state.selectedProfile.id, false)
                }
              }}
              color="primary">
              No
            </Button>
            <Button
              onClick={() => this.setState({setStatusOpen: false})}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={state.setActiveOpen}
          onClose={() => null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {
            state && state.selectedProfile && state.selectedProfile.is_active === true ?
            (
              <DialogTitle id="alert-dialog-title">
                Would you like to deactivate this account?
              </DialogTitle>
            )
            :
            (
              <DialogTitle id="alert-dialog-title">
                Would you like to activate this account
              </DialogTitle>
            )
          }
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({setActiveOpen: false});
                if (state.selectedProfile.id) {
                  props.setActiveProfileStatus(
                    state.selectedProfile.id,
                    !state.selectedProfile.is_active
                  );
                }
              }}
              color="primary">
              Yes
            </Button>
            <Button
              onClick={() => this.setState({setActiveOpen: false})}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          color="secondary"
          variant="contained"
          style={{
            margin:20
          }}>
          <CSVLink 
            style={{color:'black'}}
            data={state.csvData}
            filename={"agency_export.csv"}
            target="_blank">
            Export Data
          </CSVLink>
        </Button>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div style={{display: 'flex'}}>
                    Data Added
                    <Button className={classes.sortButton}
                      onClick={() => {
                        if (state.dateSort === 'asc') {
                          this.setState({dateSort: 'desc'});
                          props.setSort(
                            props,
                            'desc',
                            null,
                            null
                          );
                        }
                        else {
                          this.setState({dateSort: 'asc'});
                          props.setSort(
                            props,
                            'asc',
                            null,
                            null
                          );
                        }
                      }}>
                      {
                        state.dateSort === 'asc' ? 
                        (
                          <MdKeyboardArrowDown size={30}/>
                        )
                        :
                        (
                          <MdKeyboardArrowUp size={30}/>
                        )
                      }
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{display: 'flex'}}>
                    Status
                    <Button className={classes.sortButton}
                      onClick={() => {
                        if (state.statusSort === 'asc') {
                          this.setState({statusSort: 'desc'});
                          props.setSort(
                            props,
                            null,
                            'desc',
                            null
                          );
                        }
                        else {
                          this.setState({statusSort: 'asc'});
                          props.setSort(
                            props,
                            null,
                            'asc',
                            null
                          );
                        }
                      }}>
                      {
                        state.statusSort === 'asc' ? 
                        (
                          <MdKeyboardArrowDown size={30}/>
                        )
                        :
                        (
                          <MdKeyboardArrowUp size={30}/>
                        )
                      }
                    </Button>
                  </div>
                </TableCell>
                <TableCell>Active</TableCell>
                <TableCell>
                  <div style={{display: 'flex'}}>
                    Agency Name
                    <Button className={classes.sortButton}
                      onClick={() => {
                        if (state.agencyNameSort === 'asc') {
                          this.setState({agencyNameSort: 'desc'});
                          props.setSort(
                            props,
                            null,
                            null,
                            'desc'
                          );
                        }
                        else {
                          this.setState({agencyNameSort: 'asc'});
                          props.setSort(
                            props,
                            null,
                            null,
                            'asc'
                          );
                        }
                      }}>
                      {
                        state.agencyNameSort === 'asc' ? 
                        (
                          <MdKeyboardArrowDown size={30}/>
                        )
                        :
                        (
                          <MdKeyboardArrowUp size={30}/>
                        )
                      }
                    </Button>
                  </div>
                </TableCell>
                <TableCell>Agency Address</TableCell>
                <TableCell>Agency Email</TableCell>
                <TableCell>Agency Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.getTableCells(props, this.setState.bind(this))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

AgencyProfiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  sortButton: {
    margin: "-5 0 0 10",
    padding: 0,
    minHeight: 0,
    minWidth: 0
  }
});

export default withRoot(withStyles(styles)(AgencyProfiles));