import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {MdSettings} from 'react-icons/md';
import {MdAddCircle} from 'react-icons/md';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import { colors } from './../design';

const styles = {

};

class NavBar extends React.Component {

  constructor(){
    super();
    this.state = {
      anchorEl: null,
      eventDashboardPressed: false
    };
  }

  handleChange(event, checked){

  };

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose() {
    this.setState({ anchorEl: null });
  };

  render() {

    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);

      return (
        <div className={classes.root}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <Button
                color="inherit"
                component={Link}
                to="/">
                <img src={require('./../assets/pitchless-logo-blue.png')} 
                  style={{
                    marginTop:10,
                    maxWidth: 130
                  }}/>
              </Button>
              <div style={{position:'absolute', right:20}}>
                {
                  this.props.profile && this.props.profile.type === 'CLIENT' ?
                  (
                    <Button
                      color="inherit"
                      component={Link}
                      to="/edit-rfp"
                      style={{color: colors.lightAccent}}>
                      <img 
                        src={require('./../assets/new-rfp-icon.png')} 
                        style={{
                          maxWidth:50}}
                      />
                    </Button>
                  )
                  :
                  (
                    <div/>
                  )
                }
                <IconButton
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={(a,b) => this.handleMenu(a,b)}
                  style={{color: colors.lightAccent}}>
                  <img 
                    src={require('./../assets/profile-icon.png')} 
                    style={{maxWidth:35}}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={() => this.handleClose()}>
                  {
                    this.props.profile && this.props.profile.type !== "ADMIN" ?
                    (
                      <MenuItem
                        component={Link}
                        to="/edit-profile"
                        onClick={() => this.setState({anchorEl: null})}>
                        Profile
                      </MenuItem>
                    )
                    :
                    (
                      <div/>
                    )
                  }
                  <MenuItem onClick={() => {
                      this.setState({anchorEl: null});
                      this.props.logout();
                    }}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      );

  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(NavBar));
