import React from 'react';
import { connect } from 'react-redux';
import SimpleAlert from './SimpleAlertComponent';
import { simpleAlert } from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.displayReducer.simple_alert_title,
    body: state.displayReducer.simple_alert_body,
    open: state.displayReducer.simple_alert_open
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close: () => {
      dispatch(simpleAlert({
        title: '',
        body: '',
        open: false
      }));
    },
    onClose: () => {

    }
  }
}

const SimpleAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {pure:false}
)(SimpleAlert);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default SimpleAlertContainer;
