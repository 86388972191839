import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class ClientDraftRFPs extends React.Component {

  constructor() {
    super();
    this.state = {
      publishDialogOpened: false,
      rfpId: null
    };
  }

  componentDidMount(){
    this.props.getRFPS(this.props, this.state);
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.rfps) {
      if (props.rfps.length > 0) {
        return (
          <div>
            <Dialog
              open={this.state.publishDialogOpened}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"Publish RFP"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={() => this.setState({publishDialogOpened: false, rfpId: null})} 
                  color="primary">
                  No
                </Button>
                <Button 
                  onClick={() => {
                    props.publishRFP(props, state.rfpId);
                    this.setState({publishDialogOpened: false, rfpId: null});
                  }} 
                  color="primary" 
                  autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Typography variant="headline" style={{margin:30}}>
              Your Draft RFPs:
            </Typography>
            {props.getViews(props, this.setState.bind(this))}
          </div>
        );
      }
      else {
        return (
          <div>

          </div>
        );
      }
    }
    else {
      return (<div/>);
    }
  }
}

ClientDraftRFPs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  
});

export default withRoot(withStyles(styles)(ClientDraftRFPs));