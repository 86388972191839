import * as React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import AppRouter from './components/AppRouterContainer';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import withRoot from './withRoot';

const theme = createMuiTheme({

});


const styles = theme => ({
  root: {
    textAlign: 'center'
  },
});


class App extends React.Component {

  render(){

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <AppRouter {...this.props}/>
        </MuiThemeProvider>
      </Provider>
    )
  }
}
export default withRoot(withStyles(styles)(App));
