import * as React from 'react';
import { connect } from 'react-redux';
import ViewAgency from './ViewAgencyComponent';
import { 
  GetAgencyRFPResponse
} from './../util/requests';
import {loading, simpleAlert} from './../reducers/displayActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
 return {

  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAgencyResponse: (profileId, rfpId, setState) => {
      dispatch(loading(true));
      dispatch(GetAgencyRFPResponse({
        rfp_id: rfpId,
        agency_profile_id: profileId
      }))
      .then(res => {
        setState({
          viewResponseIsOpen: true,
          rfp: res.value.data[0],
          rfp_id: rfpId
        });
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));
        console.log('GetAgencyRFPResponse err', err);
      })
    }
  }
}

const ViewAgencyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAgency);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default ViewAgencyContainer;