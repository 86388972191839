import * as React from 'react';
import { connect } from 'react-redux';
import AppRouter from './AppRouterComponent';
import { setIsAuthenticated, setProfile } from './../reducers/userActions';
import { loading, simpleAlert } from './../reducers/displayActions';
import { LoadEnvironment } from './../util/persist';
import { GetUserProfile, GetClientDashboard, GetAgencyDashboard } from './../util/requests';
import { setClientDashboard, setAgencyDashboard } from './../reducers/rfpActions';

// =============================================================================
// MAP STATE TO PROPS
// =============================================================================

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.userReducer.is_authenticated,
    profile: state.userReducer.profile,
    loading: state.displayReducer.loading,
    clientDashboard: state.rfpReducer.client_dashboard,
    agencyDashboard: state.rfpReducer.agency_dashboard,
  }
}

// =============================================================================
// MAP DISPATCH TO PROPS
// =============================================================================

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    checkAuth: (props) => {
      const env = LoadEnvironment();
      if (env && env.bs_api_token) {
        dispatch(setIsAuthenticated(true));
        if (props.profile === null) {

          dispatch(GetUserProfile())
          .then(res => {
            dispatch(setProfile(res.value.data));
            const profile = res.value.data;
            if (
              profile.type === 'AGENCY' && 
              (profile.payment_source === undefined || profile.payment_source === null)
            ) {
              dispatch(simpleAlert({
                title: 'Missing Payment Information',
                body: 'Please enter a payment source in Edit Profile'
              }));
            }

            if (profile.type === 'AGENCY') {
              dispatch(GetAgencyDashboard())
              .then(res => {
                dispatch(setAgencyDashboard(res.value.data));
              })
              .catch(err => {
                console.log("GetAgencyDashboard err", err);
              })
            }

            if (profile.type === 'CLIENT') {
              dispatch(GetClientDashboard())
              .then(res => {
                dispatch(setClientDashboard(res.value.data));
              })
              .catch(err => {
                console.log("GetClientDashboard err", err);
              });
            }
          })
          .catch(err => {
            console.log('GetUserProfile err', err);
          })
        }
      }
      else {
        // dispatch(setIsAuthenticated(false));
      }
    }
  }
}

const AppRouterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {pure:false}
)(AppRouter);

// =============================================================================
// HELPER FUNCTIONS
// =============================================================================

export default AppRouterContainer;
