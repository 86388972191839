import React from 'react';
import SelectMenu from './SelectMenuComponent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import TextField from '@material-ui/core/TextField';
import SelectBool from './SelectBoolComponent';
import MultiSelect from './MultiSelectComponent';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { colors } from './../design';

const stripe = Stripe('pk_test_xlX7kvrqSIIH39C4y6jvYR4E');
const elements = stripe.elements();
const style = {
  base: {
    fontSize: '16px',
    color: "#32325d",
  },
};
const card = elements.create('card', {style});

class EditAgencyProfile extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      selectedServicesProvided: null,
      showSetPaymentSource: 'hidden'
    };
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.profile && prevProps.profile === null) {
      if (this.props.profile.type != 'UNDEFINED') {
        this.setState({showSetPaymentSource: 'visible'})
      }
    }

    if (this.props.profile && prevProps.profile) {
      if (this.props.profile.type !== 'UNDEFINED' && prevProps.profile.type === 'UNDEFINED') {
        this.setState({showSetPaymentSource: 'visible'})
      }
    }
  }

  componentDidMount(){

    card.mount('#card-element');
    var x = document.getElementById('card-element');

    card.addEventListener('change', ({error}) => {
      const displayError = document.getElementById('card-errors');
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
    });

    if (this.props.profile && this.props.profile.type != 'UNDEFINED') {
      this.setState({showSetPaymentSource: 'visible'})
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    var paymentSource = null;
    if (props.profile && props.profile.stripe_customer) {
      const stripeCust = props.profile.stripe_customer;
      if (stripeCust.sources && stripeCust.sources.total_count === 1) {
        paymentSource  = stripeCust.sources.data[0];
      }
    }

    if (props.profile && props.profile.type === 'CLIENT') {
      props.history.push('edit-marketer-profile');
    }

    if (props.profile && props.profile.services_provided && state.selectedServicesProvided === null) {
      this.setState({selectedServicesProvided: props.profile.services_provided});
    }

    return (
      <div style={{margin:40}}>
        {this.props.profile ? null :null}
        <Typography className={classes.typography} variant="display1" align='left' gutterBottom={false}>
          Edit Profile
        </Typography>

        <div style={{marginTop:20, marginBottom:20}}>
        {
          this.state.profile_image ? 
          (
            <img
              style={{width:200, height:200}}
              src={this.state.profile_image}
            />
          )
          :
          (
            <div>
            {
              props.profile && props.profile.profile_image ? 
              (
                <img
                  style={{width:200, height:200}}
                  src={props.profile.profile_image}
                />
              )
              :
              (
                <img 
                  src={require('./../assets/profile-icon.png')} 
                  width="200" 
                  height="200" 
                  style={{
                    padding: 10,
                    backgroundColor:'white', 
                    borderRadius:5
                  }}
                />
              )
            }
            </div>
          )
        }
        </div>
        <div>
          <Input 
            type="file"
            onChange={(event) => {
              var file = event.target.files[0];
              var imageType = file.type.replace('image/', '');
              if (!file.type.match('image.*')){
                return;
              }
              var reader = new FileReader();
              reader.onload = (file) => {
                this.setState({
                  profile_image: file.target.result,
                  profile_image_type: imageType
                })
              };
              reader.readAsDataURL(file);
            }}
          />
        </div>
        <Typography variant="body2" align='left' style={{marginTop:10}}>
          Recommended image size 250 x 250
        </Typography>
        <div 
          style={{
            margin: "0 -40 0 -40",
            paddingLeft: 40,
            backgroundColor: colors.lightAccent
          }}>
          <Typography 
            className={classes.selectTitle} 
            variant="headline"
            lign='left' 
            gutterBottom={false}>
            Company Background
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "1-3", "4-7", "8-15", "16-25", "26-40", "41-60", "61-100", "100+"
                ]
              }
              initialValue={getPropertyValue('years_in_business', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({years_in_business: option});
              }}
              title="* How many years have you been in business?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "10,000+",
                  "5001-10,000",
                  "1001-5000",
                  "501-1000",
                  "201-500",
                  "51-200",
                  "11-50",
                  "1-10",
                  "Self-employed"
                ]
              }
              initialValue={getPropertyValue('number_of_employees', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({number_of_employees: option});
              }}
              title="* How many employees does your service provider have?"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "Accounting", "Marketing", "Administrative", "Arts and Design", "Business Development", "Community and Social Services", "Consulting", "Education", "Engineering", "Entrepreneurship", "Finance", "Healthcare Services", "Human Resources", "Information Technology", "Legal", "Media and Communication", "Military and Protective Services", "Operations", "Product Management", "Program and Project Management", "Purchasing", "Quality Assurance", "Real Estate", "Research", "Sales", "Support"
                ]
              }
              initialValue={getPropertyValue('job_function', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({job_function: option});
              }}
              title="Job Function"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Service Provider Name"
              className={classes.textField}
              value={getPropertyValue('agency_name', props.profile, state)}
              onChange={(event) => this.setState({agency_name: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Service Provider City"
              className={classes.textField}
              value={getPropertyValue('agency_city', props.profile, state)}
              onChange={(event) => this.setState({agency_city: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={usStates}
              initialValue={getPropertyValue('agency_state', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({agency_state: option});
              }}
              title="* Service Provider State"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="* Service Provider Email Address"
              className={classes.textField}
              value={getPropertyValue('agency_email', props.profile, state)}
              onChange={(event) => this.setState({agency_email: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
            label="* Service Provider Phone Number"
              className={classes.textField}
              value={getPropertyValue('agency_phone_number', props.profile, state)}
              onChange={(event) => this.setState({agency_phone_number: event.target.value})}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="Industry Awards"
              className={classes.textField}
              value={getPropertyValue('industry_awards', props.profile, state)}
              onChange={(event) => this.setState({industry_awards: event.target.value})}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <TextField
              label="Certifications"
              className={classes.textField}
              value={getPropertyValue('certifications', props.profile, state)}
              onChange={(event) => this.setState({certifications: event.target.value})}
              margin="normal"
            />
          </Grid>
        <Typography 
          className={classes.selectTitle} 
          variant="headline" 
          gutterBottom={false}>
          Project Overview
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Button
              style={{marginLeft:-20, marginTop:15}} 
              onClick={() => this.setState({open: true})}>
              * Please select the services you provide ({this.state.selectedServicesProvided? this.state.selectedServicesProvided.length : 0})
            </Button>
            {
              this.state.selectedServicesProvided && this.state.selectedServicesProvided.length > 0 ? 
              <Typography 
                variant="body2"
              >
                {this.state.selectedServicesProvided.join(', ')}
              </Typography>
              : null
            }
            <MultiSelect
              open={this.state.open}
              closeModal={(selectedItems => {
                this.setState({
                  selectedServicesProvided: selectedItems,
                  open: false
                })
              })}
              title="Services"
              initSelectedItems={props.profile ? props.profile.services_provided : []}
              options={[ 
                  'AR VR',
                  'Account Based Marketing',
                  'Advertising',
                  'Advertising Services',
                  'Affilate Marketing',
                  'Animation',
                  'Brand and Product Naming',
                  'Branding',
                  'Broadcast Advertising',
                  'CRM Automation',
                  'Content Creation Services',
                  'Content Marketing',
                  'Copywriting',
                  'Data Analytics',
                  'Design',
                  'Design Strategy',
                  'Digital',
                  'Digital Strategy',
                  'Direct Marketing',
                  'Display Advertising',
                  'E-Commerce',
                  'E-commerce Design',
                  'Email',
                  'Email Marketing',
                  'Environmental',
                  'Events',
                  'Experiential Marketing',
                  'Experiential and Event Marketing',
                  'Gaming',
                  'Graphic / Communication',
                  'Graphic Design',
                  'Healthcare Marketing',
                  'Inbound Marketing',
                  'Industrial',
                  'Influencer Marketing',
                  'Innovation',
                  'Marketing',
                  'Marketing Analytics and Business Intelligence',
                  'Marketing Automation',
                  'Marketing Strategy',
                  'Media',
                  'Media Buying and Planning',
                  'Mobile',
                  'Mobile App Development',
                  'Mobile and App Marketing',
                  'Multicultural Marketing',
                  'Packaging',
                  'Pay-Per-Click (PPC)',
                  'Print Advertising',
                  'Promotions',
                  'Public Relations',
                  'Qualitative Insights',
                  'Quantitative Insights',
                  'Research',
                  'Search',
                  'Search Engine Marketing (SEM)',
                  'Shopper Marketing',
                  'Social Media',
                  'Social Media Marketing',
                  'Technical Marketing Services',
                  'User Experience',
                  'Video Production',
                  'Web',
                  'Website Development',
                  'Word of Mouth (WOMM)' 
              ]}
            />
          </Grid>
        </Grid>`
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectBool
              initialValue={getPropertyValue('can_partner_with_agencies', props.profile, state)}
              title="* Can you work in partnership with other agencies or contractors on projects?"
              optionSelected={(selected) => this.setState({can_partner_with_agencies: selected})}
            />
          </Grid>
        </Grid>
        <Typography 
          className={classes.selectTitle} 
          variant="headline" 
          align='left' 
          gutterBottom={false}>
          Timeline
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <SelectMenu
              options={
                [
                  "One-time", "ongoing", "both"
                ]
              }
              initialValue={getPropertyValue('project_freq_pref', props.profile, state)}
              optionSelected={(index, option) => {
                this.setState({project_freq_pref: option});
              }}
              title="* Do you prefer to work on one-time projects or in an ongoing retainer arrangement or both?"
            />
          </Grid>
        </Grid>
        <Typography 
          className={classes.selectTitle} 
          variant="headline" 
          align='left' 
          gutterBottom={false}>
          Payment Information
        </Typography>
        <Typography variant="subheading" style={{color: 'grey', margin: "10 0"}}>
          *IMPORTANT* Once you proceed with participating in an RFP, a credit card is required but will only be charged if your proposal / response is accepted by the RFP creator. If your response is accepted, you will be charged a finder's fee of $500 one-time.
        </Typography>
        <Grid container style={{marginTop:20}}>
          <Grid item xs={12} sm={12} md={6}>
            {
              paymentSource ? 
              (
                <div>
                  <Paper elevation={1} style={{padding:20, marginBottom:20}}>
                    <Typography variant="title" style={{marginBottom: 15}}>
                      Current Payment Information
                    </Typography>
                    <Typography variant="body" style={{marginBottom: 15}}>
                      Brand: {paymentSource.brand}
                    </Typography>
                    <Typography variant="body" style={{marginBottom: 15}}>
                      Last Four: {paymentSource.last4}
                    </Typography>
                    <Typography variant="body" style={{marginBottom: 15}}>
                      Exp: {paymentSource.exp_month}/{paymentSource.exp_year}
                    </Typography>
                    <Button
                      style={{color:'red'}}
                      onClick={() => props.deletePaymentSource(props)}>
                      Delete
                    </Button>
                  </Paper>
                </div>
              )
              :
              (
                <div/>
              )
            }
            {
              props.profile === null || props.profile.type === 'UNDEFINED' ?
              (
                <Typography variant="body" style={{marginBottom: 15}}>
                  Please enter a payment source after your profile has been saved
                </Typography>
              )
              :
              (
                <div/>
              )
            }
          </Grid>
        </Grid>
        <Grid container style={{marginTop:20, visibility: this.state.showSetPaymentSource}}>
          <Grid item xs={12} sm={12} md={6} >
            <Paper elevation={1} style={{padding:20}}>
              <div class="form-row">
                <Typography variant="body" style={{marginBottom: 15}}>
                  Credit or debit card
                </Typography>
                <div id="card-element" style={{marginBottom: 15}}></div>
                <div id="card-errors" role="alert"></div>
              </div>
              <Button 
                color="secondary"
                onClick={() => {
                  console.log('stripe.createToken', stripe.createToken);
                  console.log('card', card);
                  stripe.createToken(card)
                  .then((result) => {
                    console.log('result', result);
                    if (result.error) {
                      // Inform the customer that there was an error.
                      const errorElement = document.getElementById('card-errors');
                      errorElement.textContent = result.error.message;
                    } else {
                      // Send the token to your server.
                      this.props.stripeTokenHandler(result.token);
                    }
                  })
                  .catch(err => {
                    console.log('createToken err', err);
                  });
                }}>
                Set Payment Method
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant='contained'
          style={{marginTop:50, width:200}}
          onClick={() => this.props.saveProfile(this.props, this.state)}>
          Save Profile
        </Button>
      </div>
    );
  }
}

const getPropertyValue = (prop, propsProfile, state) => {
  const stateValue = state[prop];
  if (stateValue != undefined && stateValue != null) {
    return stateValue;
  }
  else if (propsProfile) {
    return propsProfile[prop];
  }
  else {
    return '';
  }
}

const styles = theme => ({
  selectTitle: {
    marginTop: 40
  },
  sectionBody: {
    display: 'flex'
  },
  textField: {
    width:300
  }
});

EditAgencyProfile.propTypes = {

};

const usStates = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export default withRoot(withStyles(styles)(EditAgencyProfile));