import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoadEnvironment } from './../util/persist';

class PrivateRoute extends React.Component  {

  componentDidMount() {
    this.props.getProfile(this.props);
  }

  render(){

    const environment = LoadEnvironment();

    if (this.props.role === 'ADMIN'){
      if (this.props.profile && this.props.profile.type === 'ADMIN') {
        return (
          <Route
            {...this.props}
            path={this.props.path}
            component={this.props.protectedComponent}
          />
        );
      }
      else {
        return (
          <Route
            {...this.props}
            render={props => (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )}
          />
        );
      }
    }
    else {
      if(environment.bs_api_token){
        return (
          <Route
            {...this.props}
            path={this.props.path}
            component={this.props.protectedComponent}
          />
        );
      }
      else {
        return (
          <Route
            {...this.props}
            render={props => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )}
          />
        );
      }
    }
  }
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  protectedComponent: PropTypes.func.isRequired
};

export default PrivateRoute;
