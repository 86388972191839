import * as React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { LoadEnvironment } from './../util/persist';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withRoot from './../withRoot';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';

class ResetPassword extends React.Component {

  constructor() {
    super();
    this.state = {
      token: null,
      password: '',
      confirmed_password: ''
    };
  }

  componentDidMount(){
    const params = queryString.parse(this.props.location.search);
    if (params && params.token) {
      this.setState({token: params.token})
    }
    else {
      this.props.history.push('/login');
    }
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={24} alignItems={'center'} justify={'center'}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              {/* <img src={require('./../assets/social_safe_icon.png')} width="150" height="150" /> */}
              <h2>Agency Saver Password Reset</h2>
              <div>
                <TextField
                  label="Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.password}
                  onChange={(val) => this.setState({password: val.target.value})}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  label="Confirm Password"
                  type="password"
                  className={classes.textField}
                  value={this.state.confirmed_password}
                  onChange={(val) => this.setState({confirmed_password: val.target.value})}
                  margin="normal"
                />
              </div>
              <div>
                <Button
                  color="primary"
                  style={{marginTop:40}}
                  variant="contained"
                  onClick={() => this.props.resetPassword(this.state, this.props)}>
                  Reset Password
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem'
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width:300
  }
});

export default withRoot(withStyles(styles)(ResetPassword));