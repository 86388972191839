import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';

class TestPDF extends React.Component {

  constructor() {
    super();
    this.state = {
      src: ''
    };
  }

  componentDidMount(){
    const historyState = this.props.history.location.state;
    if (historyState) {
      if (
        historyState.rfp_responses && historyState.rfp_details && historyState.client_rfp
      ) {
        this.props.showPDF(
          this.setState.bind(this),
          history.rfp_responses,
          historyState.rfp_details,
          historyState.client_rfp
        );
      }
      else if (historyState.rfp_details) {
        this.props.getData(this.props, this.setState.bind(this), historyState);
      }
    }
  } 

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    const html = '<iframe width="100%" height="100%" src="'+this.state.src+'"/>';

    return (
      <div> 
        <div dangerouslySetInnerHTML={{__html: html}} />
      </div>
    );
  }
}

TestPDF.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(TestPDF));