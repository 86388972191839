import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class AgencyClosedRFPs extends React.Component {

  constructor() {
    super();
    this.state = {  
      toggleSelected: 'All'
    };
  }

  componentDidMount(){
    this.props.getRFPS(this.props, this.state);
  }

  render() {

    const props = this.props;
    const state = this.state;
    const { classes } = this.props;

    if (props.rfps) {
      if (props.rfps.length > 0) {
        return (
          <div>
            <Typography variant="headline" style={{margin:30}}>
              Your Closed RFPs:
            </Typography>
            <div style={{marginLeft: 40}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Gender"
                  name="gender1"
                  className={classes.group}
                  value={this.state.toggleSelected}
                  onChange={(event) => this.setState({ toggleSelected: event.target.value })}
                  style={{flexDirection: "row"}}>
                  <FormControlLabel value="All" control={<Radio />} label="All" />
                  <FormControlLabel value="Won" control={<Radio />} label="Won" />
                  <FormControlLabel value="Lost" control={<Radio />} label="Lost" />
                </RadioGroup>
              </FormControl>
            </div>
            {props.getViews(props, state)}
          </div>
        );
      }
      else {
        return (
          <div>

          </div>
        );
      }
    }
    else {
      return (<div/>);
    }
  }
}

AgencyClosedRFPs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({

});

export default withRoot(withStyles(styles)(AgencyClosedRFPs));